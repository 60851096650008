import React, {useState, useRef} from "react";

import {useTable} from 'react-table'
import PropTypes from "prop-types";

import {Wrapper, Pagination} from './Table.style'

import OptionsDropdown from "../OptionsDropdown/OptionsDropdown";
import Option from "../OptionsDropdown/Option/Option";


import { useOnClickOutside } from "../../../hooks";

import {ReactComponent as ArrowDown} from '../../../assets/icons/angle-down.svg'
import {ReactComponent as ArrowLeft} from '../../../assets/icons/angle-left.svg'
import {ReactComponent as ArrowRight} from '../../../assets/icons/angle-right.svg'
import NoDataTable from "./NoDataTable/NoDataTable";

const perPageOptions = [20, 50, 100]

const Table = ({columns, data, hasFooter = false, pagination = null, maxRows = 8, onRowClicked}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        
    } = useTable({ columns, data})

    const [dropdownOpened, setDropdownOpened] = useState(false)

    const ref = useRef()

    useOnClickOutside(ref, () => {
        setDropdownOpened(false)
    })

    const handlePrevPage = () => {
        pagination.page > 1 && pagination.setPage(pagination.page - 1)
    }

    const handleNextPage = () => {
        pagination.to < pagination.totalResults && pagination.setPage(pagination.page + 1)
    }

    const handleResultsPerPageChange = (perPage) => {
        pagination.setPerPage(perPage)
        pagination.setPage(1)
    }

    

    return data.length > 0 ? (
        <Wrapper scrollable={!pagination} maxRows={maxRows}>
            {pagination && (
                <Pagination opened={dropdownOpened}>
                    <div>Rows</div>
                    <div ref={ref} className="perpage">
                        {pagination.perPage}
                        <ArrowDown className="arrow-down" onClick={() => setDropdownOpened(!dropdownOpened)} />
                        <div className="dropdown">
                            <OptionsDropdown>
                                {perPageOptions.map((opt, index) => <Option key={index} onClick={() => handleResultsPerPageChange(opt)}>{opt}</Option>)}
                            </OptionsDropdown>         
                        </div>
                    </div>
                    <div className="page-select">
                        {pagination.from}-{pagination.to} of {pagination.totalResults}
                        <ArrowLeft onClick={handlePrevPage} className={`arrow ${pagination.page === 1 && 'disabled'}` }/>
                        <ArrowRight onClick={handleNextPage} className={`arrow ${pagination.to >= pagination.totalResults && 'disabled'}`}/>
                    </div>
                </Pagination>
            )}
                 <table {...getTableProps()}>
                 <thead>
                     {headerGroups.map((headerGroup, index) => (
                     <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                         {headerGroup.headers.map((column, index) => (
                         <th key={index} {...column.getHeaderProps()}>{column.render('Header')}</th>
                         ))}
                     </tr>
                     ))}
                 </thead>
                 <tbody {...getTableBodyProps()}>
                     {rows.map((row, index) => {
                     prepareRow(row)
                     return (
                         <tr key={index} {...row.getRowProps({
                             onClick: () => onRowClicked && onRowClicked(row)
                         })}>
                             
                         {row.cells.map((cell, index) => {
                             return <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                         })}
                         </tr>
                     )
                     })}
                 </tbody>
                 {hasFooter && (
                     <tfoot>
                         {footerGroups.map((group, index) => (
                             <tr key={index} {...group.getFooterGroupProps()}>
                                 {group.headers.map((column, index) => (
                                 <td key={index} {...column.getFooterProps()}>{column.render('Footer')}</td>
                                 ))}
                             </tr>
                         ))}
                     </tfoot>
                 )}
                 
             </table>
        </Wrapper>
    ) : <NoDataTable />
}

export default Table

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    hasFooter: PropTypes.bool,
    pagination: PropTypes.shape({
        page: PropTypes.number,
        setPage: PropTypes.func,
        perPage: PropTypes.number,
        setPerPage: PropTypes.func,
        totalResults: PropTypes.number,
        from: PropTypes.number,
        to: PropTypes.number
    }),
    maxRows: PropTypes.number,
    onRowClicked: PropTypes.func
}