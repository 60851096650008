import styled from "styled-components";

export const AddContainer = styled.div`
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #fff;

    .name{
      color: #50637F;
      font-size: 20px;

    }

    .date {
        color: #50637F;
        font-size: 14px;
        span {
            color: #A3A3A3;
        }
    }

    .time {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 40%;
        color: #50637F;
        font-size: 12px;
    }

    .status {
        color: #50637F;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .buttons {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
    }

    .buttons .btn {
        color: #ffffff;
        border: none;
        cursor: pointer;
    }

    .buttons .cancel {
        color: #B2B1B1;
        background-color: #ffffff;
        width: 100px;
    }

    .buttons .cancel:hover {
        color: #506C90;
    }
`;