import React from 'react'

import {Wrapper} from './NoDataTable.style'

const NoDataTable = () => {
    return (
        <Wrapper>
            No Data Found
        </Wrapper>
    )
}

export default NoDataTable
