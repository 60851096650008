import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;

  h3{
    font-size: 20px;
    font-weight: 300;
    color: #50637F;
    margin-bottom: 20px;
  }

  .profile-info{
    width: 50%;
    padding-right: 50px;
    border-right: 1px solid #E8E8E8;
  }

  .login-info{
    width: 50%;
    padding-left: 50px;
    
    .save{
      margin-top: 75px;
      display: flex;
      justify-content: right;
    }
  }

  label{
    display: block;
    margin-top: 16px;
    margin-bottom: 9px;
    font-size: 12px;
    color: #506280;
  }
  
  
`