import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  
  .header{
    background-color: #D6E1EE;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    width: 100%;
    height: 45px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: 'Roboto Medium', sans-serif;
    font-size: 13px;
    color: #50637F;
  }
  
  .month-days{
    .week:last-child{
      .day:first-child{
        border-bottom-left-radius: 7px;
      }

      .day:last-child{
        border-bottom-right-radius: 7px;
      }
    }
    
    .week{
      width: 100%;
      height: 110px;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      position: relative;
    }
    
    .event-container{
      width: 100%;
      position: absolute;
      top: 35px;
    }
    

    .day:nth-child(29) {
      border-bottom-left-radius: 7px;
    }

    .day:nth-child(35) {
      border-bottom-right-radius: 7px;
    }
    
  }
  
`

