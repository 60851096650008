import styled from "styled-components";

export const Wrapper = styled.div`
  width: 200px;
  position: absolute;
  display: ${props => props.opened ? 'block' : 'none'};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 20px;
  top: 105px;
  left: -20px;
  z-index: 10;
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  
  .date{
    font-family: 'Roboto Light', sans-serif;
    font-size: 12px;
    color: #50637F;
    margin-bottom: 10px;
  }

  .close{
    position: absolute;
    right: 15px;
    top: 15px;
    width: 12px;
    height: 12px;
    path{
      fill: #3E5371;
    }

    :hover{
      cursor: pointer;
    }
  }
`

export const Event = styled.div`
  color: ${props => props.eventStartColor};
  height: 20px;
  width: 150px;
  border-radius: 6px;
  background-color: ${props => props.background};
  display: flex;
  align-items: center;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  :hover{
    border: 1px solid ${props => props.eventStartColor};
    border-left: none
  }
  
  .event-start{
    background-color: ${props =>  props.eventStartColor};
    height: 20px;
    width: 5px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-right: 5px;
  }
`