import styled from "styled-components";

export const SelectNumberWrapp = styled.div`
    width: 90px;

    .label {
        color: #506280;
        font-family: 'Robot Regular', sans-serif;
        font-size: 12px;
        letter-spacing: 0.17px;
        text-transform: uppercase;
        width: 110%;
        margin-bottom: 8px;
    }

    .input {
        position: relative;
        user-select: none;
        .select {
            width: 100%;
            padding: 10px 15px ;
            border-radius: 8px;
            border: 1px solid ${props => props.error !== null ? "#E43F3F" : "#C5CCD5" };
            color: #A3A3A3;
            user-select: auto;
            &::-webkit-inner-spin-button {
                opacity: 0;
                pointer-events: none;
                border: 1px solid red;
            }
        }
        .arrows {
            position: absolute;
            top: 11px;
            right: 13px;
            display: flex;
            flex-direction: column;
            svg {
                height: 9px;
                cursor: pointer;
                path {
                    fill: #3E5371;
                }
                &:hover {
                    path{
                        fill: #FBB106;
                    }
                }
            }
            .up-arrow {
                transform: rotate(180deg);
            }
        }
    }

    .error {
        color: #E43F3F;
        font-family: 'Roboto Light',sans-serif;
        font-size: 10px;
        margin-top: 3px;
        width: 160px;
    }
`;