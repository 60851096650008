import styled from 'styled-components'


export const Wrapper = styled.div`
    user-select: none;
    width: 200px;
`

export const Header = styled.div`
    height: 30px;
    width: 100%;
    border: 1px solid ${props => props.error ? "#F19E9E" : props.focused ? '#3E5573' : '#CFD4DC'} ;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    .arrow-down{
        width: 12px;
        margin-left: 15px;
        path{
            fill: #3E5371;
        }
    }

    :hover{
        border: 1px solid #A1AEBD;
    }

    .filter-icon{
        margin-right: 5px;
        height: 14px;

        svg {
            fill: #3E5371;
        }

        transform: translateY(3px);
    }

    .filter-placeholder{
        display: flex;
        
    }

    font-family: 'Roboto Light', sans-serif;
    color: #ADADAD;
    font-size: 13px;
    letter-spacing: 0.46px;

`

export const DropdownContainer = styled.div`
    position: absolute;
    z-index: 10;
    background-color: #ffffff;
    margin-top: 4px;
    width: 214px;
    border: 1px solid #E8E8E8;
    border-radius: 7px;
    padding: 15px;
    box-shadow: 0px 2px 8px 0px rgba(62, 85, 115, 0.24);
    display: ${props => props.opened ? 'block' : 'none'};
    transform: translateX(-14px);

    .selection{
      display: flex;
      gap: 30px;
      margin-top: 11px;
      font-family: 'Roboto Regular', sans-serif;
      font-size: 12px;
      color: #3E5573;
      border-top: 1px solid #D8D8D8;
      padding-top: 5px;

      :hover{
        cursor: pointer;
      }
    }
`
