import React, {useCallback, useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import {useHistory} from "react-router";
import {useSelector, useDispatch} from "react-redux";

import Logo from "../../shared/components/Logo/Logo";
import Input from "../../shared/components/Input/Input";
import Button from "../../shared/components/Button/Button";
import Checkbox from "../../shared/components/Checkbox/Checkbox";

import {Wrapper, Container, Form} from "./Login.style";

import {login} from "../../reducers/authReducer";

import { useKeypress } from "../../hooks";

const Login = ({location}) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rememberMe, setRememberMe] = useState(false)
    const {errors, isLoggedIn} = useSelector(state => state.auth)

    const dispatch = useDispatch()
    let history = useHistory()

    // useEffect(() => {
    //     console.log(location)
    // }, [location])

    if(isLoggedIn){
        console.log(location)
        if(location.state){
            history.push(location.state.from)
        } else {
            history.push('/your-profile')
        }

    }

    const handleLogin = () => {
        const params = {
            email,
            password,
            rememberMe
        }

        dispatch(login(params))
    }

    const handleCheckbox = useCallback(() => {
        setRememberMe(!rememberMe)
    }, [rememberMe])

    useKeypress('Enter', handleLogin)

    return (
        <Wrapper>
            <Logo size={80}/>
            <h3 className="title">Timelogger Watchdog</h3>
            <Container>
                <div className="form-title">Login</div>
                <hr />
                <Form>
                    <Input error={errors && 'email' in errors ? errors.email[0] : null} value={email} placeholder={"Email"} type={"email"} onChange={setEmail}/>
                    <Input error={errors && 'password' in errors ? errors.password[0] : null} value={password} placeholder={"Password"} type={"password"} onChange={setPassword}/>
                    <div className="remember-forgot">
                        <Checkbox checked={rememberMe} label="Remember me" onClick={handleCheckbox}/>
                        <Link to="/forgot-password" className="forgot-password">Forgot password?</Link>
                    </div>
                    <Button value={"Login"} onClick={handleLogin}/>
                </Form>
            </Container>
        </Wrapper>
    )
}

export default Login