import styled, {keyframes} from "styled-components";

const widthAnimation = (finalWidth) => keyframes`
  0% { width: 0; color: transparent}

  100% { width: calc(${finalWidth} - 14px);}
`

export const Wrapper = styled.div`
  z-index: 5;
  height: 22px;
  position: absolute;
  color: ${props => props.eventStartColor};
  width: calc(${props => props.eventWidth}% - 14px);
  max-width: ${props => props.maxWidth}%;
  left: calc(${props => props.leftOffset}% + 7px);
  top: ${props => props.topOffset}px;
  border-radius: 6px;
  border: ${props => props.hovered ? `1px solid ${props.eventStartColor}` : 'none'};
  border-left: none;
  cursor: pointer;
  background-color: ${props => props.background};
  display: flex;
  align-items: center;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 12px;
  transition: width 1s;
  animation: ${props => widthAnimation(props.eventWidth)} 0.5s ease-in;
  
  
  .event-start{
    background-color: ${props => props.isFirstDay ? props.eventStartColor : props.background};
    border: ${props => props.hovered ? `1px solid ${props.eventStartColor}` : 'none'};
    border-right: none;
    height: 22px;
    width: 5px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-right: 5px;
  }
  
  .pending-container{
    position: relative;
    margin-left: auto;
  }
  
`

export const PendingDropdown = styled.div`
  width: 102px;
  height: 30px;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 10px;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(62, 85, 115, 0.24);
  display: ${props => props.opened ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -40px;
  top: -35px;
  background: white;
`

