import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    .filters{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .search {
            display: flex;
            align-items: center;
            padding-top: 1px;
        }

        .calendar-reset {
            display: flex;
            justify-content: space-between;
            width: 60%;
            .reset-button-wrapper {
                display: flex;
                align-items: center;
                .reset-button {
                    width: 90px;
                    height: 38px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #6D8DB7;
                    background: #F1F7FF;
                    border: none;
                    border-radius: 8px;
                    cursor: pointer;
                    &:hover {
                      background: #D7E8FF;
                      color: #5477A6;
                    }
                }
            }
        }
    }
`;