import styled from "styled-components";

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    background-color: #ffffff95;
    z-index: 10000000;
`

export const Content = styled.div`
    padding: 45px 30px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    border-radius: 20px;
    position: relative;
    max-width: max-content;
    margin: 5vh auto; 
    position: relative;
    .close{
      position: absolute;
      right: 30px;
      top: 30px;
      width: 18px;
      height: 18px;
      path{
        fill: #3E5371;
      }
      
      :hover{
        cursor: pointer;
      }
    }
`

export const LoadingOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    background-color: #ffffff95;
    z-index: 10000000;
    display: flex;
    align-items: center;
    justify-content: center;
`