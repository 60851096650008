import {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #F2F2F2;
    height: 100vh;
  }
  
  * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Roboto Regular', sans-serif;

        ::-webkit-scrollbar{
          width: 7px;
          background-color: #EFF6FD;
          border-radius: 4px;
          overflow: overlay;
        }

        ::-webkit-scrollbar-thumb {
          background: #D6E1EE;
          border-radius: 4px;
        }
    }
  
`

export default GlobalStyle