import React, {memo} from 'react'
import PropTypes from "prop-types";

import {ReactComponent as CheckIcon} from '../../../assets/icons/check.svg'

import {CheckboxContainer, StyledCheckbox, Label} from './Checkbox.style'

const Checkbox = ({checked, label, size = 20, onClick}) => {
    return <CheckboxContainer onClick={onClick} >
        <StyledCheckbox size={size} checked={checked}>
            <CheckIcon className="check-icon"></CheckIcon>
        </StyledCheckbox>
        <Label>{label}</Label>
    </CheckboxContainer>
}

export default memo(Checkbox)

Checkbox.propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.string,
    size: PropTypes.number,
    onChange: PropTypes.func
}