import React, {useRef, useState} from "react";

import {Wrapper} from "./Day.style";
import dayjs from "dayjs";
import ShowMore from "../ShowMore/ShowMore";
import {useOnClickOutside} from "../../../../hooks";

const Day = ({day, events, selectedMonthIndex, setHovered}) => {

    const [showMoreDisplayed, setShowMoreDisplayed] = useState(false)

    const ref = useRef()

    useOnClickOutside(ref, () => {
        setShowMoreDisplayed(false)
    })

    return (
        <Wrapper>
            <div
                className={`
                   ${day.format('DD-MM-YY') === dayjs().format('DD-MM-YY') ? 'current-day' : ''} 
                   ${day.$M !== dayjs().month(selectedMonthIndex).$M ? 'not-in-selected-month': ''}
            `}>
                {day.$D}
            </div>
            <div ref={ref}>
                {events.length > 0 && <div className="show-more"  onClick={() => setShowMoreDisplayed(!showMoreDisplayed)} >{events.length} more</div>}
                <ShowMore setHovered={setHovered} date={day} opened={showMoreDisplayed} events={events} close={() => setShowMoreDisplayed(false)}/>
            </div>
            </Wrapper>
    )

}

export default Day