import styled from 'styled-components'

export const Wrapper = styled.div`
    user-select: none;
`

export const Header = styled.div`
    height: 38px;
    width: 100%;
    border: 1px solid ${props => props.error ? "#F19E9E" : props.focused ? '#3E5573' : '#CFD4DC'} ;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    .value{
        font-family: 'Roboto Regular', sans-serif;
        font-size: 14px;
        letter-spacing: 0.19px;
        color: #A3A3A3;
    }

    .placeholder{
        font-family: 'Roboto Light', sans-serif;
        color: #ADADAD;
        font-size: 14px;
    }

    .arrow-down{
        width: 12px;
        margin-left: 15px;
        path{
            fill: #3E5371;
        }
    }

    :hover{
        border: 1px solid #A1AEBD;
    }

`

export const DropdownContainer = styled.div`
    position: absolute;
    z-index: 10;
    background-color: #ffffff;
    margin-top: 4px;
    width: 100%;
    border: 1px solid #E8E8E8;
    border-radius: 7px;
    padding: 15px;
    box-shadow: 0px 2px 8px 0px rgba(62, 85, 115, 0.24);
    display: ${props => props.opened ? 'block' : 'none'};
`

export const ErrorMsg = styled.div`
  color: #E43F3F;
  font-family: 'Roboto Light', sans-serif;
  font-size: 10px;
  margin-top: 3px;
`