import {createSlice} from "@reduxjs/toolkit";

import { addUserLog, deleteUserLog, editUserLog, getUserDailyStats, getUserMonthlyStats } from "./UserStats.thunk";

const initialState = {
    monthly : [],
    loadingMonthly : false,
    errorsMontly: null,
    daily : [],
    loadingDaily : false,
    errorsDaily : null
}

const userStatsSlice = createSlice({
    name: "userStats",
    initialState,
    reducers: {},
    extraReducers : (builder) => {
        builder
        // get monthly stats
            .addCase(getUserMonthlyStats.pending, (state) => {
                state.loadingMonthly = true;
                state.errorsMontly = null;
            })
            .addCase(getUserMonthlyStats.fulfilled, (state, action) => {
                state.loadingMonthly = false;
                state.monthly = action.payload.data.monthly_logs;
            })
            .addCase(getUserMonthlyStats.rejected, (state, action) => {
                console.log(action.payload);
            })
        //get daily stats
            .addCase(getUserDailyStats.pending, (state) => {
                state.loadingDaily = true;
                state.errorsDaily = null;
            })
            .addCase(getUserDailyStats.fulfilled, (state, action) => {
                state.loadingDaily = false;
                state.daily = action.payload.data;
            })
            .addCase(getUserDailyStats.rejected, (state, action) => {
                console.log(action.payload);
            })
        // add log
            .addCase(addUserLog.pending, (state) => {
                state.loadingDaily = true;
                state.loadingMonthly = true;
                state.errorsDaily = null;
                state.errorsMontly = null;
            })
            .addCase(addUserLog.fulfilled, (state, action) => {
                state.loadingDaily = false;
                state.loadingMonthly = false;
            })
            .addCase(addUserLog.rejected, (state, action) => {
                console.log(action.payload);
            })
        // edit log
            .addCase(editUserLog.pending, (state) => {
                state.loadingDaily = true;
                state.loadingMonthly = true;
                state.errorsDaily = null;
                state.errorsMontly = null;
            })
            .addCase(editUserLog.fulfilled, (state, action) => {
                state.loadingDaily = false;
                state.loadingMonthly = false;
            })
            .addCase(editUserLog.rejected, (state, action) => {
                console.log(action.payload);
            })
        // delete log
            .addCase(deleteUserLog.pending, (state) => {
                state.loadingDaily = true;
                state.loadingMonthly = true;
                state.errorsDaily = null;
                state.errorsMontly = null;
            })
            .addCase(deleteUserLog.fulfilled, (state, action) => {
                state.loadingDaily = false;
                state.loadingMonthly = false;
            })
            .addCase(deleteUserLog.rejected, (state, action) => {
                console.log(action.payload);
            })
    }
});

export const userStatsMonthly = (state) => state.userStats.monthly;
export const userLoadingMonthly = (state) => state.userStats.loadingMonthly;
export const userStatsDaily = (state) => state.userStats.daily;
export const userLoadingDaily = (state) => state.userStats.loadingDaily;

export default userStatsSlice.reducer;