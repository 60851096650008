import React, { useEffect, useState, useCallback, memo } from 'react'
import {useSelector} from 'react-redux'

import { Wrapper, WorkTime, BreakTime } from './WorkTimeStatus.style'

import WorkTimeStatusService from './WorkTimeStatus.service'

const secondsToHoursAndMinutes = (sec) => {
    const hours = Math.floor(Math.abs(sec) / 3600)
    const minutes = Math.floor(Math.abs(sec) % 3600 / 60)
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
}

const WorkTimeStatus = () => {
    const [workTime, setWorkTime] = useState({
        workTime: null,
        breakTime: null
    })
    
    const {user} = useSelector(state => state.auth)

    const fetchWorkTime = useCallback(async () => {
        try{
            const {data} = await WorkTimeStatusService.getWorkTime(user.id, Date.parse(new Date()) / 1000)
            setWorkTime({
                workTime:  data.work_time_left,
                breakTime:  data.break_time_left
            })
        } catch(err){
            setWorkTime({
                workTime: '07:30',
                breakTime: '00:30'
            })
        }
    }, [user.id])

    useEffect(() => {
        fetchWorkTime()
        const fetchInterval = setInterval(() => {
            fetchWorkTime()
        }, 60000)
        return () => clearInterval(fetchInterval)
    }, [user.id, fetchWorkTime])

    return (
        <Wrapper>
            <span className="text">{workTime.workTime < 0 ? 'Overtime' : 'Left Work Time'}</span><WorkTime overtime={workTime.workTime < 0}>{secondsToHoursAndMinutes(workTime.workTime)}</WorkTime><div className="dot"></div><span className="text">Break Time</span><BreakTime>{secondsToHoursAndMinutes(workTime?.breakTime)}</BreakTime>
        </Wrapper>
    )
}

export default memo(WorkTimeStatus)
