import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {Wrapper} from './EditTimeOffRequest.style'

import Button from "../../../../shared/components/Button/Button";
import Calendar from "../../../../shared/components/Calendar/Calendar";
import Dropdown from "../../../../shared/components/Dropdown/Dropdown";
import TextArea from "../../../../shared/components/TextArea/TextArea";

import UserTimeOffService from '../TimeOff.service'

import { showToast } from "../../../../shared/utils/showToast";
import { useKeypress } from "../../../../hooks";

const timeOffReasons = ['Vacation', 'Sick Leave', 'Personal', 'Work From Home', 'Company']

const EditTimeOffRequest = ({hideModal, id, setLoadingModal, onEdit}) => {
    const date = new Date()
    const [fromDate, setFromDate] = useState(date)
    const [toDate, setToDate] = useState(date)
    const [reason, setReason] = useState(null)
    const [requestNote, setRequestNote] = useState("")
    const [errors, setErrors] = useState(null)
    const {user} = useSelector(state => state.auth)


    const fetchTimeOffRequest = useCallback(async () => {
        try {
            setLoadingModal(true)
            const {data} = await UserTimeOffService.getById(user.id, id)
            const request = data.data.time_off
            setFromDate(new Date(request?.date_from * 1000))
            setToDate(new Date(request?.date_to * 1000))
            setRequestNote(request?.note)
            setReason(request?.reason)
            setLoadingModal(false)
        } catch (error) {
            console.log(error)
        }
    }, [user.id, id, setLoadingModal])

    useEffect(() => {
        fetchTimeOffRequest()
    }, [fetchTimeOffRequest])

    const editTimeOffRequest = async ( ) => {        
        try { 
            setLoadingModal(true)
            const response = await UserTimeOffService.edit(id, user.id, Date.parse(fromDate) / 1000, Date.parse(toDate) / 1000, requestNote, reason.toLowerCase())
            showToast(response.data.message, response.data.data.status === 'declined' ? 'error' : "success")
            setLoadingModal(false)
            onEdit()
            hideModal()
        } catch (error) {
            console.log(error.response.status)
            showToast(error.response.status === 413 ? 'Profile picture size must be less then 8MB.' : error.response.data.message, "error")
            setErrors(error.response.data.data)
            setLoadingModal(false)
        }
        
    }

    const handleSaveRequest =() => {
        editTimeOffRequest()
    }  

    const handleReasonSelect = (reason) => {
        setReason(reason)
    }

    useKeypress('Enter', handleSaveRequest)
    useKeypress('Escape', hideModal)

    return (
        <Wrapper>
                <>
                    <h3>Edit Time Off Request</h3>
                    <div className="dropdown-container">
                        <label>REASON</label>
                        <Dropdown error={errors && 'reason' in errors && errors.reason[0]} placeholder={"Select reason"} value={reason && reason.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}>
                            {timeOffReasons.map(reason => <div key={reason} className="dropdown-option" onClick={() => handleReasonSelect(reason)}>{reason}</div>)}
                        </Dropdown>
                    </div>
        
                    <div className="from-to">
                        <div className="from">
                            <label>FROM</label>
                            <Calendar minDate={new Date()} startDate={fromDate} setStartDate={setFromDate}/>
                        </div>
                        <div className="to">
                            <label>TO</label>
                            <Calendar minDate={fromDate} startDate={toDate} setStartDate={setToDate}/>
                        </div>
                    </div>
    
                    <TextArea label="NOTE" error={errors && 'note' in errors && errors.note[0]} rows={10} maxLength={300} value={requestNote} onChange={e => setRequestNote(e.target.value)} />
                    <div className="button-group">
                        <div className="cancel-button"  onClick={() => hideModal()}>Cancel</div>
                        <Button value={"Save"} width={100}  onClick={handleSaveRequest}/>
                    </div>

                </>

        </Wrapper>
    )
}

export default EditTimeOffRequest