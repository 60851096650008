import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../Button/Button";
import Calendar from "../../Calendar/Calendar";
import Select from "../../Select/Select";
import { editUserLog, getUserDailyStats, getUserMonthlyStats } from "../UserStats.thunk";
import { EditContainer } from "./Edit.style";

const Edit = ({ hideModal, user, log, toTimestamp}) => {
    const timestampToDateObject = (date) => {
        const newDate = new Date(date * 1000);
        return newDate;
    }

    const [time, setTime] = useState(new Date(timestampToDateObject(log.time)));
    const [status, setStatus] = useState(log.log_type);

    const dispatch = useDispatch();
    
    let options = ["Login", "Logout"];

    const handleEdit = async () => {
        let timestamp = toTimestamp(time);
        let user_id = user.user_id;
        let log_type = status;
        let id = log.id;
        let params = {id, user_id, timestamp, log_type};
        await dispatch(editUserLog(params));
        hideModal();
        let reloadParams = {user_id: user_id, timestamp: log.time};
        dispatch(getUserDailyStats(reloadParams));
        dispatch(getUserMonthlyStats(reloadParams));
    }

    return(
        <EditContainer>
            <div className="name">Edit Stats - {user.user_full_name}</div>
            <div className="date">
                Date: <span>{new Date(log.time*1000).toLocaleDateString()}</span>
            </div>
            <div className="time">
                <div>TIME:</div>
                <Calendar startDate={time} setStartDate={setTime} timeSelectOnly={true}  maxDate={new Date()} />
            </div>
            <div className="status">
                <div>STATUS: </div>
                <Select options={options} onChange={setStatus} currentOption={status} />
            </div>
            <div className="buttons">
                <Button width={100} value="Save" onClick={()=>handleEdit()} />
                <button className="btn cancel" onClick={()=> hideModal()} >Cancel</button>
            </div>
        </EditContainer>
    )
}

export default Edit;