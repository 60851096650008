import api from "../../../libs/api";

class UserStatsService {
    async getMonthlyStats(user_id, timestamp) {
        let response = await api.get(`${user_id}/monthly/logs?date=${timestamp}`);
        return response;
    }

    async getDailyStats(user_id, timestamp) {
        let response = await api.get(`${user_id}/daily/logs?date=${timestamp}`);
        return response;
    }

    async addLog(user_id, log_time, log_type, log_date){
        let selected_date = `${log_date}`;
        let selected_time = `${log_time}`;
        let response = await api.post(`${user_id}/logs`, {
            selected_date,
            selected_time,
            log_type
        });
        return response;
    }

    async editLog(id, user_id, timestamp, log_type){
        let date = `${timestamp}`;
        let response = await api.put(`${user_id}/logs/${id}`, {
            date,
            log_type
        });
        return response;
    }

    async deleteLog(id, user_id){
        let response = await api.delete(`${user_id}/logs/${id}`);
        return response;
    }

    async exportCsv(user_id, date){
        let response = await api.get(`${user_id}/export-monthly/stats?date=${date}`);
        return response;
    }
}

export default new UserStatsService();