import styled from 'styled-components'

export const Wrapper = styled.div`

    width: 390px;

    .title{
        font-family: 'Roboto Light', sans-serif;
        font-size: 20px;
        color: #50637F;
    }

    .content{
        font-family: 'Robot Regular', sans-serif;
        font-size: 14px;
        color: #A3A3A3;
        margin-top: 30px;
    }

    .button-group{
        margin-top: 40px;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 20px;

        .cancel-button{
            margin-right: 20px;
            font-family: 'Roboto Regular', sans-serif;
            font-size: 14px;
            color: #B2B1B1;

            :hover{
            cursor: pointer;
            }
        }
    }
`

