import styled from "styled-components";

export const Wrapper = styled.div`
  width: ${props => props.width};
  font-size: 14px;
  position: relative;
  user-select: none;
`

export const DropdownHeader = styled.div`
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #CFD4DC;
  border-radius: 8px;
  color: rgba(163, 163, 163, 1);
  
  .arrow-down{
    border: solid rgba(62, 83, 113, 1);
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .arrow-up{
    border: solid rgba(62, 83, 113, 1);
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  :hover{
    cursor: pointer;
  }
`

export const DropdownListWrapper = styled.ul`
  display: ${props => props.isOpened ? "block" : "none"};
  margin: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  position: absolute;
  z-index: 99999;
  border-radius: 8px;

  
  &:first-child {
    padding-top: 0.8em;
  }
`

export const DropdownListItem = styled.li`
  display: flex;
  align-items: center;
  padding-left: 10px;
  list-style: none;
  height: 38px;
  color: rgba(163, 163, 163, 1);
  :hover{
    cursor: pointer;
    background: rgba(163, 163, 163, 1);
    color: #ffffff;
  }
`

