import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 160px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .title{
    font-weight: 400;
    font-size: 20px;
    color: #50637F;
    margin-top: 15px;
    letter-spacing: 0.277778px;
    height: 52px;
  }

  .to-login-wrapper {
      width: 39px;
      margin-top: 15px;
  }

  .to-login {
    color: #F9C761;
    font-size: 14px;
  }
  
  a{
    text-decoration: none;
  }
`

export const Container = styled.div`
  background-color: #FFFFFF;
  width: 400px;
  padding: 20px 50px 43px 50px;
  
  .form-title{
    font-weight: 400;
    font-size: 20px;
    
    color: #50637F;
    margin-bottom: 20px;
  }
  
  hr{
    height: 1px;
    width: 400px;
    background-color: #F3F3F3;
    border: none;
    transform: translateX(-50px);
  }
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 43px;
`