import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../Button/Button";
import Calendar from "../../Calendar/Calendar";
import Select from "../../Select/Select";
import { addUserLog, getUserDailyStats, getUserMonthlyStats } from "../UserStats.thunk";
import { AddContainer } from "./AddLog.style";


const AddLog = ({user, selectedDate, toTimestamp, setAddModal}) => {
    const [time, setTime] = useState(selectedDate);
    const [status, setStatus] = useState("Login");

    const dispatch = useDispatch();

    const handleLog = async () => {
        let user_id = user.user_id;
        let log_date = toTimestamp(selectedDate);
        let log_time = toTimestamp(time);
        let log_type = status;
        const params = {
            user_id,
            log_date,
            log_time,
            log_type
        };
        await dispatch(addUserLog(params));
        setAddModal(false);
        const reloadParams = {
            user_id: user_id,
            timestamp: log_date
        };
        dispatch(getUserDailyStats(reloadParams));
        dispatch(getUserMonthlyStats(reloadParams));

        // setTimeout(()=>{
        //     console.log("aaaaaaaaaaa");
        // }, 500);
    };
    
    return (
        <AddContainer>
            <div className="name">Add log - {user.user_full_name}</div>
            <div className="date">
                Date: <span>{selectedDate.toLocaleString().slice(0, 10)}</span>
            </div>
            <div className="time">
                <div>TIME: </div>
                <Calendar timeSelectOnly={true} startDate={time} setStartDate={setTime}  maxDate={new Date()}  />
            </div>
            <div className="status"> 
                <div>Status:</div> 
                <Select options={["Login", "Logout"]} onChange={setStatus} />
            </div>
            <div className="buttons">
                <Button width={100} value="Save" onClick={()=>handleLog()} />
                <button className="btn cancel" onClick={()=>setAddModal(false)} >Cancel</button>
            </div>
        </AddContainer>
    )
};

export default AddLog;