import {useState, useCallback} from 'react'

export const useTableSort = (initialKey, initialType) => {
    const [sortBy, setSortBy] = useState(initialKey)
    const [sortType, setSortType] = useState(initialType)

    const toggleSortDirection = useCallback((key) => {
        if(key !== sortBy){
            setSortBy(key)
            setSortType(initialType)
        } else {
            setSortType(sortType === 'asc' ? 'desc' : 'asc')
        }
    }, [initialType, sortBy, sortType])

    return {
        sortBy,
        sortType,
        toggleSortDirection
    }
}