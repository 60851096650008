import styled from "styled-components";

export const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    z-index: 10000;
`;

export const Text = styled.div`
    width: 80px;

    .lds-facebook {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }

    .lds-facebook div {
      display: inline-block;
      position: absolute;
      background-color: #FBB106;
      left: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
      animation: lds-facebook 1.2s infinite;
    }

    .lds-facebook div:nth-child(1) {
      left: -10px;
      background-color: #FBB106;
      animation-delay: -0.36s;
    }

    .lds-facebook div:nth-child(2) {
      left: 25px;
      background-color: #FBB106;
      opacity: 0.67;
      animation-delay: -0.24s;
    }

    .lds-facebook div:nth-child(3) {
      left: 59px;
      background-color: #FBB106;
      opacity: 0.39;
      animation-delay: -0.12s;
    }

    @keyframes lds-facebook {
      0% {
        top: 16px;
        height: 8px;
        width: 8px;
      }

      50%{
        top: 16px;
        height: 16px;
        width: 16px;
      }

      100% {
        top: 16px;
        height: 16px;
        width: 16px;
      }
    }

`;