import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    user-select: none;
`

export const OuterCircle = styled.div`
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border: 1px solid ${props => props.checked ? '#FBB106' : '#C5CCD5'} ;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;   
`

export const InnerCircle = styled.div`
    width: ${props => props.checked ? "10px" : 0};
    height: ${props => props.checked ? "10px" : 0};
    border-radius: 50%;
    background-color: #FBB106;
`

export const Label = styled.div`
    font-family: 'Roboto Regular';
    font-size: 14px;
    color: #A3A3A3;
`