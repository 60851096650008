import React from "react";
import {Redirect, Route, useLocation} from "react-router";
import {useSelector} from "react-redux";
let history = []
const ourHistory = (loc) => {
    let historyStr = localStorage.getItem("history")
    if(historyStr){
        history = historyStr.split(",")
    }
    if(history.length < 2){
        if(!history.includes(loc)){
            history.push(loc)
        }
    } else if(history.length === 2){
        if(history[1] !== loc){
            history.shift()
            history.push(loc)
        }
    }
    localStorage.setItem("history", history.toString())
}
const PrivateRoute = ({component: Component, isAdminRoute, ...rest}) => {
    const {user, isLoggedIn} = useSelector(state => state.auth)
    const location = useLocation()
    let redirectPath = history[0]
    ourHistory(location.pathname)
    return (
        <Route {...rest} render={(props) => {
            if(isLoggedIn){
                if(isAdminRoute){
                    if(user.is_admin) return <Component {...props} />
                    return <Redirect to={redirectPath} />
                }
                return <Component {...props} />
            }
            return <Redirect to={{
                pathname: "/login",
                state: { from: location.pathname }
            }} />
        }} />
    )
}
export default PrivateRoute