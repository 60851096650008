import React, { useCallback, useEffect, useMemo, useState } from "react";
import Table from "../../shared/components/Table/Table";
import { UsersStats } from "./Stats.style";
import { useDispatch, useSelector } from "react-redux";
import UserStats from "../../shared/components/UserStats/UserStats";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { getAllStats } from "./Stats.thunk";
import { statsList, statsLoading, statsPagination } from "./Stats.slice";
import Calendar from "../../shared/components/Calendar/Calendar";
import LoadingTable from "../../shared/components/Table/LoadingTable/LoadingTable";
import { useDebounce, useTableSort } from "../../hooks";
// import UserStatsService from "../../shared/components/UserStats/UserStats.service";
import {ReactComponent as File} from "../../assets/icons/file.svg";
import StatsService from "./Stats.service";


const Stats = () => {
    const pagination = useSelector(statsPagination);
    const stats = useSelector(statsList);
    const loading = useSelector(statsLoading)
    const [showStats, setShowStats] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [month, setMonth] = useState(new Date());
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const {sortBy, sortType, toggleSortDirection} = useTableSort('user_name','asc')
    const [userName, setUserName] = useState("");
    const [user, setUser] = useState(null);
    const debouncedSearchTerm = useDebounce(userName, 500);

    const dispatch = useDispatch();
    const toTimestamp = date => Math.round(Date.parse(date)/1000);
    const getTotalHoursMins = (time) => {
        const hours = Math.floor(Math.abs(time) / 3600);
        const mins = time < 0 ? Math.floor(Math.abs(time) % 3600 / 60) : Math.ceil(Math.abs(time) % 3600 / 60);
        return `${hours}h ${mins}min`;
    }

    useEffect(()=>{
        const params = {
            date: toTimestamp(month === null ? new Date() : month),
            user_name: debouncedSearchTerm,
            page: page,
            per_page: perPage,
            sortBy,
            sortType
        };
        dispatch(getAllStats(params));
    },[month, showStats, page, perPage, debouncedSearchTerm, sortType, sortBy]);

    useEffect(() => {
        setTableData(stats ? stats : []);
    }, [stats]);

    const mappedTableData = useMemo(() => tableData?.map(user => {
        
        return { 
            name: user.user_full_name, 
            work_time: getTotalHoursMins(user.work_time), 
            remaining_time: getTotalHoursMins(user.remaining_time), 
            overtime: user.over_time >= 60 ? <span className="overtime">{getTotalHoursMins(user.over_time)}</span> : "", 
            sum:  (getTotalHoursMins(user.over_time) === getTotalHoursMins(user.remaining_time) && "0h 0min")||
                  (user.sum >= 60 && <span className="overtime">{getTotalHoursMins(user.sum)}</span>) || 
                  (user.sum <= -60 && <span className="undertime">{getTotalHoursMins(user.sum)}</span>) || 
                  (0 < user.over_time - user.remaining_time < 60 && <span className="overtime">0h 1min</span>) ||
                  (-60 < user.over_time - user.remaining_time < 0 && <span className="undertime">0h 1min</span>),
            time_off: getTotalHoursMins(user.time_off), 
            dots: <div className="show-stats-btn" onClick={()=>{
                        setShowStats(true);
                        setUser(user);
                    }
                } >Details</div> }
    }), [tableData]); 

    const handleSearchChange = useCallback((e) => {
        setUserName(e.target.value);
        setTableData(stats);
    }, [stats]);

    const handleSortTableResults = useCallback((sort) => {
        toggleSortDirection(sort);
    }, [toggleSortDirection]);

    const tableColumns = useMemo(()=>[
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("user_name")}>NAME</span>,
            accessor: "name"
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("work_time")}>WORK TIME</span>,
            accessor: "work_time"
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("remaining_time")}>REMAINING TIME</span>,
            accessor: "remaining_time"
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("overtime")}>OVERTIME</span>,
            accessor: "overtime"
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("sum")}>SUM</span>,
            accessor: "sum"
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("time_off")}>TIME OFF</span>,
            accessor: "time_off"
        },
        {
            Header: "",
            accessor: "dots"
        }
    ], [handleSortTableResults])

    const downloadFile = (fileData, filename) => {
        const blob = new Blob([fileData]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }

    const exportCsv = async () => {
        let date = toTimestamp(month);
        try {
            let response = await StatsService.exportCsv(date);
            let filedata = response.data;
            let filename = 'all_users_monthly_logs.xlsx';
            downloadFile(filedata, filename);
        } catch (error) {
            console.log(error);
        }
    }
    
    return showStats === true ?
            <UserStats page="Stats" setShowStats={setShowStats} month={month} user={user} />
            :
            <>
            <PageHeader title={"Stats"} />
            <UsersStats>
                <div className="above">
                    <div className="search-container">
                        <input onChange={handleSearchChange} value={userName} type="search" placeholder="Search users..." />
                    </div>
                    <div className="calendar-container">
                        <div className="export-btn" onClick={() => exportCsv()}><File className="file-icon"/> Export CSV
                        </div>
                        <Calendar startDate={month} setStartDate={setMonth} monthly={true} yearDropdown={true}
                                  maxDate={new Date()}/>
                    </div>
                </div>
                <div className="table">
                    {loading ?
                        <LoadingTable />
                    : 
                    <Table 
                        columns={tableColumns}
                        data={mappedTableData}
                        pagination={
                            {
                                totalResults: pagination.total,
                                page,
                                perPage,
                                setPage,
                                setPerPage,
                                from: pagination.from,
                                to: pagination.to
                            }
                        }
                     /> }
                </div>
            </UsersStats>
            </>
}
export default Stats;