import styled from "styled-components";

export const Wrapper = styled.div`
    background-color: ${props => props.type === 'error' ? '#FFE1E1' : 
                                props.type === 'warning' ? '#FFF5DE' :
                                props.type === 'info' ? '#F1F7FF' : 
                                props.type === 'success' ? '#E3FFF1' : 'black'};
    
    border: 1px solid ${props => props.type === 'error' ? '#FFBCBC' : 
                                props.type === 'warning' ? '#FFD87C' :
                                props.type === 'info' ? '#ABDFFF' : 
                                props.type === 'success' ? '#A4F6CD' : 'black'};;
    border-radius: 10px;
    min-height: 48px;
    height: fit-content;
    width: 500px;
    font-family: 'Roboto Regular', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    

    .close{
        stroke: ${props => props.type === 'error' ? '#E43F3F' : 
                        props.type === 'warning' ? '#F0A905' :
                        props.type === 'info' ? '#529CCA' : 
                        props.type === 'success' ? '#4CC186' : 'black'};
        stroke-width: 0.2;
        height: 9px;
        width: 9px;
    }

    path{
        fill: ${props => props.type === 'error' ? '#E43F3F' : 
                        props.type === 'warning' ? '#F0A905' :
                        props.type === 'info' ? '#529CCA' : 
                        props.type === 'success' ? '#4CC186' : 'black'};
    }

    .close:hover{
        cursor: pointer;
    }
    
    .message {

        font-size: 12px;
        color: ${props => props.type === 'error' ? '#E43F3F' : 
                        props.type === 'warning' ? '#F0A905' :
                        props.type === 'info' ? '#529CCA' : 
                        props.type === 'success' ? '#4CC186' : 'black'};
        letter-spacing: 0.17px;
        line-height: 14px;
        margin-right: 18px;
        max-width: 80%;
    }
`