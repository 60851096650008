import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 169px;
  background-color: #FFFFFF;
  z-index: 100;
  color: #50637F;
  padding: 41px 61px 0 61px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;

  .tabs-counter{
    display: flex;
    justify-content: space-between;
  }

`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .title-profile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-title-date {
      display: flex;
      align-items: center;

      .title {
        margin-left: 7px;
        width: 250px;
        height: 52px;
        font-size: 22px;
        line-height: 52px;
        text-align: center;
        letter-spacing: 0.305556px;
        
        position: relative;

        .date {
          position: absolute;
          left: 12.5px;
          top: 60px;
          font-size: 14px;
          line-height: 0;
          letter-spacing: 0.194444px;
        }
      }
  }
  
    .profile{
      display: flex;
      align-items: center;
      
      .name{
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.685714px;
        margin-right: 10px;
      }

      .image{
        position: relative;
        .online{
          width: 17px;
          height: 17px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          right: -5px;
          background-color: #4CC186;
          border: 2px solid white;
          

        }
        .picture-container{
          width: 50px;
          height: 50px;
          .profile-image{
            width:100%;
            height:100%;
            object-fit: cover;
            overflow: hidden;
            border-radius: 50%;
            
        }
        }
        
      }

      
      
      .menu-toggle {
        width: 23px;
        height: 23px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;
        
        :hover{
          cursor: pointer;
        }
        
        .circle{
          width: 5.75px;
          height: 5.75px;
          border-radius: 50%;
          background: #405573;
        
        }
      }
    }
  }
`

export const Dropdown = styled.div`
  position: absolute;
  width: 190px;
  height: 40px;
  bottom: 24px;
  right: 61px;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  display: ${props => props.opened ? 'flex' : 'none'};
  font-size: 14px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 8px 0px rgba(62, 85, 115, 0.24);
  color: #405573;
  font-family: 'Robot Regular', sans-serif;
  
  :hover{
    cursor: pointer;
  }
`

export const Tabs = styled.div`
  margin-left: 80px;
  height: 27px;
  display: inline-flex;
  gap: 30px;
  
  .tab{
    font-size: 14px;
    color: inherit;
    text-decoration: none;
  }
  
  .tab-selected{
    color: #F9C36C;
    border-bottom: 2px solid #F9C36C;
  }
`



