import styled from "styled-components";

export const StatsWrapper = styled.div`
    margin: 20px 127px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;;
  width: 100%;
  height: 52px;
  
  .title{
    font-size: 20px;
    color: #50637F;
    display: flex;
    gap: 8px;

    .back-button {
      cursor: pointer;
      font-size: 20px;
      color: #A3A3A3;
      width: max-content;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  
  .time-left{
    font-size: 16px;
    color: #50637F;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  gap: 20px;
  background-color: #ffffff;
  min-height: 500px;
  padding: 20px;

  .user-name{
    color: #50637F;
    font-size: 22px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;

  h3{
    font-size: 20px;
    font-weight: 300;
    color: #50637F;
  }

  .monthly{
    width: 50%;
    padding-right: 50px;
    border-right: 1px solid #E8E8E8;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .table {
      position: relative;
      height: 100%;
    }

    .calendar-exportbtn{
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .export-btn{
        color: #3E5573;
        font-size: 13px;
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;

        svg{
          width: 11px;
          height: 15px;
          path{
            fill: #3E5573;
          }
        }
      }

      .export-btn:hover{
        color: #FBB106;
        svg{
          path{
            fill: #FBB106;
          }
        }
      }
    }
  }

  .daily{
    width: 50%;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .navigation {
      display: flex;
      justify-content: space-between;
      .calendar-todaybtn{
        display: flex;
        gap: 20px;
        justify-content: space-between;
        .today-wrapper {
          display: flex;
          align-items: center;
          .today {
            width: 90px;
            height: 38px;
            font-size: 14px;
            font-weight: 400;
            color: #6D8DB7;
            background: #F1F7FF;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            &:hover {
              background: #D7E8FF;
              color: #5477A6;
            }
          }
        }
      }
    }

    .table {
      position: relative;
      height: 100%;
    }
  }
  .green {
    color: #4CC186;
  }

  .red {
    color: #E43F3F;
  }
`