import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "../../../../HOC/Modal/Modal";
import Button from "../../Button/Button";
import Edit from "../Edit/Edit";

import {ReactComponent as EditIcon} from "../img/marker-alt.svg";
import {ReactComponent as TrashIcon} from "../img/trash.svg";
import { deleteUserLog, getUserDailyStats, getUserMonthlyStats } from "../UserStats.thunk";
import { DeleteModal, LogOptionsWrapper } from "./LogOptions.style";


const LogOptions = ({log, user, toTimestamp}) => {
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const dispatch = useDispatch();
    
    const deleteLogHandler = (id) => {
        let user_id = user.user_id
        const params = {id, user_id};
        dispatch(deleteUserLog(params));
        const reloadParams = {
            user_id : user.user_id,
            timestamp : log.time
        }
        dispatch(getUserDailyStats(reloadParams));
        dispatch(getUserMonthlyStats(reloadParams))
    }

    return(
        <LogOptionsWrapper>
            <div className="edit daily-icon" onClick={()=>setEditModal(true)}>
                <EditIcon />
                <Modal hide={()=>setEditModal(false)} isShowing={editModal} >
                    <Edit 
                        user={user} 
                        log={log} 
                        hideModal={()=>setEditModal(false)} 
                        toTimestamp={toTimestamp}
                    />
                </Modal>
            </div>
            <div className="delete daily-icon" >
                <Modal hide={()=>setDeleteModal(false)} isShowing={deleteModal} >
                    <DeleteModal>
                        <div className="user">Delete Stats - {user.user_full_name}</div>
                        <div className="question">Are you sure you want to delete time from the table?</div>
                        <div className="buttons">
                            <Button width={100} value="Delete" onClick={()=>deleteLogHandler(log.id)} />
                            <button className="btn cancel" onClick={()=>setDeleteModal(false)} >Cancel</button>
                        </div>
                    </DeleteModal>
                </Modal>
                <TrashIcon onClick={()=>setDeleteModal(true)} />
            </div>
        </LogOptionsWrapper>
    )
}

export default LogOptions;