import {createGlobalStyle} from "styled-components";

import RobotoBlack from './assets/fonts/Roboto-Black.ttf'
import RobotoBlackItalic from './assets/fonts/Roboto-BlackItalic.ttf'
import RobotoBold from './assets/fonts/Roboto-Bold.ttf'
import RobotoBoldItalic from './assets/fonts/Roboto-BoldItalic.ttf'
import RobotoItalic from './assets/fonts/Roboto-Italic.ttf'
import RobotoLight from './assets/fonts/Roboto-Light.ttf'
import RobotoLightItalic from './assets/fonts/Roboto-LightItalic.ttf'
import RobotoMedium from './assets/fonts/Roboto-Medium.ttf'
import RobotoMediumItalic from './assets/fonts/Roboto-MediumItalic.ttf'
import RobotoRegular from './assets/fonts/Roboto-Regular.ttf'
import RobotoThin from './assets/fonts/Roboto-Thin.ttf'
import RobotoThinItalic from './assets/fonts/Roboto-ThinItalic.ttf'

import RCBold from './assets/fonts/RobotoCondensed-Bold.ttf'
import RCBoldItalic from './assets/fonts/RobotoCondensed-BoldItalic.ttf'
import RCItalic from './assets/fonts/RobotoCondensed-Italic.ttf'
import RCLight from './assets/fonts/RobotoCondensed-Light.ttf'
import RCLightItalic from './assets/fonts/RobotoCondensed-LightItalic.ttf'
import RCRegular from './assets/fonts/RobotoCondensed-Regular.ttf'

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'Roboto Italic';
    src: url(${RobotoItalic});
  }

  @font-face {
    font-family: 'Roboto Black';
    src: url(${RobotoBlack});
  }

  @font-face {
    font-family: 'Roboto Black Italic';
    src: url(${RobotoBlackItalic});
  }

  @font-face {
    font-family: 'Roboto Bold';
    src: url(${RobotoBold});
  }

  @font-face {
    font-family: 'Roboto Bold Italic';
    src: url(${RobotoBoldItalic});
  }
  
  @font-face {
    font-family: 'Roboto Light';
    src: url(${RobotoLight});
  }
  
  @font-face {
    font-family: 'Roboto Light Italic';
    src: url(${RobotoLightItalic});
  }
  
  @font-face {
    font-family: 'Roboto Medium';
    src: url(${RobotoMedium});
  }
  
  @font-face {
    font-family: 'Roboto Medium Italic';
    src: url(${RobotoMediumItalic});
  }
  
  @font-face {
    font-family: 'Roboto Regular';
    src: url(${RobotoRegular});
  }

  @font-face {
    font-family: 'Roboto Thin';
    src: url(${RobotoThin});
  }

  @font-face {
    font-family: 'Roboto Thin Italic';
    src: url(${RobotoThinItalic});
  }

  @font-face {
    font-family: 'Roboto Condensed Bold';
    src: url(${RCBold});
  }

  @font-face {
    font-family: 'Roboto Condensed Bold Italic';
    src: url(${RCBoldItalic});
  }

  @font-face {
    font-family: 'Roboto Condensed Italic';
    src: url(${RCItalic});
  }

  @font-face {
    font-family: 'Roboto Condensed Light';
    src: url(${RCLight});
  }

  @font-face {
    font-family: 'Roboto Condensed Light Italic';
    src: url(${RCLightItalic});
  }

  @font-face {
    font-family: 'Roboto Condensed Regular';
    src: url(${RCRegular});
  }
`

export default FontStyles