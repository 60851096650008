import api from "../libs/api";
import {showToast} from '../shared/utils/showToast'

import {logout} from '../reducers/authReducer'

class NetworkService {

    setupInterceptors(store){

        api.interceptors.request.use(function (config) {
            const token = localStorage.getItem('token');
            config.headers.Authorization = token ? `Bearer ${token}` : '';
            return config;
        });

        api.interceptors.response.use(function (response){
            return response
        }, function(error){
            if(error.response.status === 500) showToast(error.response.data.message, "error")
            if(error.response.status === 419){
                store.dispatch(logout())
            } 
            return Promise.reject(error)
        })
    }

}

export default new NetworkService()