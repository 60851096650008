import React, { useRef, memo, useState, useCallback} from 'react'

import PropTypes from 'prop-types'

import {Wrapper, DropdownContainer, Header, ErrorMsg} from './Dropdown.style'

import { useOnClickOutside } from '../../../hooks'

import {ReactComponent as ArrowDown} from '../../../assets/icons/angle-down.svg'

const Dropdown = ({placeholder, children, value, error}) => {
    const [opened, setOpened] = useState(false)
    const ref = useRef()

    useOnClickOutside(ref, () => setOpened(false))

    const handleOpenDropdown = useCallback(() => {
        setOpened(!opened)
    }, [opened])

    return (
        <Wrapper ref={ref}>
            <Header error={error} onClick={handleOpenDropdown} focused={opened}>{value ? <span className="value">{value}</span> : <span className="placeholder">{placeholder}</span>}<ArrowDown className="arrow-down"/></Header>
            <ErrorMsg>{error}</ErrorMsg>
            <DropdownContainer opened={opened}>
                <div onClick={() => setOpened(false)}>{children}</div>
            </DropdownContainer>
        </Wrapper>
    )
}

export default memo(Dropdown)

Dropdown.propTypes = {
    palceholder: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string
}
