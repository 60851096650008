import React, {memo} from 'react'

import PropTypes from 'prop-types'

import { ErrorMsg } from "../Dropdown/Dropdown.style";
import { Label, TextAreaStyled, Wrapper  } from "./TextArea.style";

const TextArea = ({error, onChange, value, rows = 10, maxLength = 300, label}) => {
    console.log("Text area rerendered")
    return <Wrapper >
        <Label>{label}</Label>
        <TextAreaStyled error={error} onChange={onChange} value={value} rows={rows} maxLength={maxLength}/>
        <ErrorMsg>{error}</ErrorMsg>
    </Wrapper>
}

export default memo(TextArea)

TextArea.propTypes = {
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    rows: PropTypes.number,
    maxLength: PropTypes.number
}