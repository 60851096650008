import React from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Wrapper } from './DeleteRequest.style'

import Button from '../../../../shared/components/Button/Button'

import TimeOffService from '../TimeOff.service'

import { showToast } from '../../../../shared/utils/showToast'
import { useKeypress } from '../../../../hooks'

const DeleteRequest = ({hideModal, id, onDelete, setLoadingModal}) => {
    const {user} = useSelector(state => state.auth)

    const handleDeleteRequest = async () => {
        try {
            setLoadingModal(true)
            const response = await TimeOffService.delete(id, user.id)
            showToast(response.data.message, "success")
            onDelete()
            setLoadingModal(false)
            hideModal()
        } catch (error) {
            showToast(error.response.data.message, "error")
        } 
    }

    useKeypress('Enter', handleDeleteRequest)
    useKeypress('Escape', hideModal)

    return (
        <Wrapper>
            <div className="title">Delete Time Off Request</div>
            <div className="content">Are you sure you want to delete time off request?</div>
            <div className="button-group">
                <div className="cancel-button" onClick={hideModal}>Cancel</div>
                <Button value="Delete" onClick={handleDeleteRequest} width={124}></Button>
            </div>
        </Wrapper>
    )
}

export default DeleteRequest


DeleteRequest.propTypes = {
    id: PropTypes.number.isRequired,
    hideModal: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
}