import api from '../../../libs/api'

class ProfileService {
    async updateProfile(id ,first_name, last_name, email, birthday, current_password, password, confirm_password){
        
        return await api.put(`user/${id}`, {
            first_name,
            last_name,
            email,
            birthday,
            current_password,
            password,
            password_confirmation: confirm_password
        })


    }

    async uploadProfilePicture(image){
        const data = new FormData()
        data.append('image', image)
        return await api.post('upload-image', data)
    }

    async removeProfilePicture(){
        return await api.delete('delete-image')
    }
}

export default new ProfileService()