import React, {useState, useEffect, useCallback} from 'react'
import {useSelector, useDispatch} from "react-redux";

import {Wrapper} from "./Profile.style";

import UploadPhoto from './UploadPhoto/UploadPhoto';
import Input from "../../../shared/components/Input/Input";
import Calendar from "../../../shared/components/Calendar/Calendar";
import Button from "../../../shared/components/Button/Button";

import UsersService from "../../Users/Users.service";
import ProfileService from './Profile.service';

import {updateUser} from '../../../reducers/authReducer'

import { showToast } from '../../../shared/utils/showToast';

const Profile = () => {
    const {user} = useSelector(state => state.auth)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [birthday, setBirthday] = useState(new Date())
    const [currentPassword, setCurrentPassword] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [profileImage, setProfileImage] = useState()
    const [picture, setPicture] = useState(null)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(null)

    const dispatch = useDispatch()

    const fetchUser = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await UsersService.getUserById(user.id)
            setFirstName(data.user.first_name)
            setLastName(data.user.last_name)
            setEmail(data.user.email)
            setBirthday(data.user.birthday)
            setPicture(data.user.image)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }, [user.id])



    const updateUserProfile = async () => {
        try {
            setLoading(true)
            const birthDay = new Date(new Date(birthday).setHours(new Date(birthday).getHours() + 2))
            console.log(picture)
            picture !== user.image && (!picture ? await ProfileService.removeProfilePicture() : await ProfileService.uploadProfilePicture(picture))
            const {data} = await ProfileService.updateProfile(user.id, firstName, lastName, email, birthDay, currentPassword, password, confirmPassword)
            localStorage.setItem('user', JSON.stringify(data.data.user))
            setErrors(null)   
            showToast(data.message, 'success')
            dispatch(updateUser(data.data.user))
            setPassword("")
            setConfirmPassword("")
            setLoading(false)
        } catch (error) {
            showToast(error.response.status === 413 ? "Profile picture size must be less then 8MB" : error.response.data.message, "error")
            setErrors(error.response.data.data)
            setLoading(false)
        }
    }

    useEffect(()=>{
        fetchUser()
    }, [fetchUser])


    return <Wrapper>
        <div className="profile-info">
            <h3>Profile Info</h3>
            <Input error={errors && 'first_name' in errors ? errors.first_name[0] : null} value={firstName} type="text" placeholder="First Name" onChange={setFirstName} label="FIRST NAME"/>
            <Input error={errors && 'last_name' in errors ? errors.last_name[0] : null} value={lastName} type="text" placeholder="Last Name" onChange={setLastName} label="LAST NAME"/>
            <label className="label">DATE OF BIRTH</label>
            <Calendar yearDropdown={true} startDate={new Date(birthday)} setStartDate={setBirthday} />
            <UploadPhoto setPicture={setPicture}/>
            {/* <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif" onChange={(e) => setpicture(e.target.files[0])}></input> */}
        </div>
        <div className="login-info">
            <h3>Login Info</h3>
            <Input error={errors && 'email' in errors ? errors.email[0] : null} value={email} type="email" placeholder="Email" onChange={setEmail} label="EMAIL"/>
            <Input error={errors && 'current_password' in errors ? errors.current_password[0] : null} value={currentPassword} type="password" placeholder="Current Password" onChange={setCurrentPassword} label="CURRENT PASSWORD"/>
            <Input error={errors && 'password' in errors ? errors.password[0] : null} value={password} type="password" placeholder="Password" onChange={setPassword} label="PASSWORD"/>
            <Input error={errors && 'password' in errors ? errors.password[0] : null} value={confirmPassword} type="password" placeholder="Confirm Password" onChange={setConfirmPassword} label="CONFIRM PASSWORD"/>
            <div className="save">
                <Button onClick={updateUserProfile} value="Save" width={100} />
            </div>
        </div>
    </Wrapper>
}

export default Profile