import React, {useEffect, useState, useMemo, useCallback} from "react";
import { useSelector } from "react-redux";

import {Wrapper} from "./Status.style";

import Table from "../../../shared/components/Table/Table";
import Calendar from "../../../shared/components/Calendar/Calendar";
import LoadingTable from "../../../shared/components/Table/LoadingTable/LoadingTable";
import Button from '../../../shared/components/Button/Button'

import StatusService from "./Status.service";

import { showToast } from "../../../shared/utils/showToast";

const secondsToHoursAndMinutes = (sec) => {
    const hours = Math.floor(Math.abs(sec) / 3600)
    const minutes = Math.floor(Math.abs(sec) % 3600 / 60)
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
}

const secondsToHourMinSec = (sec) => {
    const hours = Math.floor(sec / 3600)
    const minutes = Math.floor(sec % 3600 / 60)
    const seconds = Math.floor(sec % 3600 % 60)
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
}

const getTimeFromTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000)
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${hours < 10 ? '0'+hours : hours}:${minutes < 10 ? '0'+minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
}


const Status = () => {
    const date = new Date()
    const [monthlyDate, setMonthlyDate] = useState(date)
    const [dailyDate, setDailyDate] = useState(date)
    const [dailyStats, setDailyStats] = useState([])
    const [monthlyStats, setMonthlyStats] = useState([])
    const [loadingDaily, setLoadingDaily] = useState(false)
    const [loadingMonthly, setLoadingMonthly] = useState(false)
    const [workTime, setWorkTime] = useState(0)

    const {user} = useSelector(state => state.auth)

    const fetchDailyStats = useCallback(async () => {
        try {
            setLoadingDaily(true)
            const {data}= await StatusService.getDailyStats(user.id, Date.parse(dailyDate) / 1000)
            setDailyStats(!data.data.daily_logs ? [] : data.data.daily_logs)
            setWorkTime(data.data.work_time)
            setLoadingDaily(false)
        } catch (error) {
            showToast(error.response.data.message, "error")
            setLoadingDaily(false)
        }
        
    }, [user, dailyDate])

    const fetchMonthlyStats = useCallback(async () => {
        try {
            setLoadingMonthly(true)
            const {data} = await StatusService.getMonthlyStats(user.id, Date.parse(monthlyDate) / 1000)
            setMonthlyStats(data.data.monthly_logs)
            setLoadingMonthly(false)
        } catch (error) {
            showToast(error.response.data.message, "error")
            setLoadingMonthly(false)
        }
        
    }, [user, monthlyDate])

    const dailyTableData = useMemo(() => {
        return dailyStats?.map((stat) => {
            return {
                date: `${dailyDate.getDate()}.${dailyDate.getMonth() + 1}.${dailyDate.getFullYear()}.`,
                time: stat.time,
                type: stat.log_type
            }
        })
    }, [dailyStats, dailyDate])

    const monthlyTableData = useMemo(() => {
        return monthlyStats?.map(stat => {
            const date = new Date(stat.date * 1000)
            return {
                date: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`,
                worked: stat.work_time,
                overtime: stat.over_left_time,
                left: stat.left_time

            }
        })
    }, [monthlyStats])

    useEffect(() => {
        fetchMonthlyStats()
    }, [fetchMonthlyStats])

    useEffect(() => {
        fetchDailyStats()
    }, [fetchDailyStats])

    const monthlyColumns = useMemo(() => [      
        {
            Header: 'DATE',
            Footer: 'TOTAL',
            accessor: 'date'
        },
        {
            Header: 'WORKED',
            accessor: 'worked',
            Cell: worked => secondsToHoursAndMinutes(worked.row.original.worked),
            Footer: worked => {
                const total = useMemo(
                () => worked.rows.reduce((sum, row) => row.values.worked + sum, 0)
                , [worked.rows])
                return secondsToHoursAndMinutes(total).split(':')[0] + 'h ' + secondsToHoursAndMinutes(total).split(':')[1] + 'min'
            }
        },
        {
            Header: 'OVER/LEFT TIME',
            accessor: 'over_left_time',
            Cell: ({row}) => {
                const overtime = row.original.overtime
                if(overtime > 0) return <span style={{color:"#4CC186"}}>{secondsToHoursAndMinutes(overtime)}</span>
                if(overtime < 0) return <span style={{color:"#E43F3F"}}>{secondsToHoursAndMinutes(overtime)}</span>
                return secondsToHoursAndMinutes(overtime)
            },
            Footer: overtime => {
                const total = useMemo(
                () => overtime.rows.reduce((sum, row) => row.original.overtime + sum, 0)
                , [overtime.rows])
                if(total > 0) return <span style={{color:"#4CC186"}}>{secondsToHoursAndMinutes(total).split(':')[0] + 'h ' + secondsToHoursAndMinutes(total).split(':')[1] + 'min'}</span>
                if(total <= 0) return <span style={{color:"#E43F3F"}}>{secondsToHoursAndMinutes(total).split(':')[0] + 'h ' + secondsToHoursAndMinutes(total).split(':')[1] + 'min'}</span>
            }
        },
    ], [])

    const dailyColums = useMemo(() => [
        {
            Header: 'DATE',
            Footer: 'TOTAL WORKED',
            accessor: 'date'
        },
        {
            Header: 'TIME',
            accessor: 'time',
            Cell: left => getTimeFromTimestamp(left.row.original.time),
            Footer: secondsToHourMinSec(workTime)
        },
        {
            Header: 'STATUS',
            accessor: 'type',
            Cell: ({row}) => {
                if (row.original.type === 'Login'){
                    return <span style={{color: "#4CC186"}}>{row.original.type}</span>
                }

                return <span style={{color: "#E43F3F"}}>{row.original.type}</span>
            }
        },

    ], [workTime])

    const handleMonthlyRowClick = (row) => {
        const dateArr = row.original.date.split('.')
        const date = new Date(dateArr[2], dateArr[1] - 1, dateArr[0])
        setDailyDate(date)
    }

    return (
        <Wrapper>
            <div className="monthly">
                <h3>Monthly</h3>
                <Calendar monthly={true} startDate={monthlyDate} setStartDate={setMonthlyDate} maxDate={new Date()} />
                <div className="table">
                {loadingMonthly ? <LoadingTable /> : monthlyStats && <Table onRowClicked={handleMonthlyRowClick} hasFooter columns={monthlyColumns} data={monthlyTableData} />}
                </div>
                
            </div>

            <div className="daily">
                <h3>Daily</h3>
                <div className="daily-datepicker">
                    <Calendar startDate={dailyDate} setStartDate={setDailyDate} maxDate={new Date()} />
                    <div className="button">
                        <div className="today-button" onClick={() => setDailyDate(new Date())}>Today</div>
                    </div>
                </div>
                <div className="table">
                {loadingDaily ? <LoadingTable /> : dailyStats && <Table hasFooter columns={dailyColums} data={dailyTableData} />}
                </div>
                
            </div>
        </Wrapper>
    )
}

export default Status