import { createAsyncThunk } from "@reduxjs/toolkit";
import { showToast } from "../../../shared/utils/showToast";

import TimeOffService from "./TimeOff.service";

export const getUsersTimeOffs = createAsyncThunk(
    "userTimeOffs/get",
    async (params, {rejectWithValue}) => {
        try {
            const {dateFrom, dateTo, page, perPage, status, userId, sortBy, sortType} = params
            const response = await TimeOffService.getAll(dateFrom, dateTo, page, perPage, status, userId, sortBy, sortType)
            return response
        } catch (error) {
            showToast(error.response.data.message, 'error')
            return rejectWithValue(error.response.data)
        }
    }
)

export const editUsersTimeOff = createAsyncThunk(
    'userTimeOffs/edit',
    async (params, {rejectWithValue}) => {
        try {
            const {id, userId, fromDate, toDate, note, reason} = params
            return await TimeOffService.edit(id, userId, fromDate, toDate, note, reason)
        } catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
