import styled from "styled-components";

export const Wrapper = styled.div`
display: inline-flex;
align-items: center;
margin-right: 60px;

    .dot{
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: #FBB106;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
    }
    .text{
        font-family: 'Robot Regular', sans-serif;
        font-size: 14px;
        color: #405573;
    }
`

export const WorkTime = styled.span`
    font-family: 'Robot Regular', sans-serif;
    font-size: 14px;
    color: ${props => props.overtime ? '#4CC186' : '#E43F3F'};
    margin-left: 10px;
`

export const BreakTime = styled.span`
    font-family: 'Robto Regular', sans-serif;
    font-size: 14px;
    color: #4CC186;
    margin-left: 10px;
`