import React, {useState, useRef} from "react";
import {useHistory} from 'react-router'

import { DotsContainer } from "./RequestsOptions.style";

import Modal from "../../../../HOC/Modal/Modal";
import EditTimeOffRequest from "../EditRequest/EditRequest";
import DeleteRequest from "../DeleteRequest/DeleteRequest";
import OptionsDropdown from "../../../../shared/components/OptionsDropdown/OptionsDropdown";
import Option from "../../../../shared/components/OptionsDropdown/Option/Option";

import useModal from "../../../../hooks/useModal";
import { useOnClickOutside } from "../../../../hooks";

const RequestsOptions = ({timeOffRequest, onDelete, fetchParams}) => {
    const {showing, toggle, loadingModal, setLoadingModal} = useModal();
    const [dropdownOpened, setDropdownOpened] = useState(false)
    const [modalOpened, setModalOpened] = useState(null)
    const ref = useRef()
    const history = useHistory()

    const handleOpenModal = (modal) => {
        setModalOpened(modal)
        setDropdownOpened(false)
        toggle()
    }


    useOnClickOutside(ref, () => setDropdownOpened(false))

    return(
        <DotsContainer  ref={ref} opened={dropdownOpened}>
            <button className="dropbtn" onClick={() => setDropdownOpened(!dropdownOpened)}>...</button>
            <div className="dropdown">
                <OptionsDropdown>
                    <Option onClick={() => history.push({ 
                        pathname: `/time-off/requests/edit/${timeOffRequest.user_id}/${timeOffRequest.id}`,
                        state: {
                            fetchParams
                        }
                        
                        })}>Edit</Option>
                    {timeOffRequest.date_to < Date.parse(new Date()) / 1000 && timeOffRequest.status.toLowerCase() === 'approved' ? 
                         null :
                         <Option onClick={() => handleOpenModal("delete")}>Delete</Option>
                    }
                    
                </OptionsDropdown>
                {modalOpened === "edit" ? (
                    <Modal isShowing={showing} hide={toggle} loading={loadingModal}>
                        <EditTimeOffRequest hideModal={toggle} request={timeOffRequest} setLoadingModal={setLoadingModal}>Edit</EditTimeOffRequest>
                    </Modal>
                ) : modalOpened === "delete" ? (
                    <Modal isShowing={showing} hide={toggle} loading={loadingModal} >
                        <DeleteRequest hideModal={toggle} id={timeOffRequest.id} user_id={timeOffRequest.user_id} onDelete={onDelete} setLoadingModal={setLoadingModal}>Edit</DeleteRequest>
                    </Modal>
                ) : null}
            </div>
        </DotsContainer>
    )
}

export default RequestsOptions;