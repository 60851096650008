export const checkIfEventsOverlap = (row, event) => {
    let overlaps = false
    row.forEach((eventInRow) => {
        if (eventInRow.date_from <= event.date_to && event.date_from <= eventInRow.date_to) {
            overlaps = true
        } // two starts in one
        if (eventInRow.date_from <= event.date_to   && event.date_to   <= eventInRow.date_to) {
            overlaps = true
        } // two ends in one
        if (event.date_from <  eventInRow.date_from && eventInRow.date_to   <  event.date_to) {
            overlaps = true
        } // one in two
    })

    return overlaps;
}