import React, {useState, useEffect, useRef} from "react";

import PropTypes from 'prop-types'

import { Wrapper } from "./MultiselectUsersDropdown.style";
import {ReactComponent as ArrowDown} from '../../../assets/icons/angle-down.svg'

import Checkbox from '../Checkbox/Checkbox'
import MultiselectPills from "./MultiselectPills/MultiselectPills";

import UsersService from "../../../views/Users/Users.service";
import { useOnClickOutside } from "../../../hooks";


const MultiselectUsersDropdown = ({onChange, selected=[]}) => {
    const [inputValue, setInputValue] = useState("")
    const [filteredUsers, setFilteredUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [allUsersSelected, setAllUsersSelected] = useState(false)
    const [users, setUsers] = useState([])
    const [dropdownOpened, setDropdownOpened] = useState(false)
    const ref = useRef()
    
    const fetchUsers = async () => {
        const {data} = await UsersService.getUsers('', 1, 1000)
        setUsers(data.data.users.data)
    }
    
    useEffect(() => {
        fetchUsers()
    }, [])

    useEffect(() => {
        // console.log(users.filter(user => selected.includes(user.id.toString())))
        if(selected.length > 0){
            setSelectedUsers(users.filter(user => selected.includes(user.id)));
        }

    }, [selected, users])

    useEffect(() => {
        onChange(selectedUsers)
    }, [selectedUsers, onChange]);

    const fetchSelectedUsers = () => {
       setSelectedUsers(users.filter(user => selected.includes(user.id)));
    }

    useEffect(() => {
        if(users){
        setFilteredUsers(
            users.filter((item) => {
                return item.first_name.toLowerCase().includes(inputValue.toLowerCase()) || item.last_name.toLowerCase().includes(inputValue.toLowerCase())
            })
        )}
    }, [inputValue, users])

    //Check if all users are selected
    useEffect(() => {
        selectedUsers.length === users.length && selectedUsers.length > 0 ? setAllUsersSelected(true) : setAllUsersSelected(false)
    }, [selectedUsers, users])



    const handleUserCheckboxSelect = (user) => {
        if(selectedUsers.some(selectedUser => parseInt(selectedUser.id) === user.id)){
            //remove user from selected user array
            const newUsers = selectedUsers.filter(selectedUser => parseInt(selectedUser.id) !== user.id)
            setSelectedUsers(newUsers);           
        } else {
            setSelectedUsers([...selectedUsers, user])
        }

    }

    const handleSelectAll = () => {
        setSelectedUsers(users)
        setAllUsersSelected(true)
    }

    const handleClearSelected = () => {
        setSelectedUsers([])
        setAllUsersSelected(false)
    }


    useOnClickOutside(ref, () => setDropdownOpened(false))

    return (
        <Wrapper opened={dropdownOpened}>
                <label>USERS WHO WILL BE NOTIFIED FOR SUBMITTED TIME OFF REQUESTS</label>
                <div ref={ref}>
                    <div className="multiselect-pills-container">
                        {allUsersSelected ? 
                        <MultiselectPills text="All Users Selected" onClose={() => {setAllUsersSelected(false); setSelectedUsers([])}}/> : 
                        selectedUsers.map(user => <MultiselectPills onClose={() => handleUserCheckboxSelect(user)} text={user.first_name + " " + user.last_name} />)
                        }
                    </div>
                    
                    <div className="header" onClick={() => setDropdownOpened(true)}>
                        <input className="filter-users" placeholder={dropdownOpened ? "Search" : "Select Users"} onChange={(e) => setInputValue(e.target.value)}/>
                        {!dropdownOpened && <ArrowDown className="arrow-down" />}       
                    </div>
                    <div className="dropdown">
                        <div className="users-list">
                            {filteredUsers.map(user => <div className="user" key={user.id}> <Checkbox checked={selectedUsers.some(selectedUser => selectedUser.id === user.id)} onClick={() => handleUserCheckboxSelect(user)} size={14} label={user.first_name + " " + user.last_name}/></div>)}
                        </div>
                        <div className="selection">
                            <div onClick={handleSelectAll}>Select All</div>
                            <div onClick={handleClearSelected}>Clear Selected</div>
                        </div>
                    </div> 
                </div>
        </Wrapper>
    )
}

export default MultiselectUsersDropdown

MultiselectUsersDropdown.propTypes = {
    onChange: PropTypes.func
}