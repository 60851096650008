import { createAsyncThunk } from "@reduxjs/toolkit";

import timeOffService from "./Request.service";

import { showToast } from "../../../shared/utils/showToast";

export const getAllTimeOffRequests = createAsyncThunk(
    "timeOffRequests/get",
    async (params, {rejectWithValue}) => {
        try {
            const {dateFrom, dateTo, page, perPage, userName, status, reason, userId, sortBy, sortType} = params
            const response = await timeOffService.get(dateFrom, dateTo, page, perPage, userName, status, reason, userId, sortBy, sortType)
            return response
        } catch (error) {
            showToast(error.response.data.message, 'error')
            console.log(error.response.data)
            return rejectWithValue(error.response.data)
        }
    }
)

export const editTimeOffRequest = createAsyncThunk(
    'timeOffRequests/edit',
    async (params, {rejectWithValue}) => {
        try {
            const {id, userId, fromDate, toDate, note, reason, status} = params
            const response = await timeOffService.edit(id, userId, fromDate, toDate, note, reason, status)
            showToast(response.data.message, "success")
            return response
        } catch(error){
            showToast(error.response.data.message, "error")
            return rejectWithValue(error.response.data)
        }
    }
)
