import {createSlice} from "@reduxjs/toolkit";

import {addUser, deleteUser, editUserDetails, getAllUsers, getUserById} from './Users.thunk'

const initialState = {
    users : [],
    errors : false,
    loading : false,
    user: {},
    loadingUser: false,
    pagination: {}
}

const usersSlice = createSlice({
    name:"users",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
            builder
                //Get all users
                .addCase(getAllUsers.pending, (state)=>{
                    state.loading = true;
                    state.errors = null;
                })
                .addCase(getAllUsers.fulfilled, (state, action) => {
                    state.loading = false;
                    state.pagination = action.payload.data.users;
                    state.users = action.payload.data.users.data;
                })
                .addCase(getAllUsers.rejected, (state, action) => {
                    state.loading = false;
                    state.errors = action.error;
                })

                //Edit user
                .addCase(editUserDetails.pending, (state)=>{
                    state.errors = null;
                    state.loadingUser = true;
                })
                .addCase(editUserDetails.fulfilled, (state, action) => {
                    state.loadingUser = false;
                    const id = action.payload.data.data.user.id;
                    const userIndex = state.users.findIndex(user => user.id === id); 
                    state.users[userIndex] = action.payload.data.data.user;
                })
                .addCase(editUserDetails.rejected, (state, action) => {
                    state.loadingUser = false;
                    state.errors = action.payload.response.data.data;
                })

                //Add user
                .addCase(addUser.pending, (state)=>{
                    state.loading = true;
                    state.errors = null;
                })
                .addCase(addUser.fulfilled, (state, action) => {
                    state.loading = false;
                    state.errors = false;
                    state.users.push(action.payload.data.user);
                })
                .addCase(addUser.rejected, (state, action) => {
                    state.loading = false;
                    state.errors = action.payload.response.data.data;
                })

                //Delete user
                .addCase(deleteUser.pending, (state, action) => {
                    state.errors = null;
                    state.loading = true;
                })
                .addCase(deleteUser.fulfilled, (state, action) => {
                    state.loading = false;
                    const rmId = action.payload.data.data.user.id;
                    state.users = state.users.filter(user => user.id !== rmId);
                })
                .addCase(deleteUser.rejected, (state, action) => {
                    console.log(action.payload);
                })

                //Get User By ID
                .addCase(getUserById.pending, (state, action) => {
                    state.errors = null;
                    state.loadingUser = true;
                })
                .addCase(getUserById.fulfilled, (state, action) => {
                    state.loadingUser = false;
                    state.user = action.payload.user;
                })
                .addCase(getUserById.rejected, (state, action) => {
                    state.loadingUser = false;
                })
        },
});

export const { removeUserfromUi } = usersSlice.actions;

export const usersList = (state) => state.users.users;
export const usersLoading = (state) => state.users.loading;
export const usersListErrors = (state) => state.users.errors;
export const userById = (state) => state.users.user;
export const userLoading = (state) => state.users.loadingUser;
export const usersPagination = (state) => state.users.pagination;

export default usersSlice.reducer;