import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 440px;

    .from-to{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            input {
                width: 100% !important;
            }
        }
    }
    
    .data {
        margin-bottom: 20px;
    }
    
    h3 {
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        color: #50637F;
        margin-bottom: 34px;
    }

    .label{
        color: #50637F;
        font-size: 14px;
    }

    label{
        display: block;
        color: #50637F;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .value{
        color: #A3A3A3;
        font-size: 14px;
    }

    .reason-select-container{
        position: relative;
        width: 206px;
        margin-bottom: 20px;
    }

    .dropdown-option{
        cursor: pointer;
        font-family: 'Roboto Regular', sans-serif;
        font-size: 14px;
        letter-spacing: 0.19px;
        color: #A3A3A3;
        height: 30px;
        padding-left: 9px;
        display: flex;
        align-items: center;
        border-radius: 8px;

        :hover{
        background-color: #F1F7FF;
        }
    }

    .button-group{
        margin-top: 40px;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 20px;

        .cancel-button{
            margin-right: 20px;
            font-family: 'Roboto Regular', sans-serif;
            font-size: 14px;
            color: #B2B1B1;

            :hover{
            cursor: pointer;
            }
        }
    }

    .radio-button-group{
        display: flex;
        gap: 30px;
        align-items: center;
    }

`