import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { editUserDetails, getUserById }from "../Users.thunk";

import Input from "../../../shared/components/Input/Input";
import Checkbox from "../../../shared/components/Checkbox/Checkbox";

import { SpinnerWrapper, Wrapper } from "./EditUser.style";
import Calendar from "../../../shared/components/Calendar/Calendar";
import Button from "../../../shared/components/Button/Button";
import { usersListErrors, userById, userLoading } from "../Users.slice";
import Spinner from "../../../shared/components/Spinner/Spinner";
import MultiselectUsersDropdown from "../../../shared/components/MultiselectUsersDropdown/MultiselectUsersDropdown";
import SelectNumber from "../../../shared/components/SelectNumber/SelectNumber";
import { useKeypress } from "../../../hooks";

const EditUser = ( { setEditModal, id } ) => {
    const year = new Date().getFullYear()
    const errorsState = useSelector(usersListErrors);
    const user = useSelector(userById);
    const loading = useSelector(userLoading);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [vacation, setVacation] = useState();
    const [next_year_vacation, setNextYearVacation] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [errors, setErrors] = useState(false);
    const [selected, setSelected] = useState([]);
    const [emailList, setEmailList] = useState([]);
    
    const dispatch = useDispatch();

    useEffect(()=>setErrors(errorsState), [errorsState]);
    useEffect(()=>setErrors(false), []);

    useEffect(()=>{
        const params = { id };
        dispatch(getUserById(params));
    }, []);

    useEffect(()=>{
        if(user.first_name !== undefined){
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setEmail(user.email);
            setVacation(user.vacation_days);
            if(user.is_admin === true || user.is_admin === "1"){
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
            setStartDate(new Date(new Date(user.birthday).setHours(new Date(user.birthday).getHours() + 2)));
            setSelected(user.notifiers);
            setNextYearVacation(user.next_year_vacation)
        }
    }, [user]);

    const editUser = async (id) => {
        const emailListUserIds = emailList.map(user => {
            return user.id
        })
        
        const params = {
            firstName,
            lastName,
            email,
            vacation,  
            startDate,
            isAdmin,
            id,
            emailListUserIds,
            next_year_vacation
        }
        let response = await dispatch(editUserDetails(params));
        if(response.error && response.error.message === "Rejected"){
            return;
        }else{
            setEditModal(false);
        }
    }

    useKeypress('Enter', editUser)
    useKeypress('Escape', () => setEditModal(false))

    return (
        <Wrapper>
                { loading ? 
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                    :
                    <>
                        <div className="heading">Edit User</div>
                        <div className="input">
                            <Input error={errors && errors.first_name ? errors.first_name[0] : null} placeholder="First Name" onChange={setFirstName} value={firstName} label="FIRST NAME"/>
                        </div>
                        <div className="input">
                            <Input error={errors && errors.last_name ? errors.last_name[0] : null} placeholder="Last Name" onChange={setLastName} value={lastName} label="LAST NAME"/>
                        </div>
                        <div className="input">
                            <Input error={errors && errors.email ? errors.email[0] : null} placeholder="Email" onChange={setEmail} value={email} label="EMAIL"/>
                        </div>
                        <div className="checkbox">
                            <Checkbox onClick={() => setIsAdmin(!isAdmin)} label="Admin" checked={isAdmin} name="admin" />
                        </div>
                        <div className="date-picker">
                            <Calendar startDate={startDate} setStartDate={setStartDate} yearDropdown={true} maxDate={new Date()}  />
                        </div>
                        <div>
                            <MultiselectUsersDropdown selected={selected} onChange={setEmailList}  />
                        </div>
                        <div className="input" style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end'}}>
                            <div style={{flexBasis: '50%'}} >
                                <SelectNumber error={errors && errors.vacation_days ? errors.vacation_days[0] : null} value={vacation} setValue={setVacation} label={"Vacation days (" + year + ")"} />
                            </div>
                            <div style={{flexBasis: '50%'}} >
                                <SelectNumber error={errors && errors.vacation_days ? errors.vacation_days[0] : null} value={next_year_vacation} setValue={setNextYearVacation} label={"Vacation days (" + (year+1) + ")"} />
                            </div>
                        </div>
                        <div className="buttons">
                            <Button width={100} value="Save" onClick={()=>editUser(user.id)} />
                            <button className="btn cancel" onClick={()=>setEditModal(false)} >Cancel</button>
                        </div>
                    </> 
                }
        </Wrapper>
        
    )
}

export default EditUser;