import React, {useState, useEffect, useCallback, useRef} from 'react'

import {Wrapper, Header, DropdownContainer, ErrorMsg, Input} from './FilterUsersDropdown.style'
import {ReactComponent as ArrowDown} from '../../../assets/icons/angle-down.svg'


import UsersService from '../../../views/Users/Users.service'

import {useOnClickOutside} from '../../../hooks/useOnClickOutside'

const FilterUsersDropdown = ({placeholder, error, onSelect, user}) => {
    const [opened, setOpened] = useState(false)
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(user)
    const [filteredUsers, setFilteredUsers] = useState(users)
    const [filterValue, setFilterValue] = useState('')
    const [value, setValue] = useState("")
    const ref = useRef()

    useEffect(() => {
        if(users){
        setFilteredUsers(
            users.filter((item) => {
                return item.first_name.toLowerCase().includes(filterValue.toLowerCase()) || item.last_name.toLowerCase().includes(filterValue.toLowerCase())
            })
        )}
    }, [filterValue, users])


    const fetchUsers = useCallback(async () => {
        try{
            const {data} = await UsersService.getUsers("", 1, 100000)
            setUsers(data.data.users.data)
        } catch(err){
            console.log(err)
        }
    }, [])

    useEffect(() => {
        fetchUsers()
    },[filterValue, fetchUsers])

    const handleOpenDropdown = () => {
        setOpened(!opened)
    }

    const handleSelectUser = (user) => {
        onSelect(user)
        setSelectedUser(user)
        setValue(user.first_name + ' ' + user.last_name)
        setOpened(false)
    }

    const handleAllUserSelect = () => {
        onSelect(null)
        setValue("All Users")
        setOpened(false)
    }

    useOnClickOutside(ref, () => setOpened(false))

    return (
        <Wrapper ref={ref}>
            <Header error={error} onClick={handleOpenDropdown} focused={opened}>{value ? <span className="value">{value}</span> : <span className="placeholder">{placeholder}</span>}<ArrowDown className="arrow-down"/></Header>
            <ErrorMsg>{error}</ErrorMsg>
            <DropdownContainer opened={opened}>
                <Input value={filterValue} onChange={(e) => setFilterValue(e.target.value)} placeholder="Search"></Input>
                <div className='users'>
                    {filterValue ? null : <div onClick={() => handleAllUserSelect()} className='user'>All Users</div>}
                    {filteredUsers?.map(user => {
                        return <div onClick={() => handleSelectUser(user)} className="user">{user.first_name + " " + user.last_name}</div>
                    })}
                </div>
            </DropdownContainer>
        </Wrapper>
    )
}

export default FilterUsersDropdown
