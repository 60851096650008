import styled from "styled-components";

export const Wrapper = styled.div`
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .spinner-container{
        min-width: 440px;
        min-height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .heading {
        font-size: 20px;
        color: #50637F;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .container .checkbox{
        display: flex;
        align-items: baseline;
    }
    .container .checkbox .admin {
        font-weight: 500;
        color: #A3A3A3;
    }

    .container .buttons {
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 20px;
    }

    .container .buttons .btn {
        width: 100px;
        padding: 10px 18px;
        color: #ffffff;
        border: none;
        cursor: pointer;
    }

    .container .buttons .save {
        background-color: #3D5573;
    }

    .container .buttons .cancel {
        background-color: #969696;
    }

    .cancel-button{
        margin-right: 20px;
        font-family: 'Roboto Regular', sans-serif;
        font-size: 14px;
        color: #B2B1B1;
        cursor: pointer;

        :hover{
        color: #506C90;
        }
    }
`;