import React from 'react'

import { Wrapper } from './MultiselectPills.style'

import {ReactComponent as CloseBlackIcon} from '../../../../assets/icons/cancel_black_24dp.svg'

const MultiselectPills = ({text, onClose}) => {
    return (
        <Wrapper>
            <div className="content">{text}</div>
            <CloseBlackIcon onClick={onClose} className="close-black"/>
        </Wrapper>
    )
}

export default React.memo(MultiselectPills)
