import React, {useState, useEffect} from 'react'

import {Wrapper, PictureContainer, UploadFileContainer, Content, ProfilePicture, RemovePhoto} from './UploadPhoto.style'
import {ReactComponent as NoPicture} from '../../../../assets/icons/no_profile_picture.svg'
import {ReactComponent as Close} from '../../../../assets/icons/close.svg'
import { useSelector } from 'react-redux'

const profileImageBaseUrl = process.env.REACT_APP_API_BASE_URL

const UploadPhoto = ({setPicture}) => {
    const [pictureUrl, setPictureUrl] = useState()
    const [profileImage, setProfileImage] = useState()
    const [displayAvatar, setDisplayAvatar] = useState(false)
    const {user} = useSelector(state => state.auth)

    useEffect(() => {
        if(user.image){
            setPictureUrl(`${profileImageBaseUrl}/images/${user.image}`)
        }
    }, [user.image])

    useEffect(() => {
        console.log("rpije")
        if(profileImage) {
            const url = URL.createObjectURL(profileImage)
            setPictureUrl(url)
        }   
        console.log(profileImage)
    }, [profileImage])



    const handleRemoveImage = () => {
        setProfileImage(null)
        setPictureUrl(null)
        setPicture(null)
    }

    const handlePictureChange = (e) => {
        setDisplayAvatar(false)
        setProfileImage(e.target.files[0])
        setPicture(e.target.files[0])
        e.target.value = ""
    }

    const handleProfilePictireError = () => {
        setDisplayAvatar(true)
    }

    return (
        <Wrapper>
            <p className="title">UPLOAD PROFILE PHOTO</p>
            <Content>
                <PictureContainer>
                    {pictureUrl && (
                        <RemovePhoto onClick={handleRemoveImage}>
                            <Close className="close"/>
                        </RemovePhoto>
                    )}
                    
                    {pictureUrl && !displayAvatar ? <ProfilePicture src={pictureUrl} alt="profile-picture" onError={handleProfilePictireError}/> : <NoPicture />}
                    
                </PictureContainer>
                <UploadFileContainer>
                    <div className="upload">
                        <input onChange={handlePictureChange} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" id="upload-picture" hidden/>
                        <label className="label" htmlFor="upload-picture">Choose File</label>
                        <span className="file-name">{profileImage ? profileImage.name : 'No File chosen'}</span>
                    </div>
                    <p className="max-size">The maximum file size allowed is 8MB</p>
                </UploadFileContainer>   
            </Content>
        </Wrapper>
    )
}

export default UploadPhoto


