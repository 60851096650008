import api from "../libs/api";

class AuthService {

    async login(email, password, rememberMe){
        return await api.post('/login', {
            email,
            remember_me: rememberMe,
            password
        })
    }

    logout(){
        localStorage.removeItem("token")
        localStorage.removeItem("user")
    }

    async forgotPassword(email) {
        return await api.post('/forgot-password', {
            email
        });
    }

    async setNewPassword(password, password_confirmation, token) {
        
        return await api.post(`/reset-password/${token}`, {
            password,
            password_confirmation
        });
    }
}

export default new AuthService()