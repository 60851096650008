import React from "react";
import PropTypes from 'prop-types'

import {Wrapper} from "./PageHeader.style";

const PageHeader = ({title}) => {
    
    return(
        <Wrapper>
            <div className="title">{title}</div>
        </Wrapper>
    )
}

export default  PageHeader

PageHeader.propTypes = {
    title: PropTypes.string.isRequired
}