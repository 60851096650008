import { createAsyncThunk } from "@reduxjs/toolkit";
import StatsService from "./Stats.service";

export const getAllStats = createAsyncThunk(
    "stats/get",
    async (params) => {
        try {
            const { date, user_name, page, per_page, sortBy, sortType } = params;
            const response = await StatsService.getStats(date, user_name, page, per_page, sortBy, sortType);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
)