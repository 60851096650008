import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import dayjs from 'dayjs'

import {Wrapper} from './CalendarHeader.style'

import StatusFilter from './StatusFilter/StatusFilter'
import Legend from './Legend/Legend'

import {ReactComponent as LeftArrow} from '../../../assets/icons/angle-left.svg'
import {ReactComponent as RightArrow} from '../../../assets/icons/angle-right.svg'
import {ReactComponent as LegendIcon} from '../../../assets/icons/Legend.svg'

import {setFilters, setMonth} from '../OfficeCalendar.slice'


const CalendarHeader = () => {
    const {selectedMonthIndex, selectedFilters} = useSelector(state => state.officeCalendar)
    const [selectedMonth, setSelectedMonth] = useState(selectedMonthIndex)
    const [legendOpened, setLegendOpened] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(setMonth(dayjs().month()))
            dispatch(setFilters([]))
        }
    }, [])
    

    useEffect(() => {
        dispatch(setMonth(selectedMonth))
    }, [selectedMonth, dispatch])

    const handleNextMonth = () => {
        setSelectedMonth(selectedMonth + 1)
    }

    const handlePrevMonth = () => {
        setSelectedMonth(selectedMonth - 1)
    }

    const handleSetCurrentMonth = () => {
        setSelectedMonth(dayjs().month())
    }
    
    return (
        <Wrapper>
            <div className="month-picker">
                <div className="month-year">{dayjs(new Date(dayjs().year(), selectedMonth)).format('MMMM YYYY' )}</div>
                <div>
                    <LeftArrow className="arrow" onClick={handlePrevMonth}/>
                    <RightArrow className="arrow right" onClick={handleNextMonth}/>
                </div>
                <div className="today-button" onClick={handleSetCurrentMonth}>Today</div>
            </div>

            <div className="filter">
                <StatusFilter />
                <div className="legend" onClick={() => setLegendOpened(true)}><LegendIcon className="legend-icon"/>Legend</div>
            </div>
            <Legend opened={legendOpened} hide={() => setLegendOpened(false)}/>
        </Wrapper>
    )
}

export default CalendarHeader
