import React, {useEffect, useState, useCallback} from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { Wrapper } from './EditRequest.style'

import { editTimeOffRequest } from '../Request.thunk'
import RequestService from '../Request.service'

import Button from '../../../../shared/components/Button/Button'
import RadioButton from '../../../../shared/components/RadioButton/RadioButton'
import Dropdown from '../../../../shared/components/Dropdown/Dropdown'
import Modal from '../../../../HOC/Modal/Modal'

import formatDate from '../../../../shared/utils/formatDate'
import { useKeypress } from '../../../../hooks'

import {getAllTimeOffRequests} from '../Request.thunk'
import Calendar from "../../../../shared/components/Calendar/Calendar";

const timeOffReasons = ['Vacation', 'Sick Leave', 'Personal', 'Work From Home', 'Company']

const EditRequest = ({location}) => {
    const [loading, setLoading] = useState(false)
    const [request, setRequest] = useState()
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())
    const [reason, setReason] = useState(null)
    const [status, setStatus] = useState()
    const [reasonDropdownOpened, setReasonDropdownOpened] = useState(false)
    let { id, user_id } = useParams()


    const dispatch = useDispatch()

    const history = useHistory()

    const fetchRequest =useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await RequestService.getById(user_id, id)
            const request = data.data.time_off
            setRequest(request)
            setFromDate(new Date(request?.date_from * 1000))
            setToDate(new Date(request?.date_to * 1000))
            setReason(request?.reason)
            setStatus(request?.status)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
        
    }, [id, user_id])

    useEffect(() => {
        fetchRequest()
    }, [fetchRequest])

    const handleEditRequest = async () => {
        const params = {
            id: id,
            userId: user_id,
            fromDate: Date.parse(fromDate.toString()) / 1000,
            toDate: Date.parse(toDate.toString()) / 1000,
            note: request.note,
            reason: reason.toLowerCase(),
            status
        }
        setLoading(true)
        await dispatch(editTimeOffRequest(params))
        setLoading(false)
        if(location && location.state && location.state.fetchParams){
            dispatch(getAllTimeOffRequests(location.state.fetchParams))
        } else {
            dispatch(getAllTimeOffRequests({
                dateFrom: null,
                dateTo: null,
                page: 1,
                perPage: 20,
                sortBy: "date_requested",
                sortType: "desc",
                status: null,
                userName: ""
            }))
        }
        handleCloseModal()
    }

    const handleReasonSelect = (reason) => {
        setReason(reason)
        
    }

    const handleCloseModal = () => {
        history.push('/time-off/requests')
    }

    useKeypress('Enter', handleEditRequest)
    useKeypress('Escape', handleCloseModal)

    return (
        <Modal isShowing={true} loading={loading}>
            <Wrapper>
            <div className="close" onClick={handleCloseModal}></div>
            <h3>Edit Time Off Request</h3>

            <div className="data">
                <p className="label">{request?.user ? request?.user.first_name + " " + request?.user.last_name : "Group Vacation"}</p>
                <p className="value">{formatDate(request?.date_from)} - {formatDate(request?.date_to)}</p>
            </div>

                <div className="from-to">
                    <div className="from">
                        <label>FROM</label>
                        <Calendar maxDate={toDate} startDate={fromDate} setStartDate={setFromDate}/>
                    </div>
                    <div className="to">
                        <label>TO</label>
                        <Calendar minDate={fromDate} startDate={toDate} setStartDate={setToDate}/>
                    </div>
                </div>
            <div className="data">
                <p className="value"><span className="label">Note</span>: {request?.note}</p>
            </div>
            <div className="data">
                <p className="value"><span className="label">Requested</span>: {formatDate(request?.date_requested)}</p>
            </div>
            <div className="reason-select-container">
                <label className="label">REASON</label>
                <Dropdown placeholder={"Select reason"} value={reason && reason.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} opened={reasonDropdownOpened} setOpened={setReasonDropdownOpened}>
                        {timeOffReasons.map(reason => <div key={reason} className="dropdown-option" onClick={() => handleReasonSelect(reason)}>{reason}</div>)}
                </Dropdown>
            </div>
            
            <label className="label">STATUS</label>
            <div className="radio-button-group">
                <RadioButton text="Pending" onChange={setStatus} value="pending" selected={status}/>
                <RadioButton text="Approve" onChange={setStatus} value="approved" selected={status}/>
                <RadioButton text="Deny" onChange={setStatus} value="declined" selected={status}/>
            </div>
            <div className="button-group">
                <div className="cancel-button" onClick={handleCloseModal}>Cancel</div>
                <Button value="Submit" onClick={handleEditRequest} width={124}></Button>
            </div>

            </Wrapper>
        </Modal>
    )
}

export default EditRequest
