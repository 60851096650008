import React from "react";

import Modal from "../../../../HOC/Modal/Modal";
import {ReactComponent as PendingIcon} from '../../../../assets/icons/Pending_icon.svg'

import {Wrapper} from "./Legend.style";

const Legend = ({opened, hide}) => {

    return (
        <Modal isShowing={opened} hide={hide}>
            <Wrapper>
                <div className="title">

                    Legend
                </div>
                <div className='element'>
                    <div className='vacation' >
                        <span className="vacation-mark" />
                    </div>
                    Vacation
                </div>
                <div className='element'>
                    <div className='sick-leave' >
                        <span className="sick-leave-mark" />
                    </div>
                    Sick Leave
                </div>
                <div className='element'>
                    <div className='wfh' >
                        <span className="wfh-mark" />
                    </div>
                    Work From Home
                </div>
                <div className='element'>
                    <div className='personal' >
                        <span className="personal-mark" />
                    </div>
                    Personal
                </div>
                <div className='element'>
                    <div className='pending' >
                        <span className="pending-mark" />

                            <PendingIcon className="pending-icon"/>


                    </div>
                    Pending Status

                </div>
            </Wrapper>
        </Modal>
    )

}

export default Legend