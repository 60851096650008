import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import authService from "../services/auth.service";
import { showToast } from "../shared/utils/showToast";

const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {is_admin: false}
const token = localStorage.getItem("token")

const initialState = token
                    ? {isLoggedIn: true, user, loading: false, errors: null, token}
                    : {isLoggedIn: false, user, loading: false, errors: null, token: null}

export const login = createAsyncThunk(
    "auth/login",
    async (credentials, {rejectWithValue}) => {
        const {email, password, rememberMe} = credentials
        try {
            const response = await authService.login(email, password, rememberMe)
            localStorage.setItem("token", response.data.data.token)
            localStorage.setItem("user", JSON.stringify({...response.data.data.user, is_admin: response.data.data.user.is_admin}))
            showToast(response.data.message, "success")
            return response
        } catch (error){
            showToast(error.response.data.message, "error")
            return rejectWithValue(error.response.data)
        }
    }
)

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout(state) {
            state.isLoggedIn = false
            state.user = {}
            state.token = null
        },
        updateUser(state, action){
            state.user = action.payload
            
            state.user.is_admin = action.payload.is_admin
        }

    },
    extraReducers: {
        [login.pending]: (state) => {
            state.loading = true
            state.errors = null
        }
        ,
        [login.fulfilled]: (state, {payload}) => {
            state.token = payload.data.data.token
            state.user = payload.data.data.user
            state.user.is_admin = payload.data.data.user.is_admin
            state.loading = false
            state.errors = null
            state.isLoggedIn = true
        },
        [login.rejected]: (state, {payload}) => {
            state.isLoggedIn = false
            state.token = null
            state.loading = false
            state.errors = payload.data
        }
    }
})

export const {logout, updateUser} = authSlice.actions
export default authSlice.reducer