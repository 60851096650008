import api from '../../../libs/api';

class Statuservice {
    async getMonthlyStats(user_id, timestamp) {
        let response = await api.get(`${user_id}/monthly/logs?date=${timestamp}`);
        return response;
    }
    async getDailyStats(user_id, timestamp) {
        let response = await api.get(`${user_id}/daily/logs?date=${timestamp}`);
        return response;
    }
}
export default new Statuservice();