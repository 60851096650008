import React, {useEffect, useState} from "react";

import EventDetails from '../EventDetails/EventDetails'

import {ReactComponent as PendingIcon} from '../../../assets/icons/Pending_icon.svg'

import {Wrapper, PendingDropdown} from "./Event.style";



const Event = ({maxWidth, topOffset, leftOffset, eventWidth, event, isFirstDay, setHoveredEvent, isHovered}) => {
    const [background, setBackground] = useState({
        background: 'white',
        eventStart: 'white'
    })
    const[pendingDropdownOpened, setPendingDropdownOpened] = useState(false)
    const [eventDetailsModalOpened, setEventDetailsModalOpened] = useState(false)

    const setBackgroundColor = () => {
        if(event.status === 'pending') {
            setBackground( {background :'#E8E8E8', eventStart: '#A6A6A6'})
            return
        }
        if(event.reason === 'vacation') {
            setBackground( {background :'#E5F9F9', eventStart: '#53898F'})
            return
        }
        if(event.reason === 'sick leave') {
            setBackground( {background :'#FFEFCA', eventStart: '#FBB106'})
            return
        }
        if(event.reason === 'work from home') {
            setBackground( {background :'#FFDBF7', eventStart: '#DD59C1'})
            return
        }
        if(event.reason === 'personal') {
            setBackground( {background :'#E9DCFF', eventStart: '#8352DC'})
            return
        }
        if(event.reason === 'vacation') {
            setBackground( {background :'#E5F9F9', eventStart: '#53898F'})
            return
        }
    }
    useEffect(() => {
        setBackgroundColor()
    }, [])

    const handleEventClick = () => {
        setEventDetailsModalOpened(true)
    }

    const handleMouseEnter = () => {
        setHoveredEvent(event.id)
    }

    const handleMouseLeave = () => {
        setHoveredEvent(null)
    }
    return (
        <Wrapper
            maxWidth={maxWidth}
            topOffset={topOffset}
            leftOffset={leftOffset}
            eventWidth={eventWidth}
            background={background.background}
            isFirstDay={isFirstDay}
            eventStartColor={background.eventStart}
            onClick={handleEventClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            hovered={isHovered}
        >
            <div className='event-start' />
            <div className="name">
                {event.user ? `${event.user.first_name} ${event.user.last_name}` : ''}
            </div>
            {event.status === "pending" && <span className="pending-container" onMouseEnter={() => setPendingDropdownOpened(true)} onMouseLeave={() => setPendingDropdownOpened(false)}><PendingIcon style={{
                marginLeft: 'auto',
                height: '12px',
                marginRight: '3px'
            }}/>
            <PendingDropdown opened={pendingDropdownOpened}>Pending Status</PendingDropdown >
            </span>}
            <EventDetails  opened={eventDetailsModalOpened} hide={() => setEventDetailsModalOpened(false)} event={event}/>
        </Wrapper>
    )
}

export default Event