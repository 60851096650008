import React, {memo} from 'react'
import PropTypes from "prop-types";

import {Wrapper} from "./Button.style";

const Button = ({value, onClick, width, disabled = false}) => {
    return <Wrapper onClick={onClick} width={width} disabled={disabled}>
        {value}
    </Wrapper>
}

Button.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
    width: PropTypes.number,
    disabled: PropTypes.bool
}

export default memo(Button)