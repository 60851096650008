import React, { useEffect, useMemo, useState } from "react";

import { userStatsDaily, userStatsMonthly, userLoadingDaily, userLoadingMonthly } from "./UserStats.slice";
import { getUserDailyStats, getUserMonthlyStats } from "./UserStats.thunk";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../HOC/Modal/Modal";
import Table from "../Table/Table";
import Calendar from "../Calendar/Calendar";
import {Container, Header, StatsWrapper, Wrapper} from "./UserStats.style";
import LogOptions from "./LogOptions/LogOptions";
import Button from "../Button/Button";
import AddLog from "./AddLog/AddLog";
import formatDate from "../../utils/formatDate";
import {ReactComponent as File} from "./img/file.svg"
import {ReactComponent as BackIcon} from "./img/angle-left.svg";
import LoadingTable from "../Table/LoadingTable/LoadingTable";
import UserStatsService from "./UserStats.service";

const UserStats = ({setShowStats, page, month = false, user}) => {
    const monthlyLogs = useSelector(userStatsMonthly);
    const loadingMonthly = useSelector(userLoadingMonthly);
    const dailyLogs = useSelector(userStatsDaily);
    const loadingDaily = useSelector(userLoadingDaily);
    const [selectedMonth, setSelectedMonth] = useState(month ? month : new Date());
    const [selectedDate, setSelectedDate] = useState(month ? month : new Date());
    const [addModal, setAddModal] = useState(false);

    const dispatch = useDispatch();
    const toTimestamp = date => Math.round(Date.parse(date)/1000);

    const getTotalHoursMins = (time) => {
        const getSum = (prev, curr) => prev + curr;
        const reducedTime = time.reduce(getSum, 0);
        const hours = Math.floor(reducedTime / 3600);
        const mins = Math.floor(reducedTime % 3600 / 60);
        return `${hours}h ${mins}min`;
    }

    const downloadFile = (fileData, filename) => {
        const blob = new Blob([fileData], { type: 'text/scv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }

    const exportCsv = async () => {
        let user_id = user.user_id;
        let date = toTimestamp(selectedMonth);
        try {
            let response = await UserStatsService.exportCsv(user_id, date);
            let filedata = response.data;
            let filename = response.headers["content-disposition"].slice(21, 70);
            downloadFile(filedata, filename);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        let timestamp = toTimestamp(selectedMonth);
        const params = {timestamp, user_id: user.user_id};
        dispatch(getUserMonthlyStats(params));
    }, [selectedMonth]);

    useEffect(()=>{
        let timestamp = toTimestamp(selectedDate);
        const params = {timestamp, user_id: user.user_id};
        dispatch(getUserDailyStats(params));
    }, [selectedDate]);

    const timestampToDateObject = (date) => {
        const newDate = new Date(date * 1000);
        return newDate;
    }
    
    const monthlyTableData = monthlyLogs?.map(log => {
        let over_left_time = timestampToDateObject((log.date + Math.abs(log.over_left_time))).toTimeString().slice(0, 5);
        return {
            date: formatDate(log.date),
            worked : timestampToDateObject(log.date + log.work_time).toTimeString().slice(0, 5), 
            over_left_time: (log.over_left_time > 60 && <span className="green">{over_left_time}</span>) ||
                            (log.over_left_time < -60 && <span className="red">{over_left_time}</span>) ||
                            over_left_time,
        }
    });
    
    const monthlyTableColumns = useMemo(()=>[
        {
            Header: "DATE",
            accessor: "date",
            Footer : "TOTAL"
        },
        {
            Header: "WORKED",
            accessor: "worked",
            Footer: ()=> {
                const time =  monthlyLogs.map(log => {
                    return log.work_time;
                });
                const timeTotal = getTotalHoursMins(time);
                return <>{timeTotal}</>
              },
        },
        {
            Header: "OVER/LEFT TIME",
            accessor: "over_left_time",
            Footer: ()=> {
                const time =  monthlyLogs.map(log => {
                    return log.over_left_time;
                });
                const getSum = (prev, curr) => prev + curr;
                const reducedTime = time.reduce(getSum, 0);
                const hours = Math.floor(Math.abs(reducedTime) / 3600);
                const mins = reducedTime < 0 ? Math.floor(Math.abs(reducedTime) % 3600 / 60) : Math.ceil(Math.abs(reducedTime) % 3600 / 60);
                const timeTotal = `${hours}h ${mins}min`;
                let string = (reducedTime > 0 && <span className="green">{timeTotal}</span>) || (reducedTime < -60 && <span className="red">{timeTotal}</span>) || <>{timeTotal}</>;
                return string;
              },
        },
    ], [monthlyLogs]);

    const dailyTotal = dailyLogs.work_time > 0 ? `${timestampToDateObject(dailyLogs.date + dailyLogs.work_time).toTimeString().slice(0, 5)}` : "00:00"

    const dailyTableColumns = useMemo(()=>[
        {
            Header: "DATE",
            accessor: "date",
            Footer: "TOTAL WORKED"
        },
        {
            Header: "TIME",
            accessor: "time",
            Footer : dailyTotal
        },
        {
            Header: "STATUS",
            accessor: "status"
        },
        {
            Header: "",
            accessor: "icons"
        }
    ], [dailyLogs, dailyTotal]);

    const dailyTableData = dailyLogs.daily_logs?.map(log => {
        return {
            date: formatDate(dailyLogs.date),
            time: timestampToDateObject(log.time).toTimeString().slice(0, 5), 
            status: log.log_type === "Login" ? <span className="green" >{log.log_type}</span> : <span className="red" >{log.log_type}</span>, 
            icons: <LogOptions user={user} log={log} toTimestamp={toTimestamp} />
        };
    });
    
    const setToday = () => {
        setSelectedDate(new Date());
    }

    const handleMonthlyRowClick = (row) => {
        const dateArr = row.original.date.split('.');
        const date = new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
        setSelectedDate(date);
    }

    return (
        <StatsWrapper>
            <Header>
                <div className="title"> 
                <div className="back-button" onClick={()=>{
                        setShowStats(false);
                    }} >
                   <BackIcon/> {page}
                </div>
                / Stats - {user.user_full_name}</div>
            </Header>
            <Container>
                <Wrapper>
                    <div className="monthly">
                        <h3>Monthly</h3>
                        <div className="calendar-exportbtn">
                            <Calendar startDate={selectedMonth} setStartDate={setSelectedMonth} maxDate={new Date()} monthly />
                            <div className="export-btn" onClick={() => exportCsv()} ><File className="file-icon" /> Export CSV </div>
                        </div>
                        <div className="table">
                            { loadingMonthly ? 
                                <LoadingTable />
                                : 
                                monthlyTableData && <Table columns={monthlyTableColumns} data={monthlyTableData} hasFooter onRowClicked={handleMonthlyRowClick} /> 
                            }
                        </div>
                    </div>
                    <div className="daily">
                        <h3>Daily</h3>
                        <div className="navigation">
                            <div className="calendar-todaybtn">
                                <Calendar startDate={selectedDate} setStartDate={setSelectedDate}  maxDate={new Date()} />
                                <div className="today-wrapper">
                                    <button className="today" onClick={() => setToday()}>Today</button>
                                </div>
                            </div>
                            <div className="add-log-button">
                                <Button value="Add Log" width={95} onClick={()=>setAddModal(true)} />
                                <Modal hide={()=>setAddModal(false)} isShowing={addModal}>
                                    <AddLog user={user} selectedDate={selectedDate} toTimestamp={toTimestamp} setAddModal={setAddModal} />
                                </Modal>
                            </div>
                        </div>
                        <div className="table">
                            { loadingDaily ? 
                                <LoadingTable />
                                : 
                                dailyTableData && <Table columns={dailyTableColumns} data={dailyTableData} hasFooter={true} /> 
                            }
                        </div>
                    </div>
                </Wrapper>
            </Container>
        </StatsWrapper>
    )
}

export default UserStats;