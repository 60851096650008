import React, { useRef, memo, useState, useCallback, } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Wrapper, DropdownContainer, Header} from './StatusFilter.style'

import { useOnClickOutside } from '../../../../hooks'

import {ReactComponent as ArrowDown} from '../../../../assets/icons/angle-down.svg'
import {ReactComponent as FilterIcon} from '../../../../assets/icons/Filter.svg'

import Checkbox from '../../../../shared/components/Checkbox/Checkbox'

import { setFilters } from '../../OfficeCalendar.slice'

const filterValues = ['Vacation', 'Sick Leave', 'Work From Home', 'Personal', 'Pending Status']

const StatusFilter = () => {
    const [opened, setOpened] = useState(false)
    const ref = useRef()

    const dispatch = useDispatch()
    const {selectedFilters} = useSelector(state => state.officeCalendar)

    

    useOnClickOutside(ref, () => setOpened(false))

    const handleOpenDropdown = useCallback(() => {
        setOpened(!opened)
    }, [opened])

    const handleFilterSelect = (filterValue) => {
        if(selectedFilters.some(selectedFilter => selectedFilter === filterValue.toLowerCase())){
            //remove user from selected user array
            const newFilters = selectedFilters.filter(selectedFilter => selectedFilter !== filterValue.toLowerCase())
            dispatch(setFilters(newFilters))          
        } else {
            dispatch(setFilters([...selectedFilters, filterValue]))
        }

    }

    const handleSelectAll = () => {
        dispatch(setFilters(filterValues))
    }

    const handleClearSelected = () => {
        dispatch(setFilters([]))
    }

    return (
        <Wrapper ref={ref}>
            <Header onClick={handleOpenDropdown} focused={opened}>
                <div className="filter-placeholder">
                    <div className="filter-icon">
                        <FilterIcon/>
                    </div>
                    <div>Filter</div>
                </div>
                <ArrowDown className="arrow-down"/>
            </Header>

            <DropdownContainer opened={opened}>
                {filterValues.map(value => (
                    <div>
                    <Checkbox onClick={() => handleFilterSelect(value)} checked={selectedFilters.some(selectedFilter => selectedFilter === value.toLowerCase())} size={14} label={value}/>
                    </div>
                ))}
                <div className="selection">
                    <div onClick={handleSelectAll}>Select All</div>
                    <div onClick={handleClearSelected}>Clear Selected</div>
                </div>
            </DropdownContainer>
        </Wrapper>
    )
}

export default memo(StatusFilter)

