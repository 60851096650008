import React, {useState} from "react";

import EventShowMore from "./EventShowMore/EventShowMore";

import {Wrapper, Event} from "./ShowMore.style";

import {ReactComponent as CloseIcon} from '../../../../assets/icons/close.svg'


const ShowMore = ({opened, close, events, date, setHovered}) => {


    const setBackgroundColor = (event) => {
        if(event.status === 'pending') {
            return {background :'#E8E8E8', eventStart: '#A6A6A6'}

        }
        if(event.reason === 'vacation') {
            return {background :'#E5F9F9', eventStart: '#53898F'}

        }
        if(event.reason === 'sick leave') {
            return{background :'#FFEFCA', eventStart: '#FBB106'}

        }
        if(event.reason === 'work from home') {
            return  {background :'#FFDBF7', eventStart: '#DD59C1'}

        }
        if(event.reason === 'personal') {
            return  {background :'#E9DCFF', eventStart: '#8352DC'}

        }
        if(event.reason === 'vacation') {
           return {background :'#E5F9F9', eventStart: '#53898F'}

        }
    }



    return (
        <Wrapper opened={opened}>
            <CloseIcon className='close' onClick={close}/>
            <div className="date">{date.format('MMMM DD, YYYY')}</div>
            {events.map(event =>
                <EventShowMore event={event} setHovered={setHovered}>


                </EventShowMore>
            )}

        </Wrapper>
    )
}

export default ShowMore