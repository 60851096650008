import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import PrivateRoute from "./HOC/PrivateRoute";

import Login from "./views/Login/Login";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import SetNewPassword from "./views/SetNewPassword/SetNewPassword";
import UserPage from './views/Users/Users';
import Header from './shared/components/Header/Header'
import YourProfile from "./views/YourProfile/YourProfile";
import TimeOff from "./views/TimeOff/TimeOff";
import Stats from './views/Stats/Stats';
import WhoIsInTheOffice from './views/WhoIsInTheOffice/WhoIsInTheOffice'
import EditRequest from './views/TimeOff/Requests/EditRequest/EditRequest';
import OfficeCalendar from './views/OfficeCalendar/OfficeCalendar';

const App = () => {
    return (
        <Router>
          <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/set-new-password/:token" component={SetNewPassword} />
              <>
                  <Header />
                  <PrivateRoute exact path="/users" component={UserPage} isAdminRoute={true}/>
                  <PrivateRoute path="/your-profile"  component={YourProfile} isAdminRoute={false}/>
                  <PrivateRoute exact path="/stats" component={Stats} isAdminRoute={true}/>
                  <PrivateRoute path="/time-off" component={TimeOff} isAdminRoute={true}/>
                  <PrivateRoute exact path="/who's-in-office" component={WhoIsInTheOffice} isAdminRoute={false}/>
                  <PrivateRoute exact path="/office-calendar" component={OfficeCalendar} isAdminRoute={false}/>
              </>
          </Switch>
            {/* MODALS */}
            <PrivateRoute path="/time-off/requests/edit/:user_id/:id" component={EditRequest} isAdminRoute={true}/>
            
            <Route exact path="/" render={() => <Redirect to="/login" />} />
        </Router>


    )
}

export default App;
