import styled from "styled-components";



export const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  background: ${props => props.checked ? '#FFEDC2' : '#FFFFFF'};
  border-radius: 4px;
  border: 1px solid ${props => props.checked ? '#FBB106' : '#C5CCD5'};

  .check-icon{
    visibility: ${props => props.checked ? 'visible' : 'hidden'};
    height: 11px;
    width: 11px;
    stroke: #FBB106;
  }
`

export const Label = styled.div`
  font-family: 'Roboto Regular', sans-serif;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.19px;
  color: #A3A3A3;
  margin-left: 5px;
`
export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;

  :hover{
    ${StyledCheckbox} {
      background: #FFEDC2;
      border: 1px solid #FBB106;
    };
    cursor: pointer;
  }
  
`
