import React from 'react'

import CalendarHeader from './CalendarHeader/CalendarHeader'
import PageHeader from '../../shared/components/PageHeader/PageHeader'
import Calendar from './Calendar/Calendar'

import {Wrapper} from './OfficeCalendar.style'

const OfficeCalendar = () => {
    return (
        <>
            <PageHeader title="Calendar" />
            <Wrapper>
                <CalendarHeader />
                <Calendar />
            </Wrapper>
        </>
    )
}

export default OfficeCalendar
