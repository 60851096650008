import React from "react";

import PropTypes from 'prop-types'

import {Content} from "./Logo.style";
import LogoImg from '../../../assets/img/logo.png'

const Logo = ({size}) => {
     return <Content src={LogoImg} size={size}/>
}

Logo.propTypes = {
     size: PropTypes.number
}

export default Logo