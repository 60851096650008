import React from "react";
import ReactDOM from 'react-dom';

import { Overlay, Content, LoadingOverlay } from "./Modal.style";

import Spinner from '../../shared/components/Spinner/Spinner'

import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg'


const Modal = ({children, isShowing, hide, loading}) =>  isShowing ? ReactDOM.createPortal(
    <Overlay >
        <Content onClick={(e)=>{e.stopPropagation()}}>
            {loading ? <LoadingOverlay ><Spinner /></LoadingOverlay> : null}
            <CloseIcon className="close" onClick={hide}></CloseIcon>
            {children}
        </Content>
    </Overlay>
, document.body
) : null;

export default Modal;