import styled from 'styled-components'
import {ReactComponent as Close} from '../../../../assets/icons/close.svg'

export const Wrapper = styled.div`
    margin-top: 18px;
    .title{
        font-family: 'Roboto Regular', sans-serif;
        font-size: 12px;
        color: #506280;
    }
`

export const CloseStyled = styled(Close)`
     
` 

export const Content = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`

export const PictureContainer = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #F4F4F4;
    border: 1px solid #EAECEE;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`

export const RemovePhoto = styled.div`
    border: 2px solid white;
    border-radius: 50%;
    background-color: #E43F3F;
    width: 23px;
    height: 23px;
    position: absolute;
    top: 4px;
    right: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        cursor: pointer;
    }
    .close{
        width: 10px;
        height: 10px;
        path{
            fill: #FFFFFF;
        }
      
        :hover{
            cursor: pointer;
        }
    }
`

export const UploadFileContainer = styled.div`
    .label{
        background-color: #F1F7FF;
        border: 1px solid #E4F0FF;
        color: #6D8DB7;
        font-family: 'Roboto Regular', sans-serif;
        font-size: 12px;
        border-radius: 8px;
        width: 98px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        margin-right: 15px;
        cursor: pointer;
    }

    .upload{
        display: flex;
        align-items: center;

        .file-name{
            font-family: 'Roboto Regular', sans-serif;
            font-size: 12px;
            color: #A3A3A3;
        }
    }

    .max-size{
        font-family: 'Roboto Light', sans-serif;
        font-size: 12px;
        color: #A3A3A3;
        margin-top: 8px;
    }
`

export const ProfilePicture = styled.img`
     width:100%;
    height:100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
`