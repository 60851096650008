import React, {useEffect, useState, useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux"

import {Wrapper} from "./TimeOff.style"

import Button from "../../../shared/components/Button/Button";
import Table from "../../../shared/components/Table/Table";
import LoadingTable from "../../../shared/components/Table/LoadingTable/LoadingTable";
import Dropdown from "../../../shared/components/Dropdown/Dropdown";
import Modal from "../../../HOC/Modal/Modal";
import Calendar from "../../../shared/components/Calendar/Calendar";
import CreateTimeOffRequest from './CreateTimeOffRequest/CreateTimeOffRequest'
import RequestsOptions from "../../TimeOff/Requests/RequestsOptions/RequestsOptions";

import useModal from "../../../hooks/useModal";
import { useTableSort } from "../../../hooks";

import {getUsersTimeOffs} from './TimeOff.thunk'

import formatDate from '../../../shared/utils/formatDate'
import TimeOffOptions from "./TimeOffOptions/TimeOffOptions";
import NoDataTable from "../../../shared/components/Table/NoDataTable/NoDataTable";

const timeOffStatus = ['Pending', 'Approved', 'Declined']


const TimeOff  = () => {
    const [status, setStatus] = useState(null)
    const [statusDropdownOpened, setStatusDropdownOpened] = useState(false)
    const [dateFrom, setDateFrom] = useState(null)
    const [dateTo, setDateTo] = useState(null)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const {sortBy, sortType, toggleSortDirection} = useTableSort('date_requested','desc')
    const [mappedTableData, setMappedTableData] = useState()
    const {timeOffRequests, loading} = useSelector(state => state.timeOffs) 
    const {user} = useSelector(state => state.auth)
    const {showing, toggle, loadingModal, setLoadingModal} = useModal()

    const dispatch = useDispatch()

    const fetchTimeOffRequests = useCallback(() => {
        const params = {
            userId: user.id,
            status: status && status.toLowerCase(), 
            perPage, 
            dateFrom: dateFrom && Date.parse(dateFrom) / 1000, 
            dateTo: dateFrom && Date.parse(dateTo) / 1000, 
            page,
            sortBy,
            sortType
        }
        dispatch(getUsersTimeOffs(params))
    }, [status, perPage, dateFrom, dateTo, page, dispatch, user, sortBy, sortType])

    useEffect(() => {
        fetchTimeOffRequests()
    }, [status, perPage, dateFrom, dateTo, page, sortBy, sortType, fetchTimeOffRequests])

    useEffect(() => {
        const mappedData = timeOffRequests.data && timeOffRequests.data.map(request => {
            return {
                date_requested: formatDate(request.date_requested),
                from_date: formatDate(request.date_from),
                to_date: formatDate(request.date_to),
                status: request.status.charAt(0).toUpperCase() + request.status.slice(1),
                options: <TimeOffOptions onDelete={fetchTimeOffRequests} onEdit={fetchTimeOffRequests} timeOffRequest={request}/>
            }
        })
        setMappedTableData(mappedData)
    }, [timeOffRequests.data, fetchTimeOffRequests])

    
    const handleStatusFilterChange = (status) => {
        setStatus(status)
        setStatusDropdownOpened(false)
    }

    const resetFilters = () => {
        setDateFrom(null)
        setDateTo(null)
        setStatus(null)
    }

    const handleSortTableResults = useCallback((sort) => {
        toggleSortDirection(sort)
    }, [toggleSortDirection])
 
    const tableColumns = useMemo(() => [
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("date_requested")}>REQUEST DATE</span>,
            accessor: 'date_requested',
            
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("date_from")}>FROM DATE</span>,
            accessor: 'from_date'
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("date_to")}>TO DATE</span>,
            accessor: 'to_date'
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("status")}>STATUS</span>,
            accessor: 'status',
            Cell: ({row}) => {
                if(row.original.status === 'Declined') {
                    return <div style={{color: '#E43F3F'}}>{row.original.status}</div>
                }
                if(row.original.status === 'Approved') {
                    return <div style={{color: '#4CC186'}}>{row.original.status}</div>
                }
                if(row.original.status === 'Pending') {
                    return <div style={{color: '#FBB106'}}>{row.original.status}</div>
                }
                return row.original.status
            }
        },
        {
            Header: () => null,
            accessor: 'options'
        }
    ], [handleSortTableResults])

    return (
        <Wrapper>
            <div className="create-request">
                <div className="filters">

                    <div>
                        <label className="label">FROM</label>
                        <Calendar  startDate={dateFrom} setStartDate={setDateFrom}/>
                    </div>
                    <div>
                        <label className="label">TO</label>
                        <Calendar  startDate={dateTo} setStartDate={setDateTo}/>
                    </div>
                    <div className="filter-status">
                        <label className="label">STATUS</label>
                        <Dropdown placeholder={"Select Status"} value={status && status}>
                            {timeOffStatus.map(status => <div key={status} className="dropdown-option" onClick={() => handleStatusFilterChange(status)}>{status}</div>)}
                        </Dropdown>
                    </div>
                    <div className="reset-filters" onClick={resetFilters}>Reset</div>

                </div>
                <div className={'create-button'}>
                    <Button value="Create Time Off Request" width={200} onClick={toggle}/>
                </div>
            </div>
            {mappedTableData && !loading ? (
                <Table 
                    columns={tableColumns} 
                    data={mappedTableData} 
                    pagination={{page, setPage, perPage, setPerPage, totalResults: timeOffRequests.total, from: timeOffRequests.from, to: timeOffRequests.to}}
                    loading={loading}
                />  
            ) : <LoadingTable></LoadingTable>}
            <Modal isShowing={showing} hide={toggle} loading={loadingModal}>
                <CreateTimeOffRequest onCreate={fetchTimeOffRequests} hideModal={toggle} selectUser={false} setLoadingModal={setLoadingModal}/>
            </Modal >
        </Wrapper>
    )
}

export default TimeOff