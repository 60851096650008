import styled from "styled-components";

export const UsersStats = styled.div`
    margin: 37px 90px 0px;
    border-radius: 20px;
    padding: 34px 34px 60px 34px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .above {
        display: flex;
        justify-content: space-between;
    }

    .table {
        position: relative;
    }

    .search-container{
        width: 220px;
    }

    .search-container input {
        border: 1px solid #CFD4DC;
        border-radius: 8px;
        width: 100%;
        height: 38px;
        padding-left: 12px;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: #ADADAD;
    }

    .search-container input::placeholder{
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            color: #ADADAD;
    }
    .open-add-modal {
        display: flex;
        flex-direction: row-reverse;
    }

    .open-add-modal button {
        width: 150px;
        padding: 10px 18px;
        color: #ffffff;
        border: none;
        cursor: pointer;
        background-color: #3D5573;
    }

    .overtime {
        color: #4CC186;
    }

    .undertime {
        color: #E43F3F;
    }

    .show-stats-btn {
        width: max-content;
        color: #3E5573;
        cursor: pointer;
        &:hover{
            color: #31455F;
        }
    }
    
    .calendar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        .export-btn{
            color: #3E5573;
            font-size: 13px;
            display: flex;
            gap: 5px;
            align-items: center;
            cursor: pointer;

            svg{
                width: 11px;
                height: 15px;
                path{
                    fill: #3E5573;
                }
            }
        }

        .export-btn:hover{
            color: #FBB106;
            svg{
                path{
                    fill: #FBB106;
                }
            }
        }
    }
`;