import { createAsyncThunk } from "@reduxjs/toolkit";

import officeCalendarService from './OfficeCalendar.service'

export const fetchEventsForGivenMonth = createAsyncThunk(
    'officeCalendar/fetchEventsForGivenMonth',
    async (params, {rejectWithValue}) => {
        const {date_from, date_to, filters} = params
        try{
            const response = await officeCalendarService.getEvents(date_from, date_to, filters)
            return response
        } catch (err){
            return rejectWithValue(err.response.data)
        }
    }
)