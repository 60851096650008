import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {store} from "./store";
import App from './App';
import GlobalStyle from './globalStyle'
import FontStyles from "./fontStyles";
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/customToast.css'
import NetworkService from './services/network.service'

NetworkService.setupInterceptors(store)

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <FontStyles />
        <GlobalStyle />
        <ToastContainer className="toast" toastClassName="toast-container" bodyClassName="toast-body" progressClassName="toast-progress"/>
        <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


