import React, { useState, useCallback } from "react";
import {Link, useHistory} from 'react-router-dom'

import {useSelector} from "react-redux";
import authService from "../../services/auth.service";

import Logo from "../../shared/components/Logo/Logo";
import Input from "../../shared/components/Input/Input";
import Button from "../../shared/components/Button/Button";

import {Wrapper, Container, Form} from "./ForgotPassword.style";
import { showToast } from "../../shared/utils/showToast";
import { useKeypress } from "../../hooks";



const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [clicked, setClicked] = useState(false);

    const history = useHistory();
    const {isLoggedIn } = useSelector(state => state.auth);

    if(isLoggedIn){
        history.push("/dashboard");
    }

    const handleEmail = useCallback(async () => {
        setClicked(true);
        try {
            const response = await authService.forgotPassword(email);
            showToast(response.data.message, "success");
            setTimeout(()=>{
                history.push("/login");
            }, 6000);
        } catch (error) {
            showToast(error.response.data.message, "error");
        }
        setClicked(false);
    }, [email, history])

      useKeypress('Enter', handleEmail)

    return (
        <Wrapper>
            <Logo />
            <h3 className="title">Timelogger Watchdog</h3>
            <Container>
                <div className="form-title">Forgot Password</div>
                <hr />
                <Form>
                    <Input placeholder={"Email"} onChange={setEmail} value={email} />
                    <Button onClick={handleEmail} disabled={clicked} value={"Reset Password"}/>
                </Form>
            </Container>
            <div className="return-to">
                Return to <Link to="/login" className="to-login">Login</Link>
            </div>
        </Wrapper>
    )
}

export default ForgotPassword;