import React, {useState, useRef} from 'react';
import Avatar from 'react-avatar'

import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {NavLink} from 'react-router-dom'

import Logo from '../Logo/Logo'
import WorkTimeStatus from './WorkTimeStatus/WorkTimeStatus'

import {Wrapper, Content, Dropdown, Tabs} from './Header.style'

import formatDate from "../../utils/formatDate"
import authService from "../../../services/auth.service"

import {logout} from '../../../reducers/authReducer'

import {useOnClickOutside} from "../../../hooks";
import { useEffect } from 'react';

const profileImageBaseUrl = process.env.REACT_APP_API_BASE_URL

const Header = () => {
    const [menuOpened, setMenuOpened] = useState(false)
    const {user, isLoggedIn} = useSelector(state => state.auth)
    const [displayAvatar, setDisplayAvatar] = useState(false)
    const history = useHistory()
    const ref = useRef()
    const dispatch = useDispatch()

    useOnClickOutside(ref, () => setMenuOpened(false))

    useEffect(() => {
        console.log(isLoggedIn && (user.is_admin || user.is_admin !== '0'))
        setDisplayAvatar(false)
    }, [user])

    const handleLogout = () => {
        authService.logout()
        dispatch(logout())
        history.push("/login")
    }

    const handleProfilePictireError = () => {
        setDisplayAvatar(true)
    }

    return (
        <Wrapper>
            <Content>
                <div className="title-profile">
                    <div className="logo-title-date">
                        <Logo size={60}/>
                        <div className="title">
                            Timelogger Watchdog
                            <p className="date">
                                {formatDate(new Date() / 1000)}
                            </p>
                        </div>

                    </div>
                    <div className="profile">
                        <div className="name">
                           {user.first_name + " " + user.last_name}
                        </div>
                        
                            <div className="image">
                                <div className="online"></div>
                                {user.image ? (
                                    <div className="picture-container">
                                        {!displayAvatar ? (
                                            <img className="profile-image" src={`${profileImageBaseUrl}/images/${user.image}`} alt="" onError={handleProfilePictireError}/>
                                        ) : (
                                            <Avatar color={user.avatar_color} textSizeRatio={2.5} name={`${user.first_name} ${user.last_name}`} size="50" round={true}/>
                                        )}
                                        
                                    </div>
                                ) : (
                                    
                                    <Avatar color={user.avatar_color} textSizeRatio={2.5} name={`${user.first_name} ${user.last_name}`} size="50" round={true}/>
                                )}
                                
                                
                            </div>
                        
                        <div ref={ref} className="menu-toggle" onClick={() => setMenuOpened(!menuOpened)}>
                            <div className="circle" />
                            <div className="circle" />
                            <div className="circle" />
                            <Dropdown opened={menuOpened} onClick={isLoggedIn ? handleLogout : () => history.push("/login")}>
                                {isLoggedIn ? "Logout" : "Login"}
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </Content>
            <div className="tabs-counter">        
                <Tabs >
                    {isLoggedIn && (user.is_admin && user.is_admin !== '0') ? (
                        <>
                            <NavLink to="/your-profile" isActive={(match, location) => {
                                if(!match) return false
                                if(match.url === "/your-profile") return true
                                return false
                            }} className="tab" activeClassName="tab-selected">YOUR PROFILE</NavLink>
                            <NavLink to="/users" className="tab" activeClassName="tab-selected">USERS</NavLink>
                            <NavLink to="/stats" className="tab" activeClassName="tab-selected">STATS</NavLink>
                            <NavLink to="/time-off" isActive={(match, location) => {
                                if(!match) return false
                                if(match.url === "/time-off") return true
                                return false
                            }} className="tab" activeClassName="tab-selected">TIME OFF</NavLink>
                            <NavLink to="/who's-in-office" className="tab" activeClassName="tab-selected">WHO'S IN THE OFFICE</NavLink>
                            <NavLink to="/office-calendar" className="tab" activeClassName="tab-selected">OFFICE CALENDAR</NavLink>
                            
                        </>
                    ) : (!user.is_admin || user.is_admin === '0') ? (
                        <>
                            <NavLink to="/your-profile" isActive={(match, location) => {
                                if(!match) return false
                                if(match.url === "/your-profile") return true
                                return false
                            }} className="tab" activeClassName="tab-selected">YOUR PROFILE</NavLink>
                            <NavLink to="/who's-in-office" className="tab" activeClassName="tab-selected">WHO'S IN THE OFFICE</NavLink>
                            <NavLink to="/office-calendar" className="tab" activeClassName="tab-selected">OFFICE CALENDAR</NavLink>
                        </>
                        ) : null}
                </Tabs>
                <WorkTimeStatus />
            </div>    
        </Wrapper>
    )

}

export default Header