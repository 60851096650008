import api from '../../libs/api'

class OfficeStatusService {

    async fetchOfficeStats(page, perPage, sort_by, sort_type){
        const {data} = await api.get(`in-the-office?page=${page}&per_page=${perPage}`, {
            params: {
                sort_by,
                sort_type
            }
        })
        return data
    }

}

export default new OfficeStatusService();