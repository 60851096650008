import api from '../../../../libs/api'

class WorkTimeStatusService {

    async getWorkTime(id, date){
        const {data} = await api.get(`counters-time`)
        return data
    }

}

export default new WorkTimeStatusService()