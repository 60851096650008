import React, { useState, useCallback } from "react";
import {Link, useHistory, useParams} from 'react-router-dom';

import {useSelector} from "react-redux";
import authService from "../../services/auth.service";

import Logo from "../../shared/components/Logo/Logo";
import Input from "../../shared/components/Input/Input";
import Button from "../../shared/components/Button/Button";

import {Wrapper, Container, Form} from "./SetNewPassword.style";
import { showToast } from "../../shared/utils/showToast";
import { useKeypress } from "../../hooks";


const SetNewPassword = () => {
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");

    const history = useHistory();
    const { token } = useParams();
    const { isLoggedIn } = useSelector(state => state.auth);

    if(isLoggedIn){
        history.push("/dashboard");
    }

    const handlePassword = useCallback(async () => {
        try {
            const response = await authService.setNewPassword(password, confirm,  token);   
            showToast(response.data.message, "success");
            setTimeout(()=>{
                history.push("/login");
            }, 6000);
        } catch (error) {
            showToast(error.response.data.message, "error");
        }
        
    }, [history, confirm, password, token])

    useKeypress('Enter', handlePassword)

    return (
        <Wrapper>
            <Logo />
            <h3 className="title">Timelogger Watchdog</h3>
            <Container>
                <div className="form-title">Set New Password</div>
                <hr />
                <Form>
                    <Input placeholder={"Password"} onChange={setPassword} value={password} />
                    <Input placeholder={"Confirm Password"} onChange={setConfirm} value={confirm} />
                    <Button value={"Set Password"} onClick={handlePassword} />
                </Form>
            </Container>
            <div className="to-login-wrapper">
                <Link to="/login" className="to-login">Login</Link>
            </div>
        </Wrapper>
    )
}

export default SetNewPassword;