import React, { useState } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import {ReactComponent as CalendarIcon} from "./calendar.svg";
import {ReactComponent as TimerIcon} from "./timer.svg";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarContainer } from "./Calendar.style";
import sr from 'date-fns/locale/sr';

const Calendar = ({ startDate,
                    setStartDate, 
                    monthly = false, 
                    yearDropdown = false, 
                    timeSelectOnly = false, 
                    minDate, 
                    maxDate,
                    label = null,
                    error = null,
                    showYearPicker = false,
                    minTime,
                    maxTime    
                }) => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    
    const formatDate = () => {
        if(monthly) {
            return "MM-yyyy";
        }
        else if(timeSelectOnly) {
            return "HH:mm";
        }
        else if(showYearPicker) {
            return "yyyy";
        } else {
            return "dd-MM-yyyy";
        }   
    }

    const currentMonth = selectedYear === new Date().getFullYear() ? new Date().getMonth() : null ;
    registerLocale("en", sr);

    return(
        <CalendarContainer label={label} currentMonth={currentMonth} error={error} >
            {label && <div className="label">{label}</div>}
            <DatePicker 
            locale={"en"}
            selected={startDate}
            onChange={date => setStartDate(date)}
            timeFormat="HH:mm"
            dateFormat={formatDate()}
            timeSelectOnly
            showYearPicker={showYearPicker}
            showMonthYearPicker={monthly}
            showYearDropdown={yearDropdown}
            yearDropdownItemNumber={45}
            minDate={minDate}
            // maxTime={new Date().setHours(23, 59, 0, 0)}
            maxDate={maxDate}
            // minTime={new Date().setHours(0, 0, 0, 0)}
            scrollableYearDropdown
            showTimeSelect={timeSelectOnly}
            showTimeSelectOnly={timeSelectOnly}
            timeIntervals={1}
            fixedHeight={true}
            onYearChange={(year)=>setSelectedYear(year.getFullYear())}
            dayClassName={(today) =>  today.getDate() === new Date().getDate() && today.getMonth() === new Date().getMonth() && today.getFullYear() === new Date().getFullYear() ? "today" : null}
            />
            {timeSelectOnly ? 
            <>
                <TimerIcon className="calendar-icon" />
                <TimerIcon className="calendar-icon orange-calendar-icon" />
            </>
            : 
            <>
                <CalendarIcon className="calendar-icon" />
                <CalendarIcon className="calendar-icon orange-calendar-icon" />
            </>
            }
           {error && <div className="error">{error}</div>}
        </CalendarContainer>
    )
}

export default Calendar;