import React from "react";
import { Text } from "./Spinner.style";

const Spinner = () => {
    return(

            <Text>
                <div className="lds-facebook"><div></div><div></div><div></div></div>
            </Text>

    )
}

export default Spinner;