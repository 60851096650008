import React, {useState} from "react";

import {Wrapper} from './AddTimeOff.style'

import Button from "../../../../shared/components/Button/Button";
import Calendar from "../../../../shared/components/Calendar/Calendar";
import Dropdown from "../../../../shared/components/Dropdown/Dropdown";
import TextArea from "../../../../shared/components/TextArea/TextArea";
import FilterUsersDropdown from "../../../../shared/components/FilterUsersDropdown/FilterUsersDropdown";

import TimeOffService from "../Request.service";

import { showToast } from "../../../../shared/utils/showToast";
import { useKeypress } from "../../../../hooks";
import {lastDayOfMonth} from "date-fns";

const timeOffReasons = ['Vacation', 'Sick Leave', 'Personal', 'Work From Home', 'Company']

const CreateTimeOffRequest = ({hideModal, onCreate, setLoadingModal}) => {

    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()
    const [userSelected, setUserSelected] = useState()
    const [reason, setReason] = useState(null)
    const [requestNote, setRequestNote] = useState("")
    const [reasonDropdownOpened, setReasonDropdownOpened]= useState(false)
    const [errors, setErrors] = useState(null)

    const createTimeOffRequest = async () => {
        try {
            setLoadingModal(true)
            const response = await TimeOffService.create(!userSelected ? 0 : userSelected.id,
                Date.parse(fromDate) / 1000, (Date.parse(toDate) / 1000)+86399, requestNote, reason?.toLowerCase())
            showToast(response.data.message, response.data.data.status === 'declined' ? 'error' : "success")
            setLoadingModal(false)
            hideModal()
            onCreate()
        } catch(err){
            setLoadingModal(false)
            showToast(err.response.data.message, "error")
            
            setErrors(err.response.data.data)
        }
    }

    const getMinDateFrom = () => {
        if(toDate){
            if(new Date(toDate).getMonth() === new Date().getMonth()){
                return new Date()
            }

            return new Date(new Date(toDate).getFullYear(), new Date(toDate).getMonth(), 1);
        }

        return null
    }

    const getMaxDateTo = () => {
        if(fromDate){
            return lastDayOfMonth(fromDate)
        }

        return fromDate
    }

    const handleSaveRequest = () => {
        createTimeOffRequest()
    }   

    const handleReasonSelect = (reason) => {
        setReason(reason)
        setReasonDropdownOpened(false)
    }

    const checkIfButtonShouldBeDisabled = () => {
        if(fromDate && toDate && reason) return false
        return true
    }

    useKeypress('Enter', !checkIfButtonShouldBeDisabled() ? handleSaveRequest : null)
    useKeypress('Escape', hideModal)

    return (
        <Wrapper>
                <>
                    <h3>New Time Off Request</h3>
                
                        <div className="dropdown-container">
                            <label>USER</label>
                            <FilterUsersDropdown onSelect={setUserSelected} error={errors && 'user_id' in errors && errors.user_id[0]} placeholder={"Select user"} user={userSelected}>
                                
                            </FilterUsersDropdown>
                        </div>
                    
                    
                    <div className="dropdown-container">
                        <label>REASON</label>
                        <Dropdown error={errors && 'reason' in errors && errors.reason[0]} placeholder={"Select reason"} value={reason && reason} opened={reasonDropdownOpened} setOpened={setReasonDropdownOpened}>
                            {timeOffReasons.map(reason => <div key={reason} className="dropdown-option" onClick={() => handleReasonSelect(reason)}>{reason}</div>)}
                        </Dropdown>
                    </div>
        
                    <div className="from-to">
                        <div className="from">
                            <label>FROM</label>
                            <Calendar minDate={getMinDateFrom()} startDate={fromDate} setStartDate={setFromDate}/>
                        </div>
                        <div className="to">
                            <label>TO</label>
                            <Calendar maxDate={getMaxDateTo()} startDate={toDate} setStartDate={setToDate}/>
                        </div>
                    </div>
    
                    <TextArea label="NOTE" error={errors && 'note' in errors && errors.note[0]} rows={10} maxLength={300} value={requestNote} onChange={(e) => setRequestNote(e.target.value)} />
                    <div className="button-group">
                        <div className="cancel-button"  onClick={() => hideModal()}>Cancel</div>
                        <Button disabled={checkIfButtonShouldBeDisabled()} value={"Save"} width={100}  onClick={handleSaveRequest}/>
                    </div>

                </>


        </Wrapper>
    )
}

export default CreateTimeOffRequest
