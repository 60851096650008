import React, {useEffect, useState} from "react";

import Modal from "../../../HOC/Modal/Modal";

import {Wrapper, EventMark} from "./EventDetails.style";
import dayjs from "dayjs";

const EventDetails = ({opened, hide, event}) => {

    const [markBackground, setMarkBackground] = useState({
        background :'#E8E8E8',
        eventStart: '#A6A6A6'
    })

    const formatDate = () => {
        const dateFromString = dayjs(new Date(event.date_from * 1000)).format('DD MMMM')
        const dateToString = dayjs(new Date(event.date_to * 1000)).format('DD MMMM')
        const dateFromYear = dayjs(new Date(event.date_from * 1000)).year()
        const dateToYear = dayjs(new Date(event.date_to * 1000)).year()

        return `${dateFromString} ${dateFromYear !== dateToYear ? `, ${dateFromYear}` : ''} - ${dateToString}, ${dateToYear}`
    }


    const setBackgroundColor = () => {

        if(event.status === 'pending') {
            setMarkBackground( {background :'#E8E8E8', eventStart: '#A6A6A6'})
            return
        }
        if(event.reason === 'vacation') {
            setMarkBackground( {background :'#E5F9F9', eventStart: '#53898F'})
            return
        }
        if(event.reason === 'sick leave') {
            setMarkBackground( {background :'#FFEFCA', eventStart: '#FBB106'})
            return
        }
        if(event.reason === 'work from home') {
            setMarkBackground( {background :'#FFDBF7', eventStart: '#DD59C1'})
            return
        }
        if(event.reason === 'personal') {
            setMarkBackground( {background :'#E9DCFF', eventStart: '#8352DC'})
            return
        }
        if(event.reason === 'vacation') {
            setMarkBackground( {background :'#E5F9F9', eventStart: '#53898F'})
            return
        }
    }

    useEffect(() => {
        setBackgroundColor()
    }, [])

    return <Modal isShowing={opened} hide={hide}>

        <Wrapper>
            <div className="name">{event.user?.first_name} {event.user?.last_name}</div>
            <div className="details">
                <EventMark background={markBackground.background} markBackground={markBackground.eventStart}><span className="mark" /></EventMark>
                <div className="reason">{event.reason.split(' ').map(word => `${word} `)}</div>
                <span className="dot" />
                <div className="date">
                    {formatDate()}
                </div>
            </div>
        </Wrapper>

    </Modal>
}

export default EventDetails