import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid #E8E8E8;
  display: flex;
  padding-top: 5px;
  justify-content: center;
  font-family: 'Roboto Medium', sans-serif;
  font-size: 14px;
  color: #979797;
  position: relative;
  .show-more{
    position: absolute;
    bottom: 5px;
    left: 6.5px;
    font-family: 'Roboto Medium', sans-serif;
    font-size: 12px;
    color: #50637F;
    :hover{
      cursor: pointer;
      color: #FBB106;
    }
  }

  .current-day{
    background-color: #FBB106;
    width: 21px;
    height: 21px;
    border-radius: 50% ;
    color: #FFFFFF;
    text-align: center;

  }

  .not-in-selected-month{
    color: #C6D2E2;
  }
`