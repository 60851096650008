import api from "../../../libs/api";

class TimeOffService {

    async get(date_from=null, date_to=null, page = 1, per_page = 20, user_name = null, status = null, reason = null, user_id=null, sortBy, sortType){
        return await api.get('/time-offs', {
            params:{
                date_from,
                date_to,
                user_name,
                status,
                reason,
                page,
                per_page,
                user_id,
                sort_by: sortBy,
                sort_type: sortType
            }
        })
    }

    async create(user_id = null, date_from, date_to, note, reason ){
        console.log("okinuo 2")
        return await api.post(`${user_id}/time-offs/`, {
            date_from,
            date_to,
            note,
            reason,
        })
    }

    async getById(user_id ,id){
        return await api.get(`${user_id}/time-offs/${id}`)
    }

    async edit(id, user_id, date_from, date_to, note, reason, status){
        return await api.put(`${user_id}/time-offs/${id}`,{
            date_from,
            date_to,
            note,
            reason,
            status
        })
    }

    async delete(id, user_id){
        return await api.delete(`${user_id}/time-offs/${id}`)
    }

}


export default new TimeOffService()