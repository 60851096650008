import { createAsyncThunk } from "@reduxjs/toolkit";
import { showToast } from "../../utils/showToast";
import UserStatsService from "./UserStats.service";

export const getUserMonthlyStats = createAsyncThunk(
    "userStats/getMonthly",
    async (params) => {
        try {
            const {timestamp, user_id} = params;
            const response = await UserStatsService.getMonthlyStats(user_id, timestamp);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
)

export const getUserDailyStats = createAsyncThunk(
    "userStats/getDaily",
    async (params) => {
        try {
            const {timestamp, user_id} = params;
            const response = await UserStatsService.getDailyStats(user_id, timestamp);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
)

export const addUserLog = createAsyncThunk(
    "userStats/addLog",
    async (params) => {
        const {user_id, log_time, log_type, log_date} = params;
        try {
            const response = await UserStatsService.addLog(user_id, log_time, log_type, log_date);
            showToast(response.data.message, "success");
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
)

export const editUserLog = createAsyncThunk(
    "userStats/editLog",
    async (params) => {
        const {id, user_id, timestamp, log_type} = params;
        try {
            const response = await UserStatsService.editLog(id, user_id, timestamp, log_type);
            showToast(response.data.message, "success");
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
)

export const deleteUserLog = createAsyncThunk(
    "userStats/deleteLog",
    async (params) => {
        const { id, user_id } = params;
        try {
            const response = await UserStatsService.deleteLog(id, user_id);
            showToast(response.data.message, "success");
            return response.data
        } catch (error) {
            console.log(error);
        }
    }
)