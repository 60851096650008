import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .month-picker{
        display: flex;
        align-items: center;
      
        gap: 15px;
        .month-year{
            font-family: 'Roboto Light', sans-serif;
            font-size: 20px;
          width: 150px;
            color: #50637F;
        }
        
        .right {
            margin-left: 5px;
        }

        .arrow {
            height: 10px;
            
            :hover{
                cursor: pointer;
            }
        }

        path{
            fill: #3E5371
        }

        .today-button{
            width: 70px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #E4F0FF;
            border-radius: 8px;
            background-color: #F1F7FF;
            font-family: 'Roboto Regular', sans-serif;
            font-size: 12px;
            color: #6D8DB7;
            :hover{
                cursor: pointer;
            }
        }
    }

    .filter{

      display: flex;
      align-items: center;
      justify-content: space-between;
      
      .legend {
        display: flex;
        align-items: center;
        font-family: 'Roboto Regular', sans-serif;
        font-size: 13px;
        color: #3E5573;
        :hover{
          cursor: pointer;
        }
        
        .legend-icon{
          height: 12px;
          margin-left: 20px;
          margin-right: 5px;
          path {
            fill: #3E5371
          }
        }
        

      }
    }
`