import React, { useRef, useState } from "react";
import Modal from "../../../HOC/Modal/Modal";
import { DeleteModal, DotsContainer } from "./UserOptions.style";
import EditUser from "../EditUser/EditUser";
import OptionsDropdown from "../../../shared/components/OptionsDropdown/OptionsDropdown";
import Option from "../../../shared/components/OptionsDropdown/Option/Option";
import { useDispatch } from "react-redux";
import { deleteUser } from "../Users.thunk";
import { useOnClickOutside } from "../../../hooks";
import Button from "../../../shared/components/Button/Button";

const UserOptions = ({user, setShowStats, setUser}) => {
    const [dropdownOpened, setDropdownOpened] = useState(false)
    const ref = useRef()

    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const dispatch = useDispatch();

    const removeUser = async (id) => {
        dispatch(deleteUser(id));
    }

    useOnClickOutside(ref, () => setDropdownOpened(false))

    return(
        <DotsContainer  ref={ref} opened={dropdownOpened} >
                <button className="dropbtn" onClick={() => setDropdownOpened(!dropdownOpened)}>...</button>
                <div className="dropdown">
                    <OptionsDropdown>
                        <Option onClick={() => {
                            setEditModal(true);
                            setDropdownOpened(false);
                        }}>Edit</Option>
                        <Option onClick={() => {
                            setDeleteModal(true);
                            setDropdownOpened(false);
                            }}>Remove</Option>
                        <Option onClick={()=>{
                            setShowStats(true);
                            setUser({
                                user_id: user.id,
                                user_full_name: `${user.first_name} ${user.last_name}`
                            })
                            }} >Stats</Option>
                    </OptionsDropdown>
                    <Modal isShowing={editModal} hide={() => setEditModal(false)} >
                        <EditUser id={user.id} setEditModal={setEditModal} />
                    </Modal> 
                    <Modal hide={()=>setDeleteModal(false)} isShowing={deleteModal} >
                        <DeleteModal>
                            <div className="question">Are you sure you want to delete {user.first_name} {user.last_name}?</div>
                            <div className="buttons">
                                <Button width={100} value="Delete" onClick={() => removeUser(user.id)} />
                                <button className="btn cancel" onClick={()=>{
                                    setDeleteModal(false);
                                }} >Cancel</button>
                            </div>
                        </DeleteModal>
                    </Modal>
                </div>
        </DotsContainer>
    )
}

export default UserOptions;