import React, {useEffect, useState, useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux"

import {Wrapper} from "./Requests.style"

import Button from "../../../shared/components/Button/Button";
import Table from "../../../shared/components/Table/Table";
import Modal from "../../../HOC/Modal/Modal";
import Calendar from "../../../shared/components/Calendar/Calendar";
import Input from "../../../shared/components/Input/Input";
import CreateTimeOffRequest from './AddTimeOff/AddTimeOff'
import RequestsOptions from "./RequestsOptions/RequestsOptions";
import LoadingTable from "../../../shared/components/Table/LoadingTable/LoadingTable";

import useModal from "../../../hooks/useModal";
import { useDebounce } from "../../../hooks";

import {getAllTimeOffRequests} from './Request.thunk'

import formatDate from '../../../shared/utils/formatDate'
import Dropdown from "../../../shared/components/Dropdown/Dropdown";

import { useTableSort } from "../../../hooks";

const timeOffStatus = ['Pending', 'Approved', 'Declined']
const timeOffReason = ['Company', 'Vacation', 'Sick Leave', 'Work From Home', 'Personal']

const data = []

const Requests = () => {
    const [status, setStatus] = useState(null)
    const [statusDropdownOpened, setStatusDropdownOpened] = useState(false)
    const [reason, setReason] = useState(null)
    const [reasonDropdownOpened, setReasonDropdownOpened] = useState(false)
    const [usersFilter, setUsersFilter] = useState("")
    const [dateFrom, setDateFrom] = useState(null)
    const [dateTo, setDateTo] = useState(null)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const {sortBy, sortType, toggleSortDirection} = useTableSort('date_requested','desc')
    const debouncedSearchTerm = useDebounce(usersFilter, 500);
    const [tableData, setTableData] = useState(data)
    const {requests, loading} = useSelector(state => state.requests) 
    const {showing, toggle, loadingModal, setLoadingModal} = useModal()

    const dispatch = useDispatch()

    const fetchTimeOffRequests = useCallback(() => {
        const params = {
            status: status && status.toLowerCase(), 
            reason: reason && reason.toLowerCase(),
            perPage, 
            userName: debouncedSearchTerm, 
            dateFrom: dateFrom && Date.parse(dateFrom) / 1000, 
            dateTo: dateFrom && Date.parse(dateTo) / 1000, 
            page,
            sortBy,
            sortType
        }

        dispatch(getAllTimeOffRequests(params))
  
    }, [status, reason, perPage, debouncedSearchTerm, dateFrom, dateTo, page, sortBy, sortType, dispatch])

    

    useEffect(() => {
        fetchTimeOffRequests()
    }, [status, reason, perPage, debouncedSearchTerm, dateFrom, dateTo, page, sortBy, sortType, fetchTimeOffRequests])

    useEffect(() => {
        const data = requests.data && requests.data.map(request => {
            return {
                userName: request.user ? request.user.first_name + " " + request.user.last_name : "Group vacation",
                date_requested: formatDate(request.date_requested),
                from_date: formatDate(request.date_from),
                to_date: formatDate(request.date_to),
                status: request.status.charAt(0).toUpperCase() + request.status.slice(1),
                reason: request.reason.charAt(0).toUpperCase() + request.reason.slice(1),
                options: <RequestsOptions
                    onDelete={fetchTimeOffRequests} 
                    fetchParams={{
                        status: status && status.toLowerCase(),
                        reason: reason && reason.toLowerCase(),
                        perPage, 
                        userName: debouncedSearchTerm, 
                        dateFrom: dateFrom && Date.parse(dateFrom) / 1000, 
                        dateTo: dateFrom && Date.parse(dateTo) / 1000, 
                        page,
                        sortBy,
                        sortType
                    }} 
                    timeOffRequest={request}/>
            }
        })
        setTableData(data) 
    }, [requests, fetchTimeOffRequests, status, reason, debouncedSearchTerm, dateFrom, dateTo, page, perPage, sortType, sortBy])

    const handleStatusFilterChange = (status) => {
        setStatus(status)
        setStatusDropdownOpened(false)
        setPage(1)
    }

    const handleReasonFilterChange = (reason) => {
        setReason(reason)
        setReasonDropdownOpened(false)
        setPage(1)
    }

    const resetFilters = () => {
        setUsersFilter("")
        setDateFrom(null)
        setDateTo(null)
        setStatus(null)
        setReason(null)
    }

    const handleSortTableResults = useCallback((sort) => {
        toggleSortDirection(sort)
    }, [toggleSortDirection])

    const tableColumns = useMemo(() => [
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("first_name")}>FROM</span>,
            accessor: 'userName'
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("date_requested")}>REQUEST DATE</span>,
            accessor: 'date_requested',
            
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("date_from")}>FROM DATE</span>,
            accessor: 'from_date'
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("date_to")}>TO DATE</span>,
            accessor: 'to_date'
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("status")}>STATUS</span>,
            accessor: 'status',
            Cell: ({row}) => {
                if(row.original.status === 'Declined') {
                    return <div style={{color: '#E43F3F'}}>{row.original.status}</div>
                }
                if(row.original.status === 'Approved') {
                    return <div style={{color: '#4CC186'}}>{row.original.status}</div>
                }
                if(row.original.status === 'Pending') {
                    return <div style={{color: '#FBB106'}}>{row.original.status}</div>
                }
                return row.original.status
            }
        },
        {
            Header: () => null,
            accessor: 'options'
        }
    ], [handleSortTableResults])

    return (
        <Wrapper>
            <div className="create-request">
                <div className="filters">
                    <div>
                        <label className="label">FROM</label>
                        <Calendar startDate={dateFrom}  setStartDate={setDateFrom}/>
                    </div>
                    <div>
                        <label className="label">TO</label>
                        <Calendar startDate={dateTo}  setStartDate={setDateTo}/>
                    </div>
                    <div className="filter-status">
                        <label className="label">STATUS</label>
                        <Dropdown placeholder="Select Status" value={status && status} setOpened={setStatusDropdownOpened} opened={statusDropdownOpened}>
                            {timeOffStatus.map(status => <div key={status} className="dropdown-option" onClick={() => handleStatusFilterChange(status)}>{status}</div>)}
                        </Dropdown>
                    </div>
                    <div className="filter-reason">
                        <label className="label">REASON</label>
                        <Dropdown placeholder="Select Reason" value={reason && reason} setOpened={setReasonDropdownOpened} opened={reasonDropdownOpened}>
                            {timeOffReason.map(reason => <div key={reason} className="dropdown-option" onClick={() => handleReasonFilterChange(reason)}>{reason}</div>)}
                        </Dropdown>
                    </div>
                    <div>
                        <Input label="USER" value={usersFilter} onChange={setUsersFilter} placeholder={"Users"} width={"200px"}/>
                    </div>
                    <div className="reset-filters" onClick={resetFilters}>Reset</div>
                </div>
                <div className={'add-timeoff-button'}>
                    <Button value="Add Time Off" width={150} onClick={toggle}/>
                </div>
            </div>
            {tableData && !loading ?  <Table columns={tableColumns} data={tableData} pagination={
                {
                    totalResults: requests.total,
                    page,
                    perPage,
                    setPage,
                    setPerPage,
                    from: requests.from,
                    to: requests.to
                }}/> : <LoadingTable />}
            
            
            <Modal isShowing={showing} hide={toggle} loading={loadingModal} >
                <CreateTimeOffRequest onCreate={fetchTimeOffRequests} hideModal={toggle} selectUser={true} setLoadingModal={setLoadingModal}/>
            </Modal >
        </Wrapper>
    )
}

export default Requests