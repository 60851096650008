import React, {memo} from 'react'
import PropTypes from "prop-types";
import {Wrapper, StyledInput, Label, ErrorMsg} from "./Input.style";

const Input = ({placeholder, type, onChange, value="", width="100%", label = null, error = null}) => {
    return <Wrapper>
        {label && <Label>{label}</Label>}
        <StyledInput label={label} error={error} width={width} type={type} placeholder={placeholder} onChange={e => onChange(e.target.value)} value={value}/>
        {error && <ErrorMsg>{error}</ErrorMsg>}
    </Wrapper>
}

export default memo(Input)

Input.propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string
}