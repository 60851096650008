import styled from "styled-components";

export const Wrapper = styled.div`

  .create-request{
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 30px;
    flex-wrap: wrap-reverse;
    gap: 10px;
  }

  .add-timeoff-button{
    align-self: flex-start;
    margin-left: auto;
    margin-bottom: 4px;
  }
  
  .filters{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    
    .label{
      display: block;
      font-size: 12px;
      color: #506280;
      margin-bottom: 8px;
    }

    label{
      display: block;
    }

    .reset-filters{
      width: 89px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: flex-end;
      margin-bottom: 4px;
      border: 1px solid #E4F0FF;
      border-radius: 8px;
      background-color: #F1F7FF;
      font-family: 'Roboto Regular', sans-serif;
      font-size: 14px;
      color: #6D8DB7;
      :hover{
        cursor: pointer;
      }
    }

    .filter-status{
      width: 200px;
      position: relative;
    }

    .filter-reason{
      width: 200px;
      position: relative;
    }
  }

  .dropdown-option{
    cursor: pointer;
    font-family: 'Roboto Regular', sans-serif;
    font-size: 14px;
    letter-spacing: 0.19px;
    color: #A3A3A3;
    height: 30px;
    padding-left: 9px;
    display: flex;
    align-items: center;
    border-radius: 8px;

    :hover{
      background-color: #F1F7FF;
    }
  }
`