import {createSlice} from "@reduxjs/toolkit";
import { getAllVacationStats } from "./VacationStats.thunk";


const initialState = {
    pagination: {},
    users: [],
    loading: false,
    errors: null
}

const vacationStatsSlice = createSlice({
    name:"vacationStats",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
            builder
                //Get all users
                .addCase(getAllVacationStats.pending, (state)=>{
                    state.loading = true;
                    state.errors = null;
                })
                .addCase(getAllVacationStats.fulfilled, (state, action) => {
                    state.loading = false;
                    state.pagination = action.payload.data;
                    state.users = action.payload.data.data;
                })
                .addCase(getAllVacationStats.rejected, (state, action) => {
                    state.loading = false;
                    state.errors = action.error;
                })
        },
});

export const vacationStatsPagination = (state) => state.vacationStats.pagination;
export const vacationStatsUsers = (state) => state.vacationStats.users;
export const vacationStatsLoading = (state) => state.vacationStats.loading;

export default vacationStatsSlice.reducer;