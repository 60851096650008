import { createSlice } from "@reduxjs/toolkit";
import { getAllStats } from "./Stats.thunk";

const initialState = {
    stats : [],
    errors : null,
    loading : false,
    pagination: {}
}

const statsSlice = createSlice({
    name: "stats",
    initialState,
    reducers: {},
    extraReducers : (builder) => {
        builder 
            .addCase(getAllStats.pending, (state)=>{
                state.loading = true;
                state.errors = null;
            })
            .addCase(getAllStats.fulfilled, (state, action) => {
                state.loading = false;
                state.pagination = action.payload.data;
                state.users = action.payload.data.data;
            })
            .addCase(getAllStats.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.error;
            })
    }
})

export const statsList = (state) => state.stats.users;
export const statsLoading = (state) => state.stats.loading;
export const statsPagination = (state) => state.stats.pagination;

export default statsSlice.reducer;