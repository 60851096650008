import React from 'react'
import {useHistory, useLocation, NavLink} from 'react-router-dom'
import {Switch} from 'react-router'

import {Wrapper, Navigation, Content} from "./TimeOff.style"

import VacationStats from "./VacationStats/VacationStats";
import Requests from "./Requests/Requests";
import PageHeader from '../../shared/components/PageHeader/PageHeader';

import PrivateRoute from '../../HOC/PrivateRoute'

const TimeOff = () => {
    const history = useHistory()
    const location = useLocation()

    if(location.pathname === '/time-off') history.push('/time-off/requests')
    
    return (
        <>
            <PageHeader title={"Time Off"} />
            <Wrapper>
                <Navigation>
                    <NavLink to="/time-off/requests" className="tab" activeClassName="tab active" >REQUESTS</NavLink>
                    <NavLink to="/time-off/vacation-stats" className="tab" activeClassName="tab active">VACATION STATS</NavLink>      
                </Navigation>
                <Content>
                    <Switch>
                        <PrivateRoute path="/time-off/requests" component={Requests} isAdminRoute={true}/>
                        <PrivateRoute path="/time-off/vacation-stats" component={VacationStats} isAdminRoute={true}/>
                    </Switch>
                </Content>
            </Wrapper>
        </>
    )
}

export default TimeOff