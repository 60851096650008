import api from "../../libs/api";

class OfficeCalendarService {

    async getEvents(date_from, date_to, filters){
       
            return await api.get('/calendar', {
                params:{
                   date_from,
                   date_to,
                    filters
                }
            })
        
    }

}

export default new OfficeCalendarService()