import React from 'react'

import { SpinnerWrapper } from './LoadingTable.style'

import Spinner from '../../Spinner/Spinner'

const LoadingTable = () => {
    return (
        <SpinnerWrapper>
            <Spinner />
        </SpinnerWrapper>
    )
}

export default LoadingTable
