import styled from 'styled-components'

export const Wrapper = styled.div`

`

export const TextAreaStyled = styled.textarea`
    width: 100%;
    height: 180px;
    border: 1px solid ${props => props.error ? "#F19E9E" : '#C5CCD5'};
    padding: 10px 14px;
    outline: none;
    font-size: 14px;
    color: #A3A3A3;
    font-style: normal;
    font-weight: normal;
    resize: none;
    border-radius: 8px;

    :hover{
      border: 1px solid #A1AEBD;
    }

    :focus{
      outline: none;
      border: 1px solid #3E5573;
    }
`

export const Label = styled.label`
  color: #506280;
  font-family: 'Robot Regular', sans-serif;
  font-size: 12px;
  letter-spacing: 0.17px;
`

export const ErrorMsg = styled.div`
  color: #E43F3F;
  font-family: 'Roboto Light', sans-serif;
  font-size: 10px;
  margin-top: 3px;
`