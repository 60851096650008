import styled from 'styled-components'

export const Wrapper = styled.div`
    user-select: none;
`

export const Header = styled.div`
    height: 38px;
    width: 100%;
    border: 1px solid ${props => props.error ? "#F19E9E" : props.focused ? '#3E5573' : '#CFD4DC'} ;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    .value{
        font-family: 'Roboto Regular', sans-serif;
        font-size: 14px;
        letter-spacing: 0.19px;
        color: #A3A3A3;
    }

    .placeholder{
        font-family: 'Roboto Light', sans-serif;
        color: #ADADAD;
        font-size: 14px;
    }

    .arrow-down{
        width: 12px;
        margin-left: 15px;
        path{
            fill: #3E5371;
        }
    }

    :hover{
        border: 1px solid #A1AEBD;
    }
    
`

export const DropdownContainer = styled.div`
    position: absolute;
    z-index: 10;
    background-color: #ffffff;
    margin-top: 4px;
    width: 100%;
    border: 1px solid #E8E8E8;
    border-radius: 7px;
    padding: 15px;
    box-shadow: 0px 2px 8px 0px rgba(62, 85, 115, 0.24);
    display: ${props => props.opened ? 'block' : 'none'};

    .users{
       max-height: 180px;
       overflow-y: scroll;
    }

    .user{
        cursor: pointer;
        width: 100%;
        font-family: 'Roboto Regular', sans-serif;
        font-size: 14px;
        letter-spacing: 0.19px;
        color: #A3A3A3;
        height: 30px;
        padding-left: 9px;
        display: flex;
        align-items: center;
        border-radius: 8px;

        :hover{
        background-color: #F1F7FF;
        }
    }
`

export const ErrorMsg = styled.div`
  color: #E43F3F;
  font-family: 'Roboto Light', sans-serif;
  font-size: 10px;
  margin-top: 3px;
`

export const Input = styled.input`
    width: 100%;
    border: 1px solid #CFD4DC;
    height: 30px;
    padding-left: 12px;
    border-radius: 6px;
    margin-bottom: 14px;
    font-family: 'Roboto Regular', sans-serif;
    font-size: 14px;
    letter-spacing: 0.19px;
    color: #A3A3A3;
    ::placeholder{
        font-size: 13px;
        font-family: 'Roboto Light', sans-serif;
        color: #D5D5D5;
    }

    :focus{
      outline: none;
    }
`