import {createSlice} from '@reduxjs/toolkit'

import { getUsersTimeOffs, editUsersTimeOff } from './TimeOff.thunk'

const initialState = {
    loading: false,
    timeOffRequests: {},
    errors: null
}

const userTimeOffSlice = createSlice({
    name: "userTimeOffs",
    initialState,
    reducers: {},
    extraReducers: {

        //Get All TimeOff Requests
        [getUsersTimeOffs.pending]: (state) => {
            state.loading = true
            state.timeOffRequests = []
            state.errors = null
        },
        [getUsersTimeOffs.fulfilled]: (state, action) => {
            state.loading = false
            state.timeOffRequests = action.payload.data.data
            state.errors = null
        },
        [getUsersTimeOffs.rejected]: (state, action) => {
            state.loading = false
            state.timeOffRequests = []
            state.errors = action.payload
        },

        //Edit Time off request
        [editUsersTimeOff.pending]: (state) => {
            state.loading = true
            state.errors = null
        },
        [editUsersTimeOff.fulfilled]: (state, action) => {
            state.loading = false
            state.errors = null
            const requestId = state.timeOffRequests.data.findIndex(obj => obj.id === action.payload.data.data.id) 
            state.timeOffRequests.data[requestId] = action.payload.data.data
        },
        [editUsersTimeOff.rejected]: (state, action) => {
            state.loading = false
            state.errors = action.payload
        },

    }
})

export default userTimeOffSlice.reducer