import styled from "styled-components";

export const Event = styled.div`
  color: ${props => props.eventStartColor};
  height: 20px;
  width: 150px;
  border-radius: 6px;
  background-color: ${props => props.background};
  display: flex;
  align-items: center;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  :hover{
    border: 1px solid ${props => props.eventStartColor};
    border-left: none
  }
  
  .event-start{
    background-color: ${props =>  props.eventStartColor};
    height: 20px;
    width: 5px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-right: 5px;
  }
`