import {createSlice} from '@reduxjs/toolkit'

import { getAllTimeOffRequests, editTimeOffRequest } from './Request.thunk'

const initialState = {
    loading: false,
    requests: [],
    errors: null
}

const timeOffSlice = createSlice({
    name: "timeOffRequests",
    initialState,
    reducers: {},
    extraReducers: {

        //Get All TimeOff Requests
        [getAllTimeOffRequests.pending]: (state) => {
            state.loading = true
            state.requests = []
            state.errors = null
        },
        [getAllTimeOffRequests.fulfilled]: (state, action) => {
            state.loading = false
            state.requests = action.payload.data.data
            state.errors = null
        },
        [getAllTimeOffRequests.rejected]: (state, action) => {
            state.loading = false
            state.requests = []
            state.errors = action.payload
        },

        //Edit Time off request
        [editTimeOffRequest.pending]: (state) => {
            state.loading = true
            state.errors = null
        },
        [editTimeOffRequest.fulfilled]: (state, action) => {
            state.loading = false
            state.errors = null

        },
        [editTimeOffRequest.rejected]: (state, action) => {
            state.loading = false
            state.errors = action.payload
        },

    }
})

export default timeOffSlice.reducer