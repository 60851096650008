import styled from "styled-components";

export const Wrapper = styled.div`
  width: 500px;
  
  .name{
    font-family: 'Roboto Light', sans-serif;
    font-size: 20px;
    color: #50637F;
    margin-bottom: 20px;
  }
  
  .details{
    display: flex;
    gap: 15px;
    align-items: center;
    .reason{
      font-family: 'Roboto Regular', sans-serif;
      font-size: 16px;
      color: #50637F;
      text-transform: capitalize;
    }
    .dot{
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background-color: #FBB106;
      display: inline-block;
    }
    
    .date{
      font-family: 'Roboto Regular', sans-serif;
      font-size: 14px;
      color: #A3A3A3;
    }
  }
`

export const EventMark = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${props => props.background};
  border-radius: 6px;
  .mark{
    display: inline-block;
    background-color: ${props => props.markBackground};
    width: 5px;
    height: 100%;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
`