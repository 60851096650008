import {createSlice} from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { fetchEventsForGivenMonth } from './OfficeCalendar.thunk'

const initialState = {
    events: [],
    selectedFilters: [],
    selectedMonthIndex: dayjs().month(),
    loading: false
}

const officeCalendarSlice = createSlice({
    name: 'officeCalendar',
    initialState,
    reducers: {
        setFilters(state, {payload}){
            state.selectedFilters = payload.map(value => value.toLowerCase())
        },
        setMonth(state, {payload}){
            state.selectedMonthIndex = payload
            state.events = []
        }
    },
    extraReducers: {
        [fetchEventsForGivenMonth.pending]: state => {
            state.loading = true
        },
        [fetchEventsForGivenMonth.fulfilled]: (state, action) => {
            state.loading = false

            state.events = action.payload.data.data
        },
        [fetchEventsForGivenMonth.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const {setFilters, setMonth} = officeCalendarSlice.actions
export default officeCalendarSlice.reducer