import styled from "styled-components"

export const Wrapper = styled.div`
  height: 300px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  color: rgb(228,63,63);
  font-family: 'Roboto Regular', sans-serif;
  font-size: 20px;
`