import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .heading {
        font-size: 20px;
        color: #50637F;
        font-weight: 300;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .checkbox{
        display: flex;
        align-items: baseline;
    }
    .checkbox .admin {
        font-weight: 500;
        color: #A3A3A3;
    }

    .buttons {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
        padding-top: 20px;
    }

    .buttons .btn {
        color: #ffffff;
        border: none;
        cursor: pointer;
    }

    .buttons .cancel {
        color: #B2B1B1;
        background-color: #ffffff;
        width: 100px;
    }

    .buttons .cancel:hover {
        color: #506C90;
    }
`;

export const SpinnerWrapper = styled.div`
    width: 400px;
    height: 800px;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;