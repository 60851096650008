import styled from "styled-components";

export const DotsContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    background-color: transparent;

    .dropbtn {
      color: #3D5573;
      background-color: #fff;
      padding: 0 10px 15px 10px;
      font-size: 30px;
      border: none;
      cursor: pointer;
      background-color: transparent;
    }

    .dropdown {
      width: 120px;
      display: ${props => props.opened ? 'block' : 'none'};
      background-color: #fff;
      position: absolute;
      right: 0px;
      top: 40px;
      z-index: 10000000;

    }

    .dropdown-content {
      display: ${props => props.opened ? 'block' : 'none'};
      width: 120px;
      display: none;
      background-color: #fff;
      position: absolute;
      right: 0px;
      top: 40px;
      z-index: 10000;
    }



    .contact {
        width: 15px;
        height: 15px;
        background-color: #ffffff;
        position: absolute;
        right: 8px;
        top: -8px;
        transform: rotate(45deg);
        border-top: 1px solid #D8E0EA;
        border-left: 1px solid #D8E0EA;
        z-index: -1;
    }
`;

export const DeleteModal = styled.div`
display: flex;
flex-direction: column;
gap: 33px;
padding: 30px 20px 10px;

  .question {
    font-size: 14px;
    color: #A3A3A3;
  }

  .buttons {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
    }

    .buttons .btn {
        color: #ffffff;
        border: none;
        cursor: pointer;
    }

    .buttons .cancel {
        color: #B2B1B1;
        background-color: #ffffff;
        width: 100px;
    }

    .buttons .cancel:hover {
        color: #506C90;
    }
`;  