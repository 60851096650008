import api from "../../../libs/api";

class VacationStatsService{

    async getVacationStats(page, per_page, sort_by, sort_type, timestampYear, search){
        let response = await api.get(`vacation/stats?page=${page}&per_page=${per_page}&sort_by=${sort_by}&sort_type=${sort_type}&date=${timestampYear}&user_name=${search}`, {});
        return response;
    }
}

export default new VacationStatsService();