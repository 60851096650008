import styled from 'styled-components'

export const Wrapper = styled.div`
    height: 25px;
    padding: 0 10px;
    border-radius: 15px;
    border: 1px solid #DDE9FA;
    background-color: #F1F7FF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #6D8DB7;

    .content{
        font-family: 'Robot Regular', sans-serif;
        font-size: 10px;;
        
    }

    :hover{
        background-color: #D6E1EE;
        color: #3E5573;
        border: 1px solid #CCD9E7;
        cursor: default;
    }


    .close-black{
        height: 10px;
        width: 10px;
        fill: #3E5371;
        :hover{
            cursor: pointer
        }
    }
`