import React, {useEffect, useState, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import Event from '../Event/Event'
import Day from './Day/Day'

import { fetchEventsForGivenMonth } from '../OfficeCalendar.thunk'

import { getMonth } from '../utils/getDaysMatrixOfSpecificMonth'
import {checkIfEventsOverlap} from "../utils/checkIfEventsOverlap";

import {Wrapper} from './Calendar.style'


const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const Calendar = () => {
    const {selectedMonthIndex, events, selectedFilters} = useSelector(state => state.officeCalendar)
    const [eventsByWeek, setEventsByWeek] = useState([])
    const [hoveredEvent, setHoveredEvent] = useState()
    const dispatch = useDispatch()
    const calendarRef = useRef()

    useEffect(() => {
        const dates = getMonth(selectedMonthIndex)
        const date_from = dates[0][0].unix()
        const date_to = dates[4][6].unix()
        const params = {
            date_from,
            date_to,
            filters: selectedFilters
        }
        setEventsByWeek([])
        dispatch(fetchEventsForGivenMonth(params))
    }, [selectedMonthIndex, dispatch, selectedFilters])

    useEffect(() => {
        const weeksInMonths = getMonth(selectedMonthIndex)
        let weekCount = 0
        let newEvents = []
        weeksInMonths.forEach((week) => {
            const firstDayOfTheWeek = week[0].unix()
            const lastDayOfTheWeek = week[6].unix()

            weekCount++

            const weekEvents = events.filter(event => {

                return event.date_from <= firstDayOfTheWeek && firstDayOfTheWeek <= event.date_to ||
                    event.date_from <= lastDayOfTheWeek && lastDayOfTheWeek <= event.date_to ||
                    firstDayOfTheWeek < event.date_from && event.date_to < lastDayOfTheWeek
            }).sort((a,b) => a.date_from - b.date_from)
            newEvents.push(weekEvents)

        })
        setEventsByWeek(newEvents)
    }, [events])

    const handleHoverEvent = (event) => {
        console.log(event)
        setHoveredEvent(event.id)
    }

    return (
        <Wrapper>
            <div className="header">
                {days.map(day => {
                    return <div>{day}</div>
                })}
            </div>
            <div ref={calendarRef} className="month-days">
                {getMonth(selectedMonthIndex).map((week, i) => {
                    const firstDayOfTheWeek = week[0].unix()
                    const lastDayOfTheWeek = week[6].unix()

                    const row1 = []
                    const row2 = []
                    const notDisplayed = []

                    eventsByWeek.length > 0 && eventsByWeek[i].forEach(event => {

                        if(event.date_from < firstDayOfTheWeek){
                            event = {...event,  firstDayInWeek: false}
                        } else {
                            event = {...event, firstDayInWeek: true}
                        }

                        if(row1.length === 0){
                            row1.push(event)
                        } else {
                            if(checkIfEventsOverlap(row1, event)){
                                if(row2.length === 0){
                                    row2.push(event)
                                } else {
                                    if(checkIfEventsOverlap(row2, event)){
                                        notDisplayed.push(event)
                                    } else {
                                        row2.push(event)
                                    }
                                }

                            } else {
                                row1.push(event)
                            }
                        }
                    })

                    return <div className="week" key={i}>
                        <div className="event-container">
                        { row1.length > 0 && row1.map((event, idx) => {
                            let topOffset = 0
                            const date_to = event.date_to >= lastDayOfTheWeek ? lastDayOfTheWeek : event.date_to
                            const date_from = event.date_from <= firstDayOfTheWeek ? firstDayOfTheWeek : event.date_from
                            const firstDayOfTheEvent = dayjs(new Date(date_from * 1000)).day() === 0 ? 7 : dayjs(new Date(date_from * 1000)).day()
                            const lastDayOfTheEvent = dayjs(new Date(date_to * 1000)).day() === 0 ? 7 : dayjs(new Date(date_to * 1000)).day()
                            return <Event
                                        key={idx}
                                        maxWidth={(100 / 7) * (7 - Math.abs(firstDayOfTheEvent) + 1)}
                                        topOffset = {topOffset}
                                        leftOffset={(100 / 7) * (Math.abs(firstDayOfTheEvent - 1))}
                                        eventWidth={Math.abs(lastDayOfTheEvent - firstDayOfTheEvent + 1) * 100/7}
                                        event={event}
                                        isFirstDay={event.firstDayInWeek}
                                        row={row1}
                                        first={dayjs(new Date(date_from * 1000)).day()}
                                        last={dayjs(new Date(date_to * 1000)).day()}
                                        setHoveredEvent={setHoveredEvent}
                                        isHovered={hoveredEvent === event.id}
                                    >
                                {event.user ? `${event.user.first_name} , ${event.id}, ${event.date_from}, ${event.date_to}` : 'Group Vacation'}
                                    </Event>
                        })}
                            { row2.length > 0 && row2.map((event, idx) => {
                                const date_to = event.date_to >= lastDayOfTheWeek ? lastDayOfTheWeek : event.date_to
                                const date_from = event.date_from <= firstDayOfTheWeek ? firstDayOfTheWeek : event.date_from
                                const firstDayOfTheEvent = dayjs(new Date(date_from * 1000)).day() === 0 ? 7 : dayjs(new Date(date_from * 1000)).day()
                                const lastDayOfTheEvent = dayjs(new Date(date_to * 1000)).day() === 0 ? 7 : dayjs(new Date(date_to * 1000)).day()
                                return <Event
                                            key={idx}
                                            maxWidth={(100 / 7) * (7 - Math.abs(firstDayOfTheEvent) + 1)}
                                            topOffset = {25}
                                            leftOffset={(100 / 7) * (Math.abs(firstDayOfTheEvent - 1))}
                                            eventWidth={Math.abs(lastDayOfTheEvent - firstDayOfTheEvent + 1) * 100/7}
                                            event={event}
                                            isFirstDay={event.firstDayInWeek}
                                            row={row2}
                                            first={dayjs(new Date(date_from * 1000)).day()}
                                            last={dayjs(new Date(date_to * 1000)).day()}
                                            setHoveredEvent={setHoveredEvent}
                                            isHovered={hoveredEvent === event.id}
                                        >
                                        {event.user ? `${event.user.first_name} , ${event.id}, ${event.date_from}, ${event.date_to}` : 'Group Vacation'}
                                       </Event>
                            })}
                        </div>
                        {week.map((day, j) => {
                            const events = []
                            notDisplayed.forEach((event) => {
                                if(event.date_from <= day.unix() && day.unix() <= event.date_to) events.push(event)
                            })
                            return (
                                <Day setHovered={setHoveredEvent} day={day} events={events} selectedMonthIndex={selectedMonthIndex}/>
                            )
                        })}
                    </div>
                })}
            </div>
        </Wrapper>
    )
}


export default Calendar
