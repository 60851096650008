import styled from "styled-components";

export const DotsContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;

    .dropbtn {
      color: #3D5573;
      background-color: inherit;
      padding: 0 10px 15px 10px;
      font-size: 30px;
      border: none;
      cursor: pointer;

      :hover{
        background-color: inherit;
      }
    }

    .dropdown {
      width: 120px;
      display: ${props => props.opened ? 'block' : 'none'};
      background-color: #fff;
      position: absolute;
      right: 0px;
      top: 40px;
      z-index: 999;

    }

    .dropdown-content .link {
      color: #3D5573;
      padding: 12px 16px;
      display: block;
      cursor: pointer;
    }

    .dropdown-content .link:hover {
        color: #ffffff;
        background-color: #3D5573;
    }

    /* .dropdown:hover .dropdown-content {
      display: block;
    } */

    .contact {
        width: 15px;
        height: 15px;
        background-color: #ffffff;
        position: absolute;
        right: 8px;
        top: -8px;
        transform: rotate(45deg);
        border-top: 1px solid #D8E0EA;
        border-left: 1px solid #D8E0EA;
        z-index: -1;
    }
`;