import styled from "styled-components"

export const SpinnerWrapper = styled.div`
  height: 300px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  display: flex;
  justify-content: center;
  padding-top: 60px;
`