import React, { useCallback, useEffect, useMemo, useState } from "react";
import Calendar from "../../../shared/components/Calendar/Calendar";
import Input from "../../../shared/components/Input/Input";
import { Wrapper} from "./VacationStats.style";
import Table from "../../../shared/components/Table/Table";
import { useDebounce, useTableSort } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { getAllVacationStats } from "./VacationStats.thunk";
import { vacationStatsPagination, vacationStatsUsers, vacationStatsLoading } from "./VacationStats.slice";
import LoadingTable from "../../../shared/components/Table/LoadingTable/LoadingTable";

const VacationStatus = () => {
    const pagination = useSelector(vacationStatsPagination);
    const users = useSelector(vacationStatsUsers);
    const loading = useSelector(vacationStatsLoading);
    const [search, setSearch] = useState("");
    const [year, setYear] = useState(new Date());
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const {sortBy, sortType, toggleSortDirection} = useTableSort('user_name', 'asc');
    const debouncedSearchTerm = useDebounce(search, 500);
    const [tableData, setTableData] = useState([]);

    const dispatch = useDispatch();

    const toTimestamp = date => Math.round(Date.parse(date)/1000);

    useEffect(()=>{
        let timestampYear = toTimestamp(year);
        const params = {page, perPage, sortBy, sortType, timestampYear, search};
        dispatch(getAllVacationStats(params));
    }, [page, perPage, debouncedSearchTerm, year, sortBy, sortType ]);

    useEffect(()=>{
        setTableData(users);
    }, [users]);

    const handleSearchChange = useCallback((value) => {
        setSearch(value);
    }, []);

    const handleSortTableResults = useCallback((sort) => {
        toggleSortDirection(sort);
    }, [toggleSortDirection]);

    const mappedTableData = useMemo(()=>tableData?.map(user => user), [pagination, tableData]);

    const tableColumns = useMemo(()=>[
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("user_name")} >USER</span>,
            accessor: "user_name"
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("old_vacation")} >OLD VACATION</span>,
            accessor: "old_vacation"
           
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("new_vacation")} >NEW VACATION</span>,
            accessor: "new_vacation"
          
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("used_vacation")} >USED VACATION</span>,
            accessor: "used_vacation"
          
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("left_vacation")} >LEFT VACATION</span>,
            accessor: "left_vacation"
          
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("personal")} >PERSONAL</span>,
            accessor: "personal"
          
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("sick")} >SICK</span>,
            accessor: "sick"
          
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("wfh")} >WFH</span>,
            accessor: "wfh"
          
        },
    ], [handleSortTableResults]);

    return (
        <Wrapper>
            <div className="filters">
                <div className="search">
                    <Input width={"270px"} placeholder="Search Users" type="search" value={search} onChange={handleSearchChange} />
                </div>
                <Calendar startDate={year} setStartDate={setYear} showYearPicker={true} />
            </div>
            { loading ? 
                <LoadingTable /> 
                : 
                <Table columns={tableColumns} data={mappedTableData} 
                pagination={
                                {
                                    totalResults: pagination.total,
                                    page,
                                    perPage,
                                    setPage,
                                    setPerPage,
                                    from: pagination.from,
                                    to: pagination.to
                                }
                            }
                                 />
            }
        </Wrapper>
    )
}

export default VacationStatus