import React from 'react'
import { Switch } from 'react-router';
import {NavLink, useHistory, useLocation} from 'react-router-dom'

import Status from "./Status/Status";
import Profile from "./Profile/Profile";
import TimeOff from "./TimeOff/TimeOff";

import {Wrapper, Navigation, Content} from "./YourProfile.style"

import PageHeader from '../../shared/components/PageHeader/PageHeader';

import PrivateRoute from '../../HOC/PrivateRoute';


const YourProfile = () => {
    const history = useHistory()
    const location = useLocation()

    if(location.pathname === '/your-profile') history.push('/your-profile/status')
    
    return (
        <> 
            <PageHeader title={"Your Profile"} />
            <Wrapper>
                <Navigation>
                    <NavLink to="/your-profile/status" className="tab" activeClassName="tab active" >STATUS</NavLink>
                    <NavLink to="/your-profile/time-off" className="tab" activeClassName="tab active">TIME OFF</NavLink>
                    <NavLink to="/your-profile/profile" className="tab" activeClassName="tab active">PROFILE</NavLink>
                </Navigation>
                <Content>
                    <Switch>
                        <PrivateRoute exact path="/your-profile/status" component={Status} isAdminRoute={false}/>
                        <PrivateRoute exact path="/your-profile/time-off" component={TimeOff} isAdminRoute={false}/>
                        <PrivateRoute exact path="/your-profile/profile" component={Profile} isAdminRoute={false}/>
                    </Switch>
                </Content>
            </Wrapper>
        </>

    )
}

export default YourProfile