import React, {useState} from "react";

import {Event} from './EventShowMore.style'
import EventDetails from "../../../EventDetails/EventDetails";

const EventShowMore = ({ event, setHovered}) => {
    const [eventDetailsModalOpened, setEventDetailsModalOpened] = useState(false)

    const setBackgroundColor = (event) => {
        if(event.status === 'pending') {
            return {background :'#E8E8E8', eventStart: '#A6A6A6'}

        }
        if(event.reason === 'vacation') {
            return {background :'#E5F9F9', eventStart: '#53898F'}

        }
        if(event.reason === 'sick leave') {
            return{background :'#FFEFCA', eventStart: '#FBB106'}

        }
        if(event.reason === 'work from home') {
            return  {background :'#FFDBF7', eventStart: '#DD59C1'}

        }
        if(event.reason === 'personal') {
            return  {background :'#E9DCFF', eventStart: '#8352DC'}

        }
        if(event.reason === 'vacation') {
            return {background :'#E5F9F9', eventStart: '#53898F'}

        }
    }

    return <>
        <Event onClick={() => setEventDetailsModalOpened(true)} onMouseEnter={() => setHovered(event.id)} onMouseLeave={() => setHovered(null)} eventStartColor={setBackgroundColor(event).eventStart} background={setBackgroundColor(event).background}>
            <div className="event-start" />
            {event.user.first_name} {event.user.last_name}
        </Event>
        <EventDetails opened={eventDetailsModalOpened} hide={() => setEventDetailsModalOpened(false)} event={event}/>
    </>
}

export default EventShowMore