import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  h3{
    font-size: 20px;
    font-weight: 300;
    color: #50637F;
    margin-bottom: 20px;
  }

  .monthly{
    width: 50%;
    padding-right: 50px;
    border-right: 1px solid #E8E8E8;
  }

  .daily{
    width: 50%;
    padding-left: 50px;

    .daily-datepicker{
      display: flex;
      gap: 20px;

      .button{
        width: 100px;

        
      }
    }
  }

  .today-button{
      width: 89px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #E4F0FF;
      border-radius: 8px;
      background-color: #F1F7FF;
      font-family: 'Roboto Regular', sans-serif;
      font-size: 14px;
      color: #6D8DB7;
      :hover{
        cursor: pointer;
      }
    }

  .table{
    margin-top: 20px;
  }
`