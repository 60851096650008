import styled from "styled-components";

export const Wrapper = styled.div`
  width: 450px;
  
  .title{
    font-family: 'Roboto Light', sans-serif;
    font-size: 20px;
    color: #50637F;
    margin-bottom: 20px;
  }
  
  .element{
    font-family: 'Roboto Regular', sans-serif;
    font-size: 14px;
    color: #50637F;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    
    .vacation{
      display: inline-block;
      width: 110px;
      height: 22px;
      background-color: #E5F9F9;
      border-radius: 6px;
      margin-right: 25px;
      
      .vacation-mark{
        display: inline-block;
        background-color: #53898F;
        width: 5px;
        height: 100%;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
      }
    }

    .sick-leave{
      display: inline-block;
      width: 110px;
      height: 22px;
      background-color: #FFEFCA;
      border-radius: 6px;
      margin-right: 25px;

      .sick-leave-mark{
        display: inline-block;
        background-color: #FBB106;
        width: 5px;
        height: 100%;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
      }
    }

    .wfh{
      display: inline-block;
      width: 110px;
      height: 22px;
      background-color: #FFDBF7;
      border-radius: 6px;
      margin-right: 25px;

      .wfh-mark{
        display: inline-block;
        background-color: #DD59C1;
        width: 5px;
        height: 100%;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
      }
    }

    .personal{
      display: inline-block;
      width: 110px;
      height: 22px;
      background-color: #E9DCFF;
      border-radius: 6px;
      margin-right: 25px;

      .personal-mark{
        display: inline-block;
        background-color: #8352DC;
        width: 5px;
        height: 100%;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
      }
    }

    .pending{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 110px;
      height: 22px;
      background-color: #E8E8E8;
      border-radius: 6px;
      margin-right: 25px;

      .pending-mark{
        display: inline-block;
        background-color: #A6A6A6;
        width: 5px;
        height: 100%;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
      }
      
      .pending-icon{
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }
  }
  
  
`