import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 52px;
  margin-left: 127px;
  margin-right: 127px;
  margin-top: 20px;
  
  .title{
    font-size: 20px;
    color: #50637F;
  }
  
  .time-left{
    font-size: 16px;
    color: #50637F;
  }
`