import styled from "styled-components";

export const CalendarContainer = styled.div`
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    width: max-content;
    :hover {
        .calendar-icon{
            path{
                fill: ${props => props.error === null ? "#FBB106" : "#F19E9E"};
            }
        }
    }

    .label{
        color: #506280;
        font-size: 12px;
        letter-spacing: 0.17px;
        margin-bottom: 8px;
    }

    .error {
        color: #E43F3F;
        font-family: 'Roboto Light',sans-serif;
        font-size: 10px;
        margin-top: 3px;
    }

    .calendar-icon{
        position: absolute;
        top: ${props => props.label === null ? "9px" : "34px"};
        right: 15px;
        pointer-events: none;
        path{
            fill: ${props => props.error === null ? "#3E5371" : "#F19E9E"};
        }
    }

    .today{
        position: relative;
    }

    .today::after {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        background-color: #FBB106;
    }

    
    
    .react-datepicker__month-${props => props.currentMonth}, .react-datepicker__year-text--today {
        position: relative;
        ::after {
            content: '';
            width: 3px;
            height: 3px;
            border-radius: 50%;
            position: absolute;
            top: 3px;
            background-color: #FBB106;
        }
    }

    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            position: relative;
            input {
                padding: 8px 10px;
                width: 270px;
                border: 1px solid ${props => props.error === null ? "#CFD4DC" : "#F19E9E"};
                border-radius: 8px;
                color: #A3A3A3;
                /* color: ${props => props.error === null ? "#A3A3A3" : "#F19E9E"}; */
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    .react-datepicker-popper{
        z-index: 999999;
        padding-top: 2px;

        .react-datepicker{
            border: 0px;
            .react-datepicker__triangle{
                display: none;
                margin-top: 0px;
            }
            .react-datepicker__navigation {
                top: 10px;

                .react-datepicker__navigation-icon::before {
                    border-color: #3E5371;
                    border-width: 1px 1px 0 0;
                }
            }
            .react-datepicker__navigation--previous {
                left: 26px;
            }

           .react-datepicker__navigation--next {
                right: 26px;
            }

            .react-datepicker__month-container, .react-datepicker__year--container{
                display: flex;
                flex-direction: column;
                gap: 0;
                border: 1px solid #E8E8E8;
                box-sizing: border-box;
                box-shadow: 0px 2px 8px rgba(62, 85, 115, 0.242106);
                border-radius: 8px;
                background-color: #fff;
                padding: 5px 20px 15px;

                .react-datepicker__header, .react-datepicker-year-header {
                    background-color: #fff;
                    border-bottom: 0px;
                    width: 196px;
                    margin: 0 auto;
                    font-weight: normal;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .react-datepicker__current-month{
                        width: max-content;
                        height: 14px;
                        font-size: 12px;
                        margin: 0 auto;
                        color: #3E5371;
                        font-weight: 300;
                    }

                    .react-datepicker__header__dropdown{
                        width: max-content;
                        height: 14px;
                        font-size: 12px;
                        margin: 0 auto;
                        color: #3E5371;
                        font-weight: 300;

                        .react-datepicker__year-read-view--down-arrow{
                            border-color: #3E5371;
                            border-width: 1px 1px 0px 0;
                            width: 6px;
                            height: 6px;
                            top: 4px;
                            left: 30px;
                        }
                        
                        .react-datepicker__year-dropdown{
                            border: 1px solid #E8E8E8;
                            box-sizing: border-box;
                            box-shadow: 0px 2px 8px rgba(62, 85, 115, 0.242106);
                            border-radius: 8px;
                            background-color: #ffffff;
                        }
                        .react-datepicker__year-option{
                            color: #A3A3A3;
                        }
                        .react-datepicker__year-option:hover{
                            color: #6D8DB7;
                            background: #F1F7FF;
                        }
                        .react-datepicker__year-option--selected_year, .react-datepicker__year-option--selected_year:hover{
                            background: #3E5573;
                            color: #fff;
                        }
                    }

                    .react-datepicker__day-names{
                        width: 195px;
                        height: 25px;
                        margin: 0 auto;
                        display: flex;
                        justify-content: space-between;

                        .react-datepicker__day-name{
                            width: 16px;
                            height: 23px;
                            font-size: 11px;
                            margin: 0;
                            color: #3E5371;
                        }
                    }
                }
                .react-datepicker-year-header{
                    font-size: 12px;
                    color: #3E5371;
                }

                .react-datepicker__month, .react-datepicker__year{
                    width: 195px;
                    /* height: 155px; */
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    

                    .react-datepicker__week {
                        display: flex;
                        height: 24px;
                        justify-content: space-between;
                        align-items: center;

                        .react-datepicker__day, .react-datepicker__day--keyboard-selected {
                            background-color: #fff;
                            color: #A3A3A3;
                            font-size: 11px;
                            width: 24px;
                            height: 24px;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            line-height: 13px;
                            letter-spacing: 0.117857px;
                            border-radius: 4px;
                        }

                        .react-datepicker__day:hover{
                            color: #6D8DB7;
                            background: #F1F7FF;
                        }

                        .react-datepicker__day--outside-month {
                            color: #C6D2E2;
                        }

                        .react-datepicker__day--selected, .react-datepicker__day--selected:hover {
                            background: #3E5573;
                            color: #fff;

                        }

                        .react-datepicker__day--disabled, .react-datepicker__day--disabled:hover {
                            color: #C6D2E2;
                            background: #ffffff;
                        }
                    }
                }
                .react-datepicker__year {
                    width: 196px;
                }
                .react-datepicker__monthPicker, .react-datepicker__year {
                    .react-datepicker__month-wrapper, .react-datepicker__year-wrapper {
                        display: flex;
                        width: 196px;
                        justify-content: space-around;
                        max-width: none;
                        .react-datepicker__year-text{
                            width: 60px;
                        }
                        .react-datepicker__month-text, .react-datepicker__year-text{
                            height: 24px;
                            font-size: 11px;
                            line-height: 13px;
                            letter-spacing: 0.117857px;
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            color: #A3A3A3;
                            border-radius: 4px;
                        }

                        .react-datepicker__month-text:hover {
                            background: #F1F7FF;
                        }

                        .react-datepicker__year-text--keyboard-selected{
                            color: #A3A3A3;
                            background-color: #fff;
                        }

                        .react-datepicker__month--selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__year-text--selected {
                            background-color: #50637F;
                            color: #ffffff;
                        }

                        .react-datepicker__month--selected:hover, .react-datepicker__month-text--keyboard-selected:hover {
                            background-color: #50637F;
                            color: #ffffff;
                        }
                    }
                }
            }
        }

        .react-datepicker--time-only{
            overflow: initial;
            .react-datepicker__header--time--only{
                display: none;
            }
            .react-datepicker__time{
                border: 1px solid #E8E8E8;
                box-shadow: 0px 2px 8px rgba(62, 85, 115, 0.242106);
                border-radius: 8px;
                width: 103px;
                display: flex;
                align-items: center;
                overflow: initial;
                .react-datepicker__time-box{
                    /* width: 103px; */
                    overflow: initial;
                    width: auto;
                    .react-datepicker__time-list{
                        margin: 10px 0;
                        .react-datepicker__time-list-item {
                            width: 71px;
                            margin: 0 9px;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 14px;
                            letter-spacing: 0.128571px;
                            color: #A3A3A3;
                            border-radius: 4px;
                        }
                        .react-datepicker__time-list-item:hover{
                            background: #F1F7FF;
                        }

                        .react-datepicker__time-list-item--selected{
                            background: #3E5573;
                            color: #ffffff;
                        }
                        .react-datepicker__time-list-item--selected:hover{
                            background: #3E5573;
                        }
                    }
                }
                
            }
        }
    }
`;