import styled from "styled-components";

export const Wrapper = styled.div`
    
`

export const Navigation = styled.div`
    display: flex;
    align-items: center;
    margin-left: 127px;
  
  .tab{
    color: #50637F;
    font-size: 14px;
    font-weight: normal;
    margin-right: 20px;
    
    :hover{
      cursor: pointer;
    }
  }
  
  .active{
    color: #F9C36C
  }

  a{
    outline: none;
    text-decoration: none;
  }
`

export const Content = styled.div`
    padding: 34px 34px 60px 34px;
    background: #ffffff;
    margin: 37px 90px 0 90px;
    border-radius: 20px;
    margin-bottom: 40px;

`