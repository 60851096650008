import styled from "styled-components";

export const Users = styled.div`
    margin: 37px 90px 0px;
    padding: 34px 34px 60px 34px;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .above {
        display: flex;
        justify-content: space-between;
    }

    .table {
        position: relative;
    }

    .search-container{
        width: 220px;
    }

    .search-container input {
        border: 1px solid #CFD4DC;
        border-radius: 8px;
        width: 100%;
        height: 38px;
        padding-left: 12px;
        font-size: 14px;
        font-family: 'Roboto',sans-serif;
        font-weight: 400;
        color: #ADADAD;
    }

    .search-container input::placeholder{
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            color: #ADADAD;
    }
    .open-add-modal {
        display: flex;
        flex-direction: row-reverse;
    }

    .open-add-modal button {
        width: 150px;
        padding: 10px 18px;
        border-radius: 8px;
        color: #ffffff;
        border: none;
        cursor: pointer;
        background-color: #3D5573;
    }
`;