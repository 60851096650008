import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from 'react-redux';

import Input from "../../../shared/components/Input/Input";
import Checkbox from "../../../shared/components/Checkbox/Checkbox";
import MultiselectUsersDropdown from "../../../shared/components/MultiselectUsersDropdown/MultiselectUsersDropdown";

import { Wrapper } from "./AddUser.style";
import Calendar from "../../../shared/components/Calendar/Calendar";
import { addUser } from "../Users.thunk";
import Button from "../../../shared/components/Button/Button";
import Spinner from "../../../shared/components/Spinner/Spinner";
import { usersListErrors, usersLoading } from "../Users.slice";
import SelectNumber from "../../../shared/components/SelectNumber/SelectNumber";
import { useKeypress } from "../../../hooks";

const AddUser = ({hideModal, reloadUsers}) => {
    const errorsState = useSelector(usersListErrors);
    const loading = useSelector(usersLoading);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [vacation, setVacation] = useState(20);
    const [isAdmin, setIsAdmin] = useState(false);
    const [birthday, setBirthday] = useState(new Date());
    const [emailList, setEmailList] = useState([]);
    const [errors, setErrors] = useState(false);

    const dispatch = useDispatch();

    useEffect(()=>setErrors(errorsState), [errorsState]);
    useEffect(()=>setErrors(false), []);

    const handleUser = async () => {
        const emailListUserIds = emailList.map(user => {
            return user.id
        })
        const params = {
            firstName,
            lastName,
            email,
            vacation,
            birthday,
            isAdmin,
            emailListUserIds
        }
        setErrors(false);
        let response = await dispatch(addUser(params));
        if(response.error && response.error.message === "Rejected"){
            return;
        }else{
            hideModal();
            reloadUsers(prev => !prev);
        }
    }

    useKeypress('Enter', handleUser)
    useKeypress('Escape', hideModal)

    return (
        <Wrapper>
            {loading ? 
                <div className="spinner-container"><Spinner /></div> 
                : 
                <div className="container">
                    <div className="heading">Add New User</div>
                    <div className="input">
                        <Input error={errors && errors.first_name ? errors.first_name[0] : null} placeholder="First Name" onChange={setFirstName} value={firstName} label="FIRST NAME"/>
                    </div>
                    <div className="input">
                        <Input error={errors && errors.last_name ? errors.last_name[0] : null} placeholder="Last Name" onChange={setLastName} value={lastName} label="LAST NAME"/>
                    </div>
                    <div className="input">
                        <Input error={errors && errors.email ? errors.email[0] : null} placeholder="Email" onChange={setEmail} value={email} label="EMAIL"/>
                    </div>          
                    <div className="date-picker">
                        <Calendar label="BIRTHDAY" startDate={birthday} setStartDate={setBirthday} yearDropdown={true} maxDate={new Date()} />
                    </div>
                    <div className="checkbox">
                        <Checkbox onClick={() => setIsAdmin(!isAdmin)} label="Admin" checked={isAdmin} name="admin" />
                    </div>
                    <MultiselectUsersDropdown onChange={setEmailList}/>
                    <div className="input">
                        <SelectNumber error={errors && errors.vacation_days ? errors.vacation_days[0] : null} value={vacation} setValue={setVacation} label="Vacation days" />
                    </div>   
                    <div className="buttons">
                        <div className="cancel-button" onClick={()=>hideModal()} >Cancel</div>
                        <Button value="Save" width={100} onClick={()=>handleUser()} />
                    </div>
                </div>
            }
        </Wrapper>
    )
}

export default AddUser;