import { useEffect } from 'react';

export const useKeypress = (key, action) => {
  useEffect(() => {
    const onKeyup =(e) => {
      if (e.key === key){
        e.preventDefault()
        action && action()
      } 
    }
    window.addEventListener('keydown', onKeyup);
    return () => window.removeEventListener('keydown', onKeyup);
  }, [action, key]);
}