import styled from 'styled-components'

export const Wrapper = styled.div` 

`

export const StyledInput = styled.input`
  border: 1px solid ${props => props.error ? "#F19E9E" : "#C5CCD5"} ;
  border-radius: 8px;
  padding-left: 15px;
  font-family: 'Roboto Regular';
  font-size: 14px;
  color: #A3A3A3;
  width: ${props => props.width ? props.width : "100%"};
  height: 38px;
  margin-top: ${props => props.label ? "8px" : "0px"};

  ::placeholder{
    font-family: 'Roboto Light', sans-serif;
    font-size: 14px;
    letter-spacing: 0.19px;
    color: #ADADAD;
  }

  :hover{
    border: 1px solid #A1AEBD;
  }

  :focus{
    outline: none;
    border: 1px solid #3E5573;
  }
`

export const Label = styled.label`
  color: #506280;
  font-family: 'Robot Regular', sans-serif;
  font-size: 12px;
  letter-spacing: 0.17px;
`

export const ErrorMsg = styled.div`
  color: #E43F3F;
  font-family: 'Roboto Light', sans-serif;
  font-size: 10px;
  margin-top: 3px;
`