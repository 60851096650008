import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../../HOC/Modal/Modal";
import useModal from "../../hooks/useModal";
import Table from "../../shared/components/Table/Table";
import AddUser from "./AddUser/AddUser";
import { Users } from "./Users.style";
import UserOptions from "./UserOptions/UserOptions";

import { usersList, usersLoading, usersPagination } from "./Users.slice";
import { useDispatch, useSelector } from "react-redux";
import UserStats from "../../shared/components/UserStats/UserStats";
import { getAllUsers } from "./Users.thunk";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { getAllStats } from "../Stats/Stats.thunk";
import LoadingTable from "../../shared/components/Table/LoadingTable/LoadingTable";
import { useDebounce, useTableSort } from "../../hooks";


const UserPage = () => {
    const pagination = useSelector(usersPagination);
    const users  = useSelector(usersList);
    const loading = useSelector(usersLoading);
    const [showStats, setShowStats] = useState(false);
    const {showing, toggle} = useModal();
    const [tableData, setTableData] = useState(users);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const {sortBy, sortType, toggleSortDirection} = useTableSort('id','asc');
    const [userName, setUserName] = useState("");
    const debouncedSearchTerm = useDebounce(userName, 500);
    const [newUser, setNewUser] = useState(false);
    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const toTimestamp = date => Math.round(Date.parse(date)/1000);
    const month = new Date();
 
    useEffect(() => {
        const params = {
            date: toTimestamp(month),
            user_name: debouncedSearchTerm,
            page: page,
            per_page: perPage,
            sortBy,
            sortType
        };
        dispatch(getAllUsers(params));
    }, [page, perPage, debouncedSearchTerm, newUser, sortBy, sortType]);

    useEffect(() => {
        setTableData(users);
    }, [users]);

    const mappedTableData = useMemo(() => tableData.map(obj => {
        return { 
            id: obj.id,
            name: `${obj.first_name} ${obj.last_name}`,
            email: obj.email, 
            dots: <UserOptions setShowStats={setShowStats} user={obj} setUser={setUser} />}
    }), [tableData]); 

    const handleSearchChange = useCallback((e) => {
        setUserName(e.target.value);
        setTableData(users);
    }, [users]);

    const handleSortTableResults = useCallback((sort) => {
        toggleSortDirection(sort)
    }, [toggleSortDirection])

    const tableColumns = useMemo(()=>[
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("id")}>ID</span>,
            accessor: "id"
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("first_name")}>NAME</span>,
            accessor: "name"
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("email")}>EMAIL</span>,
            accessor: "email"
        },
        {
            Header: "",
            accessor: "dots"
        }
    ], [handleSortTableResults])
    
    return showStats === true ?
            <UserStats page="Users" setShowStats={setShowStats} user={user} />
            :
            <>
            <PageHeader title={"Users"} />
            <Users>
                <div className="above">
                    <div className="search-container">
                        <input onChange={handleSearchChange} value={userName} type="search" placeholder="Search users..." />
                    </div>
                    <div className="open-add-modal">
                        <button onClick={toggle}>Add New User</button>
                    </div>
                </div>
                <div className="table">
                    { loading ? 
                        <LoadingTable />
                        : 
                        <Table 
                            columns={tableColumns}
                            data={mappedTableData} 
                            pagination={
                                {
                                    totalResults: pagination.total,
                                    page,
                                    perPage,
                                    setPage,
                                    setPerPage,
                                    from: pagination.from,
                                    to: pagination.to
                                }
                            }
                        /> 
                    }
                </div>
                <Modal isShowing={showing} hide={toggle}>
                    <AddUser hideModal={toggle} reloadUsers={setNewUser} />
                </Modal>
            </Users>
            </>
    
}

export default UserPage;