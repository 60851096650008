import api from "../../libs/api";

class UsersService {

    async addUser(
        first_name,
        last_name,
        email,
        vacation_days,
        birthday,
        is_admin, 
        email_list
        ){
        
        let response = await api.post('/users', {
            first_name,
            last_name,
            email,
            vacation_days,
            birthday,
            is_admin, 
            email_list
            }
        )
        return response;
    }

    async getUsers (user_name, page, per_page, sort_by, sort_type) {
        return await api.get(`/users?user_name=${user_name}&page=${page}&per_page=${per_page}`, {
            params: {
                sort_type,
                sort_by
            }
        });
    }

    async editUser (
        first_name,
        last_name,
        email,
        vacation_days,
        birthday,
        is_admin,
        id,
        emailListUserIds,
        next_year_vacation
    ) {
        
        let response = await api.put(`/users/${id}`, {
            first_name,
            last_name,
            email,
            vacation_days,
            birthday,
            is_admin,
            email_list: emailListUserIds,
            next_year_vacation: next_year_vacation
        });
        return response;
    }

    async deleteUser (id) {
        return await api.delete(`/users/${id}`)
    }

    async getUserById (id) {
       let response = await api.get(`/users/${id}`);
       return response.data;
    }
}

export default new UsersService();