import React, {useState, useRef} from "react";
import PropTypes from "prop-types";

import {Wrapper, DropdownHeader, DropdownListWrapper, DropdownListItem} from './Select.style'

import {useOnClickOutside} from '../../../hooks'

const Select = ({options, width = "200px", onChange, currentOption = false}) => {
    const [isOpened, setIsOpened] = useState(false)
    const [selectedOption, setSelectedOption] = useState(currentOption ? currentOption : options[0])

    const ref = useRef()

    useOnClickOutside(ref, () => setIsOpened(false))

    const toggle = () => {
        setIsOpened(!isOpened)
    }

    const handleOptionSelect = (option) => {
        setSelectedOption(option)
        setIsOpened(false)
        onChange(option)
    }

    return (
        <Wrapper ref={ref} width={width}>
            <DropdownHeader onClick={toggle}>{selectedOption}<i className={`${isOpened ? "arrow-up" : "arrow-down"}`} /></DropdownHeader >
            <DropdownListWrapper  isOpened={isOpened}>
                {options.map(option => {
                    return <DropdownListItem onClick={() => handleOptionSelect(option)} key={option} value={option.toLowerCase()}>{option}</DropdownListItem>
                })}
            </DropdownListWrapper>
        </Wrapper>
    )
}

export default Select

Select.propTypes = {
    options: PropTypes.array,
    width: PropTypes.string,
    onChange: PropTypes.func
}