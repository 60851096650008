import styled from "styled-components"

export const Wrapper = styled.div`


    .users-list{
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 11px;
      max-height: 160px;
      overflow-y: scroll;
    }

    .user{
      height: 30px;
      width: 100%;
    }

    .selection{
      display: flex;
      gap: 30px;
      margin-top: 11px;
      font-family: 'Roboto Regular', sans-serif;
      font-size: 12px;
      color: #3E5573;

      :hover{
        cursor: pointer;
      }
    }

    label{
      color: #506280;
      font-family: 'Robot Regular', sans-serif;
      font-size: 12px;
      letter-spacing: 0.17px;
    }

    .header {
      margin-top: 8px;
      position: relative;
    }

    .filter-users{
      width: 100%;
      font-family: 'Roboto Light', sans-serif;
      color: #ADADAD;
      font-size: 14px;
      border: 1px solid #CFD4DC;
      border-radius: 7px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      height: 38px;

      ::placeholder{
        font-family: 'Roboto Light', sans-serif;
        font-size: 14px;
        letter-spacing: 0.19px;
        color: #ADADAD;
      }

      :focus{
        outline: none;
      }
    }

    .arrow-down{
      position: absolute;
      right: 15px;
      top: 10px;
        width: 12px;
        path{
            fill: #3E5371;
        }
    }

    .dropdown{
      position: absolute;
      z-index: 100;
      background-color: #ffffff;
      margin-top: 4px;
      width: calc(100% - 60px);
      border: 1px solid #E8E8E8;
      border-radius: 7px;
      padding: 15px;
      box-shadow: 0px 2px 8px 0px rgba(62, 85, 115, 0.24);
      display: ${props => props.opened ? 'block' : 'none'};
    }

    .multiselect-pills-container{
      width: 440px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 9px;
    }
  
`

export const Input = styled.input`
    width: 100%;
    border: 1px solid #CFD4DC;
    height: 30px;
    padding-left: 12px;
    border-radius: 6px;
    margin-bottom: 14px;

    ::placeholder{
        font-size: 13px;
        font-family: 'Roboto Light', sans-serif;
        color: #D5D5D5;
    }

    :focus{
      outline: none;
    }
`



