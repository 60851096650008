import React from "react";
import { SelectNumberWrapp } from "./SelectNumber.style";
import {ReactComponent as Arrow} from "./SelectNumber.svg";

const SelectNumber = ({value = 0, setValue, label, error = null}) => {

    const inputHandler = (val) => {
        setValue(val);
    }

    return(
        <SelectNumberWrapp error={error}>
            <div className="label">{label}</div>
            <div className="input">
                <input className="select" type="number" min={0} onChange={ e => {
                    if(e.target.value < 0 || e.target.value === undefined) {
                        inputHandler(0); 
                        return;
                    }
                    inputHandler(e.target.value);
                }} value={value} />
                <div className="arrows" >
                    <Arrow className="up-arrow" onClick={()=> setValue(prev => prev + 1)} />
                    <Arrow className="down-arrow" onClick={() => setValue(prev => prev === 0 ? 0 : prev - 1)} />
                </div>
            </div>
            {error && <div className="error">{error}</div> }
        </SelectNumberWrapp>
    )
}

export default SelectNumber;