import styled from "styled-components";

export const LogOptionsWrapper = styled.div`
    display: flex;
    gap: 10px;
    width: max-content;

    .daily-icon{
      color: #3E5573;
      cursor: pointer;
      svg{
        width: 15px !important;
        path{
          fill: #3E5573;
        }
      }
    }
    .daily-icon:hover{
      color: #FBB106;
      svg{
        path{
          fill: #FBB106;
        }
      }
    } 
    
`;

export const DeleteModal = styled.div`
display: flex;
flex-direction: column;
gap: 33px;
padding: 0 20px;

  .user {
    color: #50637F;
    font-size: 20px;
  }

  .question {
    font-size: 14px;
    color: #A3A3A3;
  }

  .buttons {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
    }

    .buttons .btn {
        color: #ffffff;
        border: none;
        cursor: pointer;
    }

    .buttons .cancel {
        color: #B2B1B1;
        background-color: #ffffff;
        width: 100px;
    }

    .buttons .cancel:hover {
        color: #506C90;
    }
`;  