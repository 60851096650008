import React from 'react'

import { Wrapper } from './Toast.style'
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg'

const Toast = ({closeToast, message, type}) => {
    return (
        <Wrapper type={type}>
            <div className={'message'}>{message}</div>
            <CloseIcon className="close" onClick={closeToast}></CloseIcon>
        </Wrapper>
    )
}

export default Toast
