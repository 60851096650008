import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 34px 34px 60px 34px;
    background: #ffffff;
    margin: 37px 90px 0 90px;
    border-radius: 20px;
    
    .title{
        margin-bottom: 20px;
        color: #50637F;
        font-family: 'Roboto Light';
        font-size: 20px;
    }
`