import styled from 'styled-components'

export const Wrapper = styled.button`
  background-color: ${props => props.disabled ? '#C5CCD5' : '#3E5573'} ;
  height: 38px;
  width: ${props => props.width ? `${props.width}px` : "100%"};
  text-align: center;
  padding: 0;
  border: none;
  border-radius: 8px;
  outline: none;
  color: #FFFFFF;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};

  :hover{
    background-color: ${props => props.disabled ? '#C5CCD5' : '#31455F'};
  }
`