import {createAsyncThunk} from "@reduxjs/toolkit";
import VacationStatusService from "./VacationStatus.service";

export const getAllVacationStats = createAsyncThunk(
    "vacationstats/get",
    async (params, {rejectWithValue}) => {
        const { page, perPage, sortBy, sortType, timestampYear, search } = params;
        try {
            const response = await VacationStatusService.getVacationStats(page, perPage, sortBy, sortType, timestampYear, search);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }   
    }
)

