import styled from 'styled-components'

export const Wrapper = styled.div`
  
  
  table {
    border-spacing: 0;
    width: 100%;
    border: 1px solid #E8E8E8;
    border-radius: 7px;
 
    tbody{
      display: block;
      max-height: ${props => props.scrollable ? `${props.maxRows * 48}px` : 'fit-content'};
      overflow-y: ${props => props.scrollable ? 'auto' : 'initial'};

      ::-webkit-scrollbar{
        width: 7px;
        background-color: #EFF6FD;
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb {
        background: #D6E1EE;
        border-radius: 4px;
      }
    }

    thead, tfoot, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }


    th {
      :first-child{
        border-top-left-radius: 7px;
      }
      :last-child{
        border-top-right-radius: 7px;
      }
    }

    tr {
      :hover{
        background-color: #F1F7FF;
      }
    }

    th,
    td {
      height: 48px;
      text-align: left;
      border-bottom: 1px solid #E8E8E8;
      padding-left: 10px;
    }

    td{
      font-family: 'Roboto Regular', sans-serif;
      font-size: 14px;
      color: #A3A3A3;
    }
    
    th {
      background: #F7F7F7;
      color: #50637F;
      font-size: 12px;
      font-family: 'Roboto Regular', sans-serif;
    }
    
    tfoot{
      tr:last-child{
        td {
          background-color: #D6E1EE;
          font-family: 'Roboto Medium', sans-serif;
          font-size: 13px;
          color: #50637F;
          :first-child{
            border-bottom-left-radius: 7px;
          }
          :last-child{
            border-bottom-right-radius: 7px;
          }
          border-bottom: 1;
        }
      }
    }
 }  
`

export const Pagination = styled.div`
  display: flex;
  font-size: 13px;
  justify-content: right;
  margin-bottom: 10px;
  align-items: center;
  font-family: 'Roboto Regular', sans-serif;
  color: #A3A3A3;
  

  .perpage{
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    .arrow-down{
        width: 12px;
        margin-left: 5px;
        path{
            fill: #3E5371;
        }
        :hover{
          cursor: pointer;
        }
    }

    .dropdown {
      display: ${props => props.opened ? 'block' : 'none'};
      background-color: #fff;
      position: absolute;
      left: 0px;
      top: 20px;
      z-index: 10000000;
      width: 70px;
    }
  }
  
  .page-select{
      margin-left: 10px;
  }
  .arrow{
      height: 12px;
      width: 12px;
      margin-left: 5px;
      path{
          fill: #3E5371;
      }
      :hover{
        cursor: pointer;
      }
  }

  .disabled{
    path{
          fill: #CFD4DC;
    }
    :hover {
      cursor: default;
    }
  }
  
`

