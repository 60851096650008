import {createAsyncThunk} from "@reduxjs/toolkit";
import { showToast } from "../../shared/utils/showToast";

import UsersService from "./Users.service";

export const getAllUsers = createAsyncThunk(
    "users/get",
    async (params, {rejectWithValue}) => {
        const { user_name, page, per_page, sortType, sortBy } = params;


        try {
            const response = await UsersService.getUsers(user_name, page, per_page, sortBy, sortType);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }   
    }
)

export const editUserDetails = createAsyncThunk(
    "users/edit",
    async (params, {rejectWithValue}) => {
        const {
            firstName,
            lastName,
            email,
            vacation,
            startDate,
            isAdmin,
            id,
            emailListUserIds,
            next_year_vacation
        } = params;
        try {
            const response = await UsersService.editUser(
                firstName,
                lastName,
                email,
                vacation,           
                startDate,
                isAdmin,
                id,
                emailListUserIds,
                next_year_vacation
            );
            showToast(response.data.message, "success");
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }  
    }
)

export const addUser = createAsyncThunk(
    "users/add",
    async (params, {rejectWithValue}) => {
        const {
            firstName,
            lastName,
            email,
            vacation,
            birthday,
            isAdmin,
            emailListUserIds
        } = params;
        try {
            const response = await UsersService.addUser(
                firstName,
                lastName,
                email,
                vacation,
                birthday,
                isAdmin,
                emailListUserIds,
            );
            showToast(response.data.message, "success");
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }  
    }
)

export const deleteUser = createAsyncThunk(
    "users/delete",
    async (id, {rejectWithValue}) => {
        try {
            const response = await UsersService.deleteUser(id);
            showToast(response.data.message, "success");
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    } 
)

export const getUserById = createAsyncThunk(
    "users/getById",
    async (params, {rejectWithValue}) => {
        const { id } = params;
        try {
            const response = await UsersService.getUserById(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }   
    }
)
