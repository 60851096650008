import React, {useState, useRef} from "react";

import { DotsContainer } from "./TimeOffOptions.style";

import Modal from "../../../../HOC/Modal/Modal";
import EditTimeOffRequest from "../EditTimeOffRequest/EditTimeOffRequest";
import DeleteRequest from "../DeleteRequest/DeleteRequest";
import OptionsDropdown from "../../../../shared/components/OptionsDropdown/OptionsDropdown";
import Option from "../../../../shared/components/OptionsDropdown/Option/Option";

import useModal from "../../../../hooks/useModal";
import { useOnClickOutside } from "../../../../hooks";

const TimeOffOptions = ({timeOffRequest, onDelete, onEdit}) => {
    const {showing, toggle, loadingModal, setLoadingModal} = useModal();
    const [dropdownOpened, setDropdownOpened] = useState(false)
    const [modalOpened, setModalOpened] = useState(null)
    const ref = useRef()

    const handleOpenModal = (modal) => {
        setModalOpened(modal)
        setDropdownOpened(false)
        toggle()
    }

    useOnClickOutside(ref, () => setDropdownOpened(false))

    return(
        <DotsContainer  ref={ref} opened={dropdownOpened}>
            <button className="dropbtn" onClick={() => setDropdownOpened(!dropdownOpened)}>...</button>
            <div className="dropdown">
                <OptionsDropdown>
                    <Option onClick={() => handleOpenModal("edit")}>Edit</Option>
                    {timeOffRequest.date_to < Date.parse(new Date()) / 1000 && timeOffRequest.status.toLowerCase() === 'approved' ? 
                         null :
                         <Option onClick={() => handleOpenModal("delete")}>Delete</Option>
                    }
                    
                </OptionsDropdown>
                {modalOpened === "edit" ? (
                    <Modal isShowing={showing} hide={toggle} loading={loadingModal}>
                        <EditTimeOffRequest onEdit={onEdit} hideModal={toggle} id={timeOffRequest.id} setLoadingModal={setLoadingModal}>Edit</EditTimeOffRequest>
                    </Modal>
                ) : modalOpened === "delete" ? (
                    <Modal isShowing={showing} hide={toggle} loading={loadingModal}>
                        <DeleteRequest hideModal={toggle} id={timeOffRequest.id} onDelete={onDelete} setLoadingModal={setLoadingModal}>Edit</DeleteRequest>
                    </Modal>
                ) : null}
            </div>
        </DotsContainer>
    )
}

export default TimeOffOptions;