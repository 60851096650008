import styled from 'styled-components'

export const Wrapper = styled.div`

  h3{
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    color: #50637F;
    margin-bottom: 34px;
  }
  
  .from-to{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .from .to{
      width: 200px;
  }
  
  label{
    display: block;
    font-size: 12px;
    color: #506280;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .button-group{
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .cancel-button{
    margin-right: 20px;
    font-family: 'Roboto Regular', sans-serif;
    font-size: 14px;
    color: #B2B1B1;

    :hover{
      cursor: pointer;
    }
  }

  .dropdown-container {
    width: 100%;
    position: relative;
  }

  .dropdown-option{
    cursor: pointer;
    font-family: 'Roboto Regular', sans-serif;
    font-size: 14px;
    letter-spacing: 0.19px;
    color: #A3A3A3;
    height: 30px;
    padding-left: 9px;
    display: flex;
    align-items: center;
    border-radius: 8px;

    :hover{
      background-color: #F1F7FF;
    }
  }
`

export const Input = styled.input`
    width: 100%;
    border: 1px solid #CFD4DC;
    height: 30px;
    padding-left: 12px;
    border-radius: 6px;
    margin-bottom: 14px;
    font-family: 'Roboto Regular', sans-serif;
    font-size: 14px;
    letter-spacing: 0.19px;
    color: #A3A3A3;
    ::placeholder{
        font-size: 13px;
        font-family: 'Roboto Light', sans-serif;
        color: #D5D5D5;
    }

    :focus{
      outline: none;
    }
`