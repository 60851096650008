import styled from 'styled-components'

export const Wrapper = styled.div`
    font-family: 'Roboto Regular';
    font-size: 14px;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    color: #3E5573;

    :hover {
        background-color: #F1F7FF;
        cursor: pointer;
        color: #6D8DB7;
    }
`