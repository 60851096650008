import React from 'react'

import PropTypes from 'prop-types'

import { Wrapper, OuterCircle, InnerCircle, Label } from './RadioButton.style'

const RadioButton = ({selected, onChange, value, text}) => {

    return (
        <Wrapper onClick={() => onChange(value)}>
            <OuterCircle checked={value === selected}>
                <InnerCircle checked={value === selected}/>
            </OuterCircle>
            <Label>{text}</Label>
        </Wrapper>
    )
}

export default RadioButton

RadioButton.propTypes = {
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired
}
