import api from "../../../libs/api";

class TimeOffService {

    async getAll(date_from=null, date_to=null, page = 1, per_page = 20, status = null, user_id, sort_by, sort_type){
        return await api.get(`${user_id}/time-offs`, {
            params:{
                date_from,
                date_to,
                status,
                page,
                per_page,
                sort_by,
                sort_type
            }
        })
    }

    async getById(user_id ,id){
        return await api.get(`${user_id}/time-offs/${id}`)
    }

    async create(user_id, date_from, date_to, note, reason ){
        console.log("Okinuo")
        return await api.post(`${user_id}/time-offs/`, {
            date_from,
            date_to,
            note,
            reason,
        })
    }

    async edit(id, user_id, date_from, date_to, note, reason){
        return await api.put(`${user_id}/time-offs/${id}`,{
            date_from,
            date_to,
            note,
            reason
        })
    }

    async delete(id, user_id){
        return await api.delete(`${user_id}/time-offs/${id}`)
    }

}


export default new TimeOffService()