import React, {useMemo, useEffect, useCallback, useState} from 'react'
import { useSelector } from 'react-redux'

import { Wrapper } from './WhoIsInTheOffice.style'

import OfficeStatusService from './WhoIsInTheOffice.service'

import PageHeader from '../../shared/components/PageHeader/PageHeader'
import Table from '../../shared/components/Table/Table'
import LoadingTable from '../../shared/components/Table/LoadingTable/LoadingTable'

import { useTableSort } from '../../hooks'

const secondsToHoursAndMinutes = (sec) => {
    const hours = Math.floor(sec / 3600)
    const minutes = Math.floor(sec % 3600 / 60)
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
}

const getTimeFromTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000)
    const hours = date.getHours()
    const minutes = date.getMinutes()
    return `${hours < 10 ? '0'+hours : hours}:${minutes < 10 ? '0'+minutes : minutes}`
}

const WhoIsInTheOffice = () => {
    const {user} = useSelector(state => state.auth)
    const [tableData, setTableData] = useState(null)
    const [loading, setLoading] =useState(false)
    const [pagination, setPagination] = useState(null)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const {sortBy, sortType, toggleSortDirection} = useTableSort('user_name','asc')

    const initialFetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await OfficeStatusService.fetchOfficeStats(page, perPage, sortBy, sortType)
            setTableData(data.data)
            setPagination({
                totalResults: data.total,
                page,
                perPage,
                setPage,
                setPerPage,
                from: data.from,
                to: data.to
            })
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }, [page, perPage, sortBy, sortType])

    const fetchOfficeStatus = useCallback(async() => {
        try {
            const {data} = await OfficeStatusService.fetchOfficeStats(page, perPage, sortBy, sortType)
            setTableData(data.data)
            setPagination({
                totalResults: data.total,
                    page,
                    perPage,
                    setPage,
                    setPerPage,
                    from: data.from,
                    to: data.to
            })
        } catch (error) {
            console.log(error)
        }
    }, [page, perPage, sortBy, sortType])

    useEffect(() => {
        initialFetch()
        const fetchInterval = setInterval(() => {
            fetchOfficeStatus()
        }, 60000)
        return () => clearInterval(fetchInterval)
    },[fetchOfficeStatus, initialFetch])

    const handleSortTableResults = useCallback((sort) => {
        toggleSortDirection(sort)
    }, [toggleSortDirection])

    const adminTableColumns = useMemo(() => [
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("user_name")}>NAME</span>,
            accessor: 'user_name'
        }, 
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("status")}>IN OFFICE</span>,
            accessor: 'status',
            Cell: ({row}) => {
                if (row.original.status === "In Office") return <span style={{color: "#4CC186"}}>{row.original.status}</span>
                if(row.original.status === "Not In Office") return <span style={{color: "#E43F3F"}}>{row.original.status}</span>
                return <span style={{color: "#FBB106"}}>{row.original.status}</span>
            }
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("out_since")}>OUT SINCE</span>,
            accessor: 'out_since',
            Cell: ({row}) => row.original.out_since ? getTimeFromTimestamp(row.original.out_since) : "-"
        },
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("time_left")}>TIME LEFT</span>,
            accessor: 'time_left',
            Cell: ({row}) => <span style={{color: "#E43F3F"}}>{row.original.time_left ? row.original.time_left > 0 ? secondsToHoursAndMinutes(row.original.time_left) : '00:00' : "-"}</span>
        }
    ], [handleSortTableResults])

    const userTableColumns = useMemo(() => [
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("user_name")}>NAME</span>,
            accessor: 'user_name'
        }, 
        {
            Header: <span style={{cursor: 'pointer'}} onClick={() => handleSortTableResults("status")}>STATUS</span>,
            accessor: 'stats',
            Cell: ({row}) => {
                if (row.original.status === "In Office") return <span style={{color: "#4CC186"}}>{row.original.status}</span>
                if(row.original.status === "Not In Office") return <span style={{color: "#E43F3F"}}>{row.original.status}</span>
                return <span style={{color: "#FBB106"}}>{row.original.status}</span>
            }
        },
    ], [handleSortTableResults])
    return (
        <>
            <PageHeader title="Who's In The Office" />
            <Wrapper>
            <div className="title">Office Status</div>  
            {loading ? <LoadingTable /> : 
                tableData ? <Table columns={user.is_admin ? adminTableColumns : userTableColumns} data={tableData}  pagination={pagination}></Table> : null
            }
           
            </Wrapper>
        </>
    )
}

export default WhoIsInTheOffice
