import {configureStore} from "@reduxjs/toolkit";
import authReducer from './reducers/authReducer'
import usersReducer from "./views/Users/Users.slice";
import statsReducer from "./views/Stats/Stats.slice";
import requestsReducer from './views/TimeOff/Requests/Request.slice';
import userTimeOffReducer from './views/YourProfile/TimeOff/TimeOff.slice'
import userStatsReducer from "./shared/components/UserStats/UserStats.slice";
import vacationStatsReducer from "./views/TimeOff/VacationStats/VacationStats.slice";
import officeCalendarReducer from './views/OfficeCalendar/OfficeCalendar.slice'


export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        stats: statsReducer,
        requests: requestsReducer,
        userStats: userStatsReducer,
        timeOffs: userTimeOffReducer,
        vacationStats: vacationStatsReducer,
        officeCalendar: officeCalendarReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})