import api from "../../libs/api";

class UsersStats {
    async getStats(date, user_name, page, per_page, sort_by, sort_type){
        let response = await api.get(`/monthly/stats?date=${date}&user_name=${user_name}&page=${page}&per_page=${per_page}`, {
            params: {
                sort_type,
                sort_by
            }
        });
        return response;
    }

    async exportCsv(date){
        let response = await api.get(`/export-monthly/stats?date=${date}`, {
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'blob'
        });
        return response;
    }
}

export default new UsersStats();