import { useState } from "react";

const useModal = () => {
    const [showing, setShowing] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false)

    function toggle(){
        setShowing(!showing);
    }

    return {
        showing, toggle, loadingModal, setLoadingModal
    }
};

export default useModal;